import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../../layout/HeadingForm';
import RepairShopSelect from './RepairShopSelect';
import Map from './Map';

import { fetchWorkshops as fetchWorkshopsAction } from '../../actions/workshops';
import { navigateToPath, setNavigationState as setNavigationStateAction } from '../../actions/navigation';
import { NAV_DIRECTIONS, types } from '../../actions/constants';


const states = {
  FETCHING: 'fetching',
  SELECTION: 'selection',
  NO_DATA: 'no-data',
  ERROR: 'error',
};

export const getCurrentState = (props) => {
  const { isFetching, fetchError, workshopList } = props;
  if (isFetching) {
    return states.FETCHING;
  }
  if (fetchError) {
    return states.ERROR;
  }
  if (workshopList.length === 0) {
    return states.NO_DATA;
  }
  return states.SELECTION;
};

class RepairShop extends React.Component {
  state = {
    selectedWorkshopId: null,
    center: null,
  }

  componentDidMount() {
    const {
      lastFetchedPostalCode,
      typedPostalCode,
      fetchWorkshops,
      workshopList,
      pathName,
      name,
      onChange,
      value,
    } = this.props;

    if (workshopList.length === 0 || typedPostalCode !== lastFetchedPostalCode) {
      onChange({ target: { name, type: 'text', value: undefined } });
      fetchWorkshops(typedPostalCode, pathName, name);
    }

    if (!isEmpty(value) && typedPostalCode === lastFetchedPostalCode) {
      this.setState({ selectedWorkshopId: value.repairerid });
      this.setupMapCoordinates(value);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { setNavigationState } = this.props;
    const currentState = getCurrentState(nextProps);
    const isNextActive = [states.ERROR].includes(currentState)
      || (currentState === states.SELECTION && !isEmpty(nextProps.value));
    setNavigationState(isNextActive, true);
    return true;
  }

  saveWorkshopInStore = (selectedWorkshop) => {
    const {
      name, pathName, onChange, updateField,
    } = this.props;
    if (selectedWorkshop) {
      const target = { name, type: 'text', value: selectedWorkshop };
      onChange({ target });
      updateField(name, selectedWorkshop, pathName);
    } else {
      onChange({ target: { name, type: 'text', value: undefined } });
      updateField(name, undefined, pathName);
    }
  }

  setupMapCoordinates = (selectedWorkshop) => {
    if (!selectedWorkshop) return;
    this.setState({
      center: {
        lat: selectedWorkshop.lat,
        lng: selectedWorkshop.lon,
      },
    });
  }

  onWorkshopSelectionChange = (id) => {
    this.setState({ selectedWorkshopId: id });
    const { workshopList } = this.props;
    const selectedWorkshopFromList = workshopList.find(workshop => workshop.repairerid === id);
    this.setupMapCoordinates(selectedWorkshopFromList);
    this.saveWorkshopInStore(selectedWorkshopFromList);
  }

  content(currentState) {
    const {
      workshopList,
    } = this.props;

    if (currentState === states.SELECTION) {
      const { selectedWorkshopId, center } = this.state;
      return (
        <div className="esc_grid">
          <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-12 esc_col-m-6">
              <RepairShopSelect
                workshopList={workshopList}
                selectedWorkshopId={selectedWorkshopId}
                onWorkshopSelectionChange={this.onWorkshopSelectionChange}
                key={selectedWorkshopId} // to force re-render on selectedWorkshopId change
              />
            </div>
            <div className="esc_col esc_col-12 esc_col-m-6">
              <Map
                workshopList={workshopList}
                selectedWorkshopId={selectedWorkshopId}
                onWorkshopSelectionChange={this.onWorkshopSelectionChange}
                center={center}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { t } = this.context;
    const {
      lastFetchedPostalCode,
      localePath,
      isValid,
    } = this.props;

    const currentState = getCurrentState(this.props);
    return (
      <ContentWrapper
        isButtonDisabled={!isValid}
      >
        <HeadingForm
          value={t(`${localePath}.heading.${currentState}`).replace('{postalCode}', lastFetchedPostalCode)}
          commentValue={t(`${localePath}.comment.${currentState}`)}
        />
        { this.content(currentState) }
      </ContentWrapper>
    );
  }
}

RepairShop.propTypes = {
  workshopList: PropTypes.arrayOf(PropTypes.object),
  lastFetchedPostalCode: PropTypes.string,
  typedPostalCode: PropTypes.string,
  fetchWorkshops: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
      repairerid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      distance: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      postalcode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
    }),
    PropTypes.objectOf(PropTypes.string),
  ]),
  name: PropTypes.string,
  pathName: PropTypes.string,
  isValid: PropTypes.bool,
  localePath: PropTypes.string,
  setNavigationState: PropTypes.func,
  updateField: PropTypes.func,
};

RepairShop.defaultProps = {
  workshopList: [],
  lastFetchedPostalCode: '',
  typedPostalCode: '',
  fetchWorkshops: () => {},
  onChange: () => {},
  value: {},
  name: '',
  pathName: '',
  isValid: false,
  localePath: '',
  setNavigationState: () => {},
  updateField: () => {},
};

RepairShop.contextTypes = {
  t: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = ({ workshops }, ownProps) => (
  {
    ...workshops,
    value: ownProps.values[ownProps.name],
  }
);
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  fetchWorkshops: (postalCode, pathName, fieldName) => dispatch(fetchWorkshopsAction(postalCode, pathName, fieldName)),
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
  goBack: () => dispatch(navigateToPath(NAV_DIRECTIONS.PREVIOUS)),
  setNavigationState: (isNextActive, isPreviousActive) => {
    dispatch(setNavigationStateAction(isNextActive, isPreviousActive));
  },
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE,
    fieldName: name,
    pathName,
    payload,
  }),
});

export { RepairShop as RepairShopPlain };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepairShop);
