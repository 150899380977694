import {
  NO,
  getYesNoValue,
  getYesNoValueProxy,
  getDateValueProxy,
  getUploadValues,
  getCountryCode,
  getOneCircumstanceFromMultiProxy,
  getDescribeCircumstance,
  getSalutationValue,
  getFirstNameValue,
  getLastNameValue,
  getPhoneValue,
  getEmailValue,
  arrayIncludesValueProxy,
  getVehicleTypeValue,
  stripSpacesFromIban,
  getUploadedFilesProxy,
  arrayIncludesKeyAndValueProxy,
} from '../claimData.helpers';
import { claimsKeys, getCarMapKey } from '../../../helpers/formData/formData';
import {
  circumstance,
} from '../../../helpers/formData/collisionAbroadFormData';
import { getPolicyNumberProxy, getGuiltValue } from './claimData.helpers';

export default {
  'who-fills': [claimsKeys.POLICY_NUMBER, getPolicyNumberProxy],
  'event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'event-circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'event-circumstances_circumstances': {
    circumstance: [
      claimsKeys.KIND_OF_DAMAGE,
      getOneCircumstanceFromMultiProxy({
        driveUp: circumstance.DRIVE_UP,
        parking: circumstance.PARKING,
        disregard: circumstance.DISREGARD,
        bending: circumstance.BENDING,
        shunting: circumstance.SHUNTING,
        overtaking: circumstance.OVERTAKING,
        laneChange: circumstance.LANE_CHANGE,
        different: circumstance.DIFFERENT,
      }),
    ],
    describeCircumstance: [claimsKeys.CIRCUMSTANCES, getDescribeCircumstance],
  },
  'event-circumstances_guilt': [claimsKeys.CAUSER_OF_DAMAGE, getGuiltValue],
  'license-number': {
    country: [claimsKeys.collisionAbroad.thirdParty.COUNTRY_CODE, getCountryCode],
    licenseNumber: claimsKeys.thirdParty.LICENSE_NUMBER,
  },
  contact_person: {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
  },
  contact_address: {
    address: claimsKeys.owner.ADDRESS,
    postCode: claimsKeys.owner.POST_CODE,
    city: claimsKeys.owner.CITY,
    country: [claimsKeys.owner.COUNTRY_CODE, getCountryCode],
  },
  'police-report_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'police-report_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'are-there-any-witnesses_are-there-any-witnesses': [claimsKeys.ANY_WITNESS, getYesNoValue],
  'are-there-any-witnesses_only_witness': {
    who: [claimsKeys.firstWitness.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.firstWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.firstWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.firstWitness.PHONE, getPhoneValue],
    address: claimsKeys.firstWitness.ADDRESS,
    postCode: claimsKeys.firstWitness.POST_CODE,
    city: claimsKeys.firstWitness.CITY,
    country: [claimsKeys.firstWitness.COUNTRY_CODE, getCountryCode],
  },
  'are-there-any-witnesses_first_witness': {
    who: [claimsKeys.firstWitness.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.firstWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.firstWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.firstWitness.PHONE, getPhoneValue],
    address: claimsKeys.firstWitness.ADDRESS,
    postCode: claimsKeys.firstWitness.POST_CODE,
    city: claimsKeys.firstWitness.CITY,
    country: [claimsKeys.firstWitness.COUNTRY_CODE, getCountryCode],
  },
  'are-there-any-witnesses_second_witness': {
    who: [claimsKeys.secondWitness.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.secondWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.secondWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.secondWitness.PHONE, getPhoneValue],
    address: claimsKeys.secondWitness.ADDRESS,
    postCode: claimsKeys.secondWitness.POST_CODE,
    city: claimsKeys.secondWitness.CITY,
    country: [claimsKeys.secondWitness.COUNTRY_CODE, getCountryCode],
  },
  'are-there-any-witnesses_third_witness': {
    who: [claimsKeys.thirdWitness.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdWitness.PHONE, getPhoneValue],
    address: claimsKeys.thirdWitness.ADDRESS,
    postCode: claimsKeys.thirdWitness.POST_CODE,
    city: claimsKeys.thirdWitness.CITY,
    country: [claimsKeys.thirdWitness.COUNTRY_CODE, getCountryCode],
  },
  'collision-type': {
    'personal-injury': [claimsKeys.thirdParty.PERSONAL_INJURY, arrayIncludesValueProxy('personal-injury')],
    'vehicle-damage': [claimsKeys.thirdParty.VEHICLE_DAMAGE, arrayIncludesValueProxy('vehicle-damage')],
    'item-damage': [claimsKeys.thirdParty.ITEM_DAMAGE, arrayIncludesValueProxy('item-damage')],
  },
  'specify-personal-injury': claimsKeys.thirdParty.PERSONAL_INJURY_DESC,
  'specify-item-damage': claimsKeys.thirdParty.ITEM_DAMAGE_DESC,
  'car-repair_vehicle-type': [
    claimsKeys.thirdParty.VEHICLE_TYPE,
    getVehicleTypeValue,
  ],
  'car-repair_license-number': {
    country: [claimsKeys.collisionAbroad.owner.COUNTRY_CODE, getCountryCode],
    licenseNumber: claimsKeys.owner.LICENSE_NUMBER,
  },
  'car-repair_car-map': {
    describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
    'front-left': [getCarMapKey('front-left'), arrayIncludesKeyAndValueProxy('carMap', 'front-left')],
    'front-middle': [getCarMapKey('front-middle'), arrayIncludesKeyAndValueProxy('carMap', 'front-middle')],
    'front-right': [getCarMapKey('front-right'), arrayIncludesKeyAndValueProxy('carMap', 'front-right')],
    'middle-left': [getCarMapKey('middle-left'), arrayIncludesKeyAndValueProxy('carMap', 'middle-left')],
    'middle-part': [getCarMapKey('middle-part'), arrayIncludesKeyAndValueProxy('carMap', 'middle-part')],
    'middle-right': [getCarMapKey('middle-right'), arrayIncludesKeyAndValueProxy('carMap', 'middle-right')],
    'back-left': [getCarMapKey('back-left'), arrayIncludesKeyAndValueProxy('carMap', 'back-left')],
    'back-middle': [getCarMapKey('back-middle'), arrayIncludesKeyAndValueProxy('carMap', 'back-middle')],
    'back-right': [getCarMapKey('back-right'), arrayIncludesKeyAndValueProxy('carMap', 'back-right')],
  },
  'car-repair_bike-other': claimsKeys.owner.DESCRIBE_DAMAGE,
  'car-repair_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'car-repair_leased-vehicle': [claimsKeys.LEASED_FINANCED, getYesNoValue],
  'damage-repair_is-repair': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'damage-repair_iban': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'damage-repair_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'damage-repair_repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
