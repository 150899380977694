import { invert } from 'lodash';

import vehicleFlows from '../../models/dataMappings/vehicleFlows';
import privateLiabilityFlows from '../../models/dataMappings/privateLiabilityFlows';
import brokerFlows from '../../models/dataMappings/brokerFlows';
import accidentFlows from '../../models/dataMappings/accidentFlows';
import legalProtectionFlows from '../../models/dataMappings/legalProtectionFlows';
import propertyFlows from '../../models/dataMappings/propertyFlows';
import { TYPE_OF_ACCIDENT } from '../../helpers/formData/accidentFormData';
import { PROPERTY_WHAT_HAPPENED } from '../../helpers/formData/propertyFormData';
import { PARTY } from '../claimData/claimData.helpers';
import { moduleNames } from '../claimData/claimData';
import flowToId from './flowToId';

/* #FIXME this code will need change when new flows are introduced */
// ToDo: This file is getting bigger and bigger
const mappedFlows = {
  vehicle: vehicleFlows,
  'private-liability': privateLiabilityFlows,
  broker: brokerFlows,
  accident: accidentFlows,
  'legal-protection': legalProtectionFlows,
  property: propertyFlows,
  upload: 'upload',
};

function runJSCode(setID, setSID) {
  /* eslint-disable  */
  window._uzactions = window._uzactions || [];
  window._uzactions.push(['_setID', `${setID}`]);
  window._uzactions.push(['_setSID', `${setSID}`]);
  window._uzactions.push(['_start']);
  (function ()
  { let uz = document.createElement('script'); uz.type = 'text/javascript'; uz.async = true; uz.charset = 'utf-8'; uz.src = `${'https:' == document.location.protocol ? 'https://' : 'http://'  }cdn4.userzoom.com/trueintent/js/uz_til.js?cuid=67009C787DDAE311BEDA0022196C4538`; let s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(uz, s); }
  ());
  /* eslint-enable */
}

export const defaultFlowsFormDataConstants = {
  WHO_FILLS: 'who-fills',
  WHAT_HAPPENED: 'what-happened',
  TYPE_OF_LEGAL: 'which-field-of-law',
};

/* eslint-disable no-case-declarations */
export function getZoomIdsByFlow(pathName, formData) {
  const mappedFlow = invert(mappedFlows[pathName]);
  const flowIds = flowToId[pathName];
  switch (pathName) {
    case moduleNames.ACCIDENT:
      const typeOfAccident = formData[TYPE_OF_ACCIDENT];
      const flowNameOfAccident = mappedFlow[typeOfAccident];
      return flowIds[flowNameOfAccident];
    case moduleNames.LEGAL_PROTECTION:
      const typeOfLegal = formData[defaultFlowsFormDataConstants.TYPE_OF_LEGAL];
      const flowNameOfLegal = mappedFlow[typeOfLegal];
      return flowIds[flowNameOfLegal];
    case moduleNames.PROPERTY:
      const propertyWhatHappened = formData[PROPERTY_WHAT_HAPPENED];
      const flowNameOfProperty = mappedFlow[propertyWhatHappened];
      return flowIds[flowNameOfProperty];
    case moduleNames.UPLOAD:
      return flowIds;
    case moduleNames.COLLISION_ABROAD:
      return flowIds;
    default:
      const whoFills = formData[defaultFlowsFormDataConstants.WHO_FILLS];
      if (whoFills === PARTY) {
        return flowIds.party.LIABILITY;
      }

      const whatHappened = formData[defaultFlowsFormDataConstants.WHAT_HAPPENED];
      const flowName = mappedFlow[whatHappened];
      return flowIds.policyholder[flowName];
  }
}
/* eslint-enable no-case-declarations */

function attachUserZoom(pathName, formData) {
  // Broker is internal flow and we don't want to support zoom here
  if (pathName === moduleNames.BROKER) return;

  try {
    const {
      setID,
      setSID,
    } = getZoomIdsByFlow(pathName, formData);
    runJSCode(setID, setSID);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error attachUserZoom:', err);
  }
}

export default attachUserZoom;
