import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getBrokerData } from '../../../helpers/formData/vehicleFormData';

const BrokerDetails = ({ formData, localePath, className }, { t }) => {
  const {
    firstName, lastName, phone, email, reference,
  } = getBrokerData(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={t(`${localePath}.broker.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.broker.content-name`, { firstName, lastName })}
      </p>
      { phone && (
        <p>
          {t(`${localePath}.broker.content-phone`, { phone })}
        </p>
      )}
      { email && (
        <p>
          {t(`${localePath}.broker.content-email`, { email })}
        </p>
      )}
      { reference && (
        <p>
          {t(`${localePath}.broker.content-reference`, { reference })}
        </p>
      )}
    </Details>
  );
};

BrokerDetails.propTypes = legalConsentProps.sectionProps;
BrokerDetails.defaultProps = legalConsentProps.sectionDefaults;

BrokerDetails.contextTypes = {
  t: PropTypes.func,
};

export default BrokerDetails;
