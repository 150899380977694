import claimCommon from './claimCommon';
import claimTraffic from './claimTraffic';
import claimProperty from './claimProperty';
import claimPrivate from './claimPrivate';
import claimOccupation from './claimOccupation';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData, getPageType } from '../../../helpers/formData/legalProtectionFormData';

const legalProtectionTransform = formData => {
  const claimData = {
    ...claimCommon,
    ...claimTraffic,
    ...claimProperty,
    ...claimPrivate,
    ...claimOccupation,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data: { ...data, ...getPageType() },
    serviceType: moduleNames.LEGAL_PROTECTION,
    ...additionalData,
  };
};

export default legalProtectionTransform;
