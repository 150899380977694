import { getTranslationArray } from '../../../helpers/polyglot';

export const getPersonDamageCircumstancesValueProxy = formValue => {
  const labels = getTranslationArray('private-liability.person-damage.circumstances.options');
  const { circumstance, describeCircumstance } = formValue;
  const damagedParts = labels[circumstance];
  const descriptionParts = describeCircumstance !== ' ' ? `; ${describeCircumstance}` : '';
  return `${damagedParts}${descriptionParts}`;
};

export const getAnimalDamageCircumstancesValueProxy = formValue => {
  const circumstances = getTranslationArray('private-liability.animal-damage.circumstances.options');
  return circumstances[formValue];
};

export const getPersonDamageItemDamagesValueProxy = formValue => {
  const labels = getTranslationArray('private-liability.person-damage.specify-item-damage.item-damage.options');
  const { typesOfItemDamage, describeDamage } = formValue;
  const damagedParts = typesOfItemDamage.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  const descriptionParts = describeDamage !== ' ' ? `; ${describeDamage}` : '';
  return `${damagedParts.join(', ')}${descriptionParts}`;
};

export const getPersonDamageGuiltValueProxy = formValue => {
  const labels = getTranslationArray('private-liability.person-damage.guilt.options');
  return labels[formValue];
};

export const getKeyLossCircumstancesValueProxy = formValue => {
  const circumstances = getTranslationArray('private-liability.key-loss.circumstances.options');
  return circumstances[formValue];
};

export const getKeyLossGuiltValueProxy = formValue => {
  const guilt = getTranslationArray('private-liability.key-loss.guilt.options');
  return guilt[formValue];
};
