import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { otherBusinessDamageCause } from '../../../helpers/formData/propertyFormData';

export default {
  'other-business_damage_cause': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'extended-coverage': otherBusinessDamageCause.EXTENDED_COVERAGE,
      'unnamed-dangers': otherBusinessDamageCause.UNNAMED_DANGERS,
      'company-closures': otherBusinessDamageCause.COMPANY_CLOSURES,
      different: otherBusinessDamageCause.DIFFERENT,
    }),
  ],
};
