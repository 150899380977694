import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const CollisionAbroadLicenseNumber = props => (
  <ErgoDataForm {...props} formType={formTypes.LICENSE_NUMBER} />
);

CollisionAbroadLicenseNumber.propTypes = {
  localePath: PropTypes.string,
};

CollisionAbroadLicenseNumber.defaultProps = {
  localePath: '',
};

export default CollisionAbroadLicenseNumber;
