import { types } from '../../actions/constants';

const initialState = {
  workshopList: [],
  isFetching: false,
  fetchError: '',
  lastFetchedPostalCode: '',
  typedPostalCode: '',
};

const workshops = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case types.FETCH_WORKSHOPS: {
      const { postalCode } = action;
      return {
        ...state,
        isFetching: true,
        fetchError: '',
        lastFetchedPostalCode: postalCode,
        workshopList: [],
      };
    }

    case types.FETCH_WORKSHOPS_SUCCESS: {
      const { workshopList } = action;
      return {
        ...state,
        isFetching: false,
        workshopList,
      };
    }

    case types.FETCH_WORKSHOPS_FAILURE: {
      const { fetchError } = action;
      return {
        ...state,
        isFetching: false,
        fetchError,
        lastFetchedPostalCode: '',
      };
    }

    case types.UPDATE_WORKSHOP_POSTAL_CODE: {
      const { postalCode } = action;
      return {
        ...state,
        typedPostalCode: postalCode,
      };
    }

    default: {
      return state;
    }
  }
};

export default workshops;
