/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import injectJss from 'react-jss';
import HeadingForm from '../../layout/HeadingForm';
import { mapClaimData, moduleNames } from '../../services';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ErgoNavButton from '../../components/ErgoNavButton';
import { legalConsentProps } from '../../models/panels';
import { getHeadingData } from '../../helpers/formData/accidentFormData';

import {
  ContactDetails,
  IncidentDetails,
  ClaimantContact,
  HospitalDetails,
  PayingOutDetails,
  YourMessageDetails,
  FileDetails,
} from './sections';
import { extractTemplateData } from '../../helpers/pdfHelpers';
import styles from './styles.jss';

class AccidentSummaryPage extends React.PureComponent {
  state = {
    mappedFormData: {},
  };

  componentDidMount() {
    const { formData } = this.props;
    this.setState({ mappedFormData: mapClaimData(formData, moduleNames.ACCIDENT).data },
      () => extractTemplateData('summaryPageWrapper'));
  }

  render() {
    const {
      isValid, localePath, classes, onClick, formData,
    } = this.props;
    const { mappedFormData } = this.state;
    const { t } = this.context;

    const defaultProps = {
      formData: mappedFormData,
      localePath,
      className: classes.detailsWrapper,
      whatDocuments: formData[moduleNames.ACCIDENT]
        && formData[moduleNames.ACCIDENT]['upload-documents_what-documents'],
    };

    return (
      <ContentWrapper>
        <HeadingForm
          value={t(`${localePath}.heading`, {
            fullName: getHeadingData(mappedFormData),
          })}
          commentValue={t(`${localePath}.comment`)}
        />
        <div id="summaryPageWrapper">
          <ContactDetails {...defaultProps} />
          <IncidentDetails {...defaultProps} />
          <ClaimantContact {...defaultProps} />
          <HospitalDetails {...defaultProps} />
          <PayingOutDetails {...defaultProps} />
          <YourMessageDetails {...defaultProps} />
          <FileDetails {...defaultProps} />
        </div>

        <div className="esc_grid">
          <div className={`esc_grid__wrapper ${classes.gridWrapper}`}>
            <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-2">
              <ErgoNavButton previous onClick={onClick} shouldNavigateAfterClick type="submit" />
            </div>
            <div className="esc_col esc_col-12 esc_col-s-8 esc_col-m-4">
              <ErgoNavButton
                title={`${localePath}.ok`}
                onClick={onClick}
                disabled={!isValid}
                shouldNavigateAfterClick
                type="submit"
              />
            </div>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({ formData }) => ({ formData });

AccidentSummaryPage.propTypes = legalConsentProps.props;
AccidentSummaryPage.defaultProps = legalConsentProps.defaultProps;
AccidentSummaryPage.contextTypes = {
  t: PropTypes.func,
};

export { AccidentSummaryPage as AccidentSummaryPagePlain };
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  injectJss(styles),
)(AccidentSummaryPage);
