const styles = () => ({
  wrapper: {
    '& .esc_col-3:nth-child(3)': {
      padding: '12px 8px 12px 0px',
    },
    '& .esc_col-3:nth-child(4)': {
      padding: '12px 8px 12px 0px',
    },
    '& .esc_col-3:nth-child(5)': {
      padding: '12px 0px',
    },
  },
  messageBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
  },
  iconWrapper: {
    paddingRight: 16,
  },
  rectangle: {
    height: 49,
    width: 24,
    borderRadius: '3px 0 0 3px',
    backgroundColor: '#294194',
    marginTop: 12,
    marginBottom: 3,
    paddingTop: 10,
    fontSize: 12,
    fontWeight: 300,
    color: '#FEFEFE',
    marginLeft: 12,
  },
  icon: {
    marginLeft: 1.5,
    marginBottom: 3,
  },
  headLabel: {
    textAlign: 'left',
  },
});

export default styles;
