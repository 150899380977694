import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import TooltipIcon from '@eg/elements/TooltipIcon';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import styles from './styles.jss';

const HeadingForm = ({
  classes,
  commentValue,
  value,
  tooltipTitle,
  tooltipContent,
  displayMultipleSelectInformation,
  textMultipleSelect,
}) => {
  if (!commentValue && !value && !displayMultipleSelectInformation) return null;
  return (
    <div className={`${classes.root} ${classes.rootPosition}`}>
      <h2 className={classes.heading}>
        <span dangerouslySetInnerHTML={{ __html: value }} />
        {tooltipTitle && (
          <TooltipIcon className={classes.tooltip}>
            <h1>{tooltipTitle}</h1>
            <p>{tooltipContent}</p>
          </TooltipIcon>
        )}
      </h2>
      {commentValue && <p className={classes.commentHeading} dangerouslySetInnerHTML={{ __html: commentValue }} /> }
      {displayMultipleSelectInformation && (
        <div className={classes.multipleSelectWrapper}>
          <InfoIcon width={25} height={25} className={classes.infoIcon} />
          <p className={classes.textMultipleSelect}>{textMultipleSelect}</p>
        </div>
      )}
    </div>
  );
};

HeadingForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  commentValue: PropTypes.string,
  value: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipContent: PropTypes.string,
  displayMultipleSelectInformation: PropTypes.bool,
  textMultipleSelect: PropTypes.string,
};

HeadingForm.defaultProps = {
  classes: {},
  commentValue: '',
  value: '',
  tooltipTitle: null,
  tooltipContent: null,
  displayMultipleSelectInformation: false,
  textMultipleSelect: '',
};

export { HeadingForm as HeadingFormPlain };
export default injectSheet(styles)(HeadingForm);
