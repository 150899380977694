import { getTranslationArray } from '../../../helpers/polyglot';

export const getTypeOfAccusationProxy = formValue => {
  const labels = getTranslationArray('asset-liability.type-of-accusation.own-damage.options');
  const selectedOptions = formValue.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  return `${selectedOptions.join(', ')}`;
};
