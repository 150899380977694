import carMapIcon from '../../assets/icons/CarMap.svg';

const styles = ({ breakpoints }) => ({
  wrapper: {
    alignItems: 'center',
  },
  carPicture: {
    background: `url(${carMapIcon}) no-repeat center`,
    height: '396px',
    width: '260px',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      margin: '0 auto',
    },
  },
});

export default styles;
