const styles = () => ({
  options: {
    textAlign: 'left',
  },

  radioButton: {
    marginRight: '20px',
  },
});

export default styles;
