import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const ErgoContactDataNotRepairShop = props => (
  <ErgoDataForm {...props} formType={formTypes.CONTACT_NOT_REPAIR_SHOP} />
);

ErgoContactDataNotRepairShop.propTypes = {
  localePath: PropTypes.string,
};

ErgoContactDataNotRepairShop.defaultProps = {
  localePath: '',
};

export default ErgoContactDataNotRepairShop;
