const styles = ({ breakpoints }) => ({
  fromLabel: {
    paddingTop: '15px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '80px 40px 260px 1fr',
    justifyItems: 'start',
    marginBottom: '20px',
  },
  datePickerContainer: {
    justifySelf: 'stretch',
    marginRight: '15px',
  },
  item: {
    alignSelf: 'start',
  },
  containerBisMobile: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`@media (max-width: ${breakpoints.breakpointS})`]: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
    datePickerContainer: {
      width: '100%',
      margin: 0,
    },
  },
  // media query for Internet Explorer 10 and 11
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    item: {
      width: '80px',
      '&:last-child': {
        flexBasis: '20%',
        marginLeft: '100px',
      },
    },
    datePickerContainer: {
      width: '260px',
      marginRight: '15px',
    },
  },
});

export default styles;
