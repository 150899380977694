import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './Checklist.styles.jss';
import * as IconLoader from '../../helpers/IconLoader';

const Checklist = ({
  columns, classes, iconClass, localePath,
}, { t }) => {
  if (!columns.length) {
    return null;
  }

  const generateColumn = (column, customClass = '') => (
    column.map(({ icon, label }) => {
      const Icon = IconLoader[icon];
      const iconStyle = iconClass ? classes[iconClass] : '';
      return (
        <div key={label} className={`${classes.item} ${customClass}`}>
          <Icon className={iconStyle} />
          <span className={classes.text}>{label}</span>
        </div>
      );
    })
  );

  return (
    <div className={classes.container}>
      <p className={classes.headingText}>
        {t(`${localePath}.checklist.heading`)}
      </p>
      {generateColumn(columns[0])}
    </div>
  );
};

Checklist.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.array),
  iconClass: PropTypes.string,
  localePath: PropTypes.string,
};

Checklist.defaultProps = {
  columns: [],
  classes: {},
  iconClass: '',
  localePath: '',
};

Checklist.contextTypes = {
  t: PropTypes.func,
};

export { Checklist as ChecklistPlain };
export default injectSheet(styles)(Checklist);
