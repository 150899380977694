import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Modal from '@eg/elements/Modal';
import styles from './Dialog.styles.jss';
import calcAppOverlayImage from '../../assets/images/calc_app_overlay.png';
import Checklist from './Checklist';

const Dialog = ({
  classes, localePath, columns, isDialogOpen, onCloseDialog,
  headingText, commentText, checklistHeadingText,
}) => (
  <Modal
    open={isDialogOpen}
    viewport="fixed"
    className={classes.modal}
    dismissible
    backdropDismissesModal
    onDismiss={onCloseDialog}
  >
    <div className={classes.mainContainer}>
      <section>
        <p className={classes.heading}>{headingText}</p>
        <p className={classes.comment}>{commentText}</p>
      </section>
      <section className={classes.container}>
        <img src={calcAppOverlayImage} alt="calculation mobile app" className={classes.image} />
        <Checklist headingText={checklistHeadingText} columns={columns} localePath={localePath} />
      </section>
    </div>
  </Modal>
);

Dialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.array),
  isDialogOpen: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  headingText: PropTypes.string,
  commentText: PropTypes.string,
  checklistHeadingText: PropTypes.string,
};

Dialog.defaultProps = {
  classes: {},
  localePath: '',
  columns: [],
  isDialogOpen: false,
  onCloseDialog: () => {},
  headingText: '',
  commentText: '',
  checklistHeadingText: '',
};

export { Dialog as DialogPlain };
export default injectSheet(styles)(Dialog);
