import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './Card.styles.jss';
import IconICDCodes from '../../assets/icons/icdCodes';
import IconMahnbescheid from '../../assets/icons/Mahnbescheid';

const Card = ({ classes, localePath }, { t }) => {
  let Icon;
  if (localePath.includes('asset-liability')) {
    Icon = IconMahnbescheid;
  } else if (localePath.includes('accident')) {
    Icon = IconICDCodes;
  }

  return (
    <div className={classes.container}>

      <div className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </div>

      <div className={classes.textContainer}>
        <p className={classes.heading}>{t(`${localePath}.card.heading`)}</p>
        <p className={classes.description}>{t(`${localePath}.card.description`)}</p>
        {t(`${localePath}.card.hint-heading`).length > 0 && (
          <p className={classes.listHeading}>{t(`${localePath}.card.hint-heading`)}</p>) }
        {t(`${localePath}.card.hint-description`).length > 0 && (
          <p className={classes.description}>{t(`${localePath}.card.hint-description`)}</p>) }
        {t(`${localePath}.card.list-heading`).length > 0 && (
          <p className={classes.listHeading}>{t(`${localePath}.card.list-heading`)}</p>)}
        <ul>
          {t(`${localePath}.card.list-item-1`).length > 0 && (
            <li className={classes.itemList}>{t(`${localePath}.card.list-item-1`)}</li>)}
          {t(`${localePath}.card.list-item-2`).length > 0 && (
            <li className={classes.itemList}>{t(`${localePath}.card.list-item-2`)}</li>)}
          {t(`${localePath}.card.list-item-3`).length > 0 && (
            <li className={classes.itemList}>{t(`${localePath}.card.list-item-3`)}</li>)}
        </ul>
      </div>
    </div>
  );
};
Card.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
};

Card.defaultProps = {
  classes: {},
  localePath: '',
};

Card.contextTypes = {
  t: PropTypes.func,
};

export { Card as CardPlain };

export default injectSheet(styles)(Card);
