import {
  NO,
  getEmailValue,
  getPhoneValue,
  getYesNoValue,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneCircumstanceFromMultiProxy,
  getOneFromMultiProxy,
  arrayIncludesValueProxy,
  arrayIncludesKeyAndValueProxy,
  getDateValueProxy,
  getCountryCode,
  getLicenseNumber,
  stripSpacesFromIban,
  getDescribeCircumstance,
  getVehicleTypeValue,
  getSalutationValue,
} from '../claimData.helpers';
import { getGuiltValue } from './claimData.helpers';
import { claimsKeys, getCarMapKey, userTypes } from '../../../helpers/formData/formData';
import { collisionAndLiabilityDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'liability_event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'liability_event-circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'liability_event-circumstances_circumstances': {
    circumstance: [
      claimsKeys.KIND_OF_DAMAGE,
      getOneCircumstanceFromMultiProxy({
        invade: collisionAndLiabilityDamages.INVADE,
        entry: collisionAndLiabilityDamages.ENTRY,
        disregard: collisionAndLiabilityDamages.DISREGARD,
        turn: collisionAndLiabilityDamages.TURN,
        maneuvering: collisionAndLiabilityDamages.MANEUVERING,
        overtaking: collisionAndLiabilityDamages.OVERTAKING,
        laneChange: collisionAndLiabilityDamages.LANE_CHANGE,
        different: collisionAndLiabilityDamages.DIFFERENT,
      }),
    ],
    describeCircumstance: [claimsKeys.CIRCUMSTANCES, getDescribeCircumstance],
  },
  'liability_event-circumstances_guilt': [claimsKeys.CAUSER_OF_DAMAGE, getGuiltValue],
  'liability_vehicle-id-ergo-liability_license-number': [claimsKeys.owner.LICENSE_NUMBER, getLicenseNumber],
  'liability_contact-person': {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
    email: claimsKeys.thirdParty.EMAIL,
  },
  'liability_contact-broker': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    yourReference: claimsKeys.broker.REFERENCE,
  },
  'liability_contact-person-address': {
    address: claimsKeys.thirdParty.ADDRESS,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY_CODE, getCountryCode],
  },
  'liability_was-police-reported_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'liability_was-police-reported_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'liability_are-there-any-witnesses_are-there-any-witnesses': [claimsKeys.ANY_WITNESS, getYesNoValue],
  'liability_are-there-any-witnesses_only-witness': {
    firstName: [claimsKeys.firstWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.firstWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.firstWitness.PHONE, getPhoneValue],
  },
  'liability_are-there-any-witnesses_first-witness': {
    firstName: [claimsKeys.firstWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.firstWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.firstWitness.PHONE, getPhoneValue],
  },
  'liability_are-there-any-witnesses_second-witness': {
    firstName: [claimsKeys.secondWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.secondWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.secondWitness.PHONE, getPhoneValue],
  },
  'liability_are-there-any-witnesses_third-witness': {
    firstName: [claimsKeys.thirdWitness.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdWitness.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdWitness.PHONE, getPhoneValue],
  },
  'liability_who-what-has-suffered': {
    'personal-injury': [claimsKeys.thirdParty.PERSONAL_INJURY, arrayIncludesValueProxy('personal-injury')],
    'item-damage': [claimsKeys.thirdParty.ITEM_DAMAGE, arrayIncludesValueProxy('item-damage')],
    'vehicle-damage': [claimsKeys.thirdParty.VEHICLE_DAMAGE, arrayIncludesValueProxy('vehicle-damage')],
  },
  'liability_specify-personal-injury': claimsKeys.thirdParty.PERSONAL_INJURY_DESC,
  'liability_specify-item-damage': claimsKeys.thirdParty.ITEM_DAMAGE_DESC,
  'liability_vehicle-id-non-ergo-by-liability_vehicle-type': [
    claimsKeys.thirdParty.VEHICLE_TYPE,
    getVehicleTypeValue,
  ],
  'liability_vehicle-id-non-ergo-by-liability_license-number': [
    claimsKeys.thirdParty.LICENSE_NUMBER,
    getLicenseNumber,
  ],
  'liability_vehicle-id-non-ergo-by-liability_bike-other': claimsKeys.thirdParty.DESCRIBE_DAMAGE,
  'liability_vehicle-id-non-ergo-by-liability_vehicle-damage-description_car-map': {
    describeDamage: claimsKeys.thirdParty.DESCRIBE_DAMAGE,
    'front-left': [
      getCarMapKey('front-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-left'),
    ],
    'front-middle': [
      getCarMapKey('front-middle', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-middle'),
    ],
    'front-right': [
      getCarMapKey('front-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-right'),
    ],
    'middle-left': [
      getCarMapKey('middle-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-left'),
    ],
    'middle-part': [
      getCarMapKey('middle-part', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-part'),
    ],
    'middle-right': [
      getCarMapKey('middle-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-right'),
    ],
    'back-left': [
      getCarMapKey('back-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-left'),
    ],
    'back-middle': [
      getCarMapKey('back-middle', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-middle'),
    ],
    'back-right': [
      getCarMapKey('back-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-right'),
    ],
  },
  'liability_car-repair_private-vehicle': [claimsKeys.THIRD_PARTY_PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'liability_car-repair_is-company-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'liability_car-repair_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  // Is Repaired: Yes
  'liability_car-repair_repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'liability_car-repair_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'liability_car-repair_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  // Is Repaired: No
  'liability_car-repair_want-to-repair': [
    claimsKeys.DAMAGE_REPAIR,
    getOneFromMultiProxy({
      yes: claimsKeys.REPAIR,
      no: claimsKeys.PAYOUT,
    }),
  ],
  'liability_car-repair_not-repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'liability_car-repair_want-to-recommend-repair-shop': [claimsKeys.RECOMMEND_REPAIR_SHOP, getYesNoValue],
  'liability_car-repair_not-repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'liability_car-repair_is-vehicle-in-workshop': [claimsKeys.WORKSHOP, getYesNoValue],
  'liability_car-repair_postal-code': claimsKeys.WORKSHOP_POSTAL_CODE,
  'liability_car-repair_select-workshop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    address: claimsKeys.WORKSHOP_ADDRESS,
    phone: claimsKeys.WORKSHOP_PHONE,
    city: claimsKeys.WORKSHOP_CITY,
    postalcode: claimsKeys.WORKSHOP_POSTAL_CODE,
  },
};
