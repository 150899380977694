const SID = {
  vehicle: 'B256680C02A3E91180DF005056A2E9D6',
  privateLiability: '805236F13FD8E911AA5EC9768AD49726',
  accident: '14DB726DC806EA11AA5EC9768AD49726',
  legalProtection: '95CA1A3C5947EA11AA5EC9768AD49726',
  property: '8741E1FE9E78EA11AA5EC9768AD49726',
  upload: '11DEB73530CBEA11AA5EC9768AD49726',
  collisionAbroad: '88BFAE690F03EB11AA5EC9768AD49726',
};

const flowToId = {
  vehicle: {
    policyholder: {
      ANIMAL: {
        setID: '5E390C8205A3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      WEATHER: {
        setID: '79134E5D0DA3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      VANDALISM: {
        setID: '7D702EDA0DA3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      FIRE: {
        setID: 'DE7D39BE11A3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      THEFT: {
        setID: '3A4564850EA3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      GLASS: {
        setID: '0948094C11A3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
      COLLISION: {
        setID: '8B10739511A3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
    },
    party: {
      LIABILITY: {
        setID: 'A5A4B64402A3E91180DF005056A2E9D6',
        setSID: SID.vehicle,
      },
    },
  },
  'private-liability': {
    policyholder: {
      KEY_LOSS: {
        setID: '5C5436F13FD8E911AA5EC9768AD49726',
        setSID: SID.privateLiability,
      },
      PERSON_DAMAGE: {
        setID: '615436F13FD8E911AA5EC9768AD49726',
        setSID: SID.privateLiability,
      },
      ANIMAL_DAMAGE: {
        setID: '5B5436F13FD8E911AA5EC9768AD49726',
        setSID: SID.privateLiability,
      },
    },
    party: {
      LIABILITY: {
        setID: 'A5A4B64402A3E91180DF005056A2E9D6',
        setSID: SID.privateLiability,
      },
    },
  },
  accident: {
    HOUSEHOLD: {
      setID: 'F7DC726DC806EA11AA5EC9768AD49726',
      setSID: SID.accident,
    },
    OCCUPATIONAL: {
      setID: 'F1DC726DC806EA11AA5EC9768AD49726',
      setSID: SID.accident,
    },
    ON_THE_WAY: {
      setID: 'F2DC726DC806EA11AA5EC9768AD49726',
      setSID: SID.accident,
    },
    OTHER: {
      setID: '20ED21E8C806EA11AA5EC9768AD49726',
      setSID: SID.accident,
    },
  },
  'legal-protection': {
    PRIVATE: {
      setID: '78CC1A3C5947EA11AA5EC9768AD49726',
      setSID: SID.legalProtection,
    },
    OCCUPATION: {
      setID: '72CC1A3C5947EA11AA5EC9768AD49726',
      setSID: SID.legalProtection,
    },
    TRAFFIC: {
      setID: '73CC1A3C5947EA11AA5EC9768AD49726',
      setSID: SID.legalProtection,
    },
    LIVING_AND_RENTING: {
      setID: '7BCC1A3C5947EA11AA5EC9768AD49726',
      setSID: SID.legalProtection,
    },
  },
  property: {
    FIRE: {
      setID: '6243E1FE9E78EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
    THEFT: {
      setID: 'BD05E494A078EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
    WATER: {
      setID: 'AE36C29FA078EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
    STORM: {
      setID: '12B43EC3A078EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
    HAZARDS: {
      setID: '28C234D2A078EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
    GLASS: {
      setID: '9D957039A178EA11AA5EC9768AD49726',
      setSID: SID.property,

    },
  },
  upload: {
    setID: 'ECDFB73530CBEA11AA5EC9768AD49726',
    setSID: SID.upload,
  },
  'collision-abroad': {
    setID: 'A602C97C0F03EB11AA5EC9768AD49726',
    setSID: SID.collisionAbroad,
  },
};

export default flowToId;
