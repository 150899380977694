import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgAuszahlung } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPayingOutDetails } from '../../../helpers/formData/collisionAbroadFormData';

const PayingOutDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isRepaired,
    ibanName,
    iban,
    invoice,
    repairShopName,
    workshopPhone,
  } = getPayingOutDetails(formData);
  if (!isRepaired) return false;

  return (
    <Details iconLeft={SvgAuszahlung} summary={t(`${localePath}.paying-out.title`)} className={className}>
      <p>
        {t(`${localePath}.paying-out.content-iban-name`, { ibanName })}
      </p>
      <p>
        {t(`${localePath}.paying-out.content-iban`, { iban })}
      </p>
      <p>
        {t(`${localePath}.paying-out.content-invoice-${invoice}`, {
          repairShopName,
          workshopPhone: workshopPhone ? t(`${localePath}.paying-out.workshop-phone`, { workshopPhone }) : '',
        })}
      </p>
    </Details>
  );
};

PayingOutDetails.propTypes = legalConsentProps.sectionProps;
PayingOutDetails.defaultProps = legalConsentProps.sectionDefaults;

PayingOutDetails.contextTypes = {
  t: PropTypes.func,
};

export default PayingOutDetails;
