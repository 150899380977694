import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Checklist from './Checklist';
import HeadingForm from '../../layout/HeadingForm';
import Toggle from '../../components/Toggle';
import { NAV_DELAY } from '../../helpers/navigation';
import { getSign, getNumber } from '../../helpers/sections';
import { navigateToPath } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';

let ts;

const SelectBoolWithIconList = ({
  localePath,
  yesNoQuestion,
  isNumberQuestion,
  options,
  onChange,
  goForward,
  values,
  name,
  checklist,
  checklistIconClass,
  classes,
}, { t }) => {
  const onClick = value => () => {
    const target = { name, type: 'text', value };
    onChange({ target });
    clearTimeout(ts);
    ts = setTimeout(goForward, NAV_DELAY);
  };

  const columns = Object.keys(checklist).map(top => Object.keys(checklist[top]).map(val => ({
    label: t(`${localePath}.checklist.${top}.${val}`),
    icon: checklist[top][val],
  })));

  const currentValue = values[name];
  const optionsLength = options.length;

  return (
    <ContentWrapper>
      <HeadingForm
        value={t(`${localePath}.heading`)}
        commentValue={t(`${localePath}.comment`)}
      />

      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className="esc_col-12 esc_col-m-12">
            <Checklist columns={columns} iconClass={checklistIconClass} localePath={localePath} />
          </div>
        </div>
      </div>

      {options.map(({ key, sign }, index) => (
        <Toggle
          key={key}
          index={index}
          option={key}
          onClick={onClick(key)}
          selected={currentValue === key}
          sign={sign || (isNumberQuestion ? getNumber(index, optionsLength) : getSign(index, yesNoQuestion, t))}
          toggleText={t(`${localePath}.options.${key}`)}
          complementaryText={t(`${localePath}.options.${key}-comment`)}
        />
      ))}
    </ContentWrapper>
  );
};

SelectBoolWithIconList.propTypes = {
  localePath: PropTypes.string,
  name: PropTypes.string,
  yesNoQuestion: PropTypes.bool,
  isNumberQuestion: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
  checklist: PropTypes.objectOf(PropTypes.shape()),
  checklistIconClass: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};


SelectBoolWithIconList.defaultProps = {
  localePath: '',
  name: '',
  values: {},
  options: [],
  yesNoQuestion: true,
  isNumberQuestion: false,
  onChange: () => {},
  goForward: () => {},
  checklist: {},
  checklistIconClass: '',
  classes: {},
};

SelectBoolWithIconList.contextTypes = {
  t: PropTypes.func,
};


export { SelectBoolWithIconList as SelectBoolWithIconListPlain };

/* istanbul ignore next */
const mapStateToProps = ({ formData }) => ({ formData });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectBoolWithIconList);
