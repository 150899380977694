import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import { legalConsentProps } from '../../../models/panels';
import { getNoteData } from '../../../helpers/formData/privateLiabilityFormData';

const NoteDetails = ({ formData, localePath, className }, { t }) => {
  const data = getNoteData(formData);
  if (!data.notes) return false;

  return (
    <Details
      iconLeft={InfoIcon}
      summary={t(`${localePath}.notes.title`)}
      className={className}
    >
      <p>{data.notes}</p>
    </Details>
  );
};

NoteDetails.propTypes = legalConsentProps.sectionProps;
NoteDetails.defaultProps = legalConsentProps.sectionDefaults;

NoteDetails.contextTypes = {
  t: PropTypes.func,
};

export default NoteDetails;
