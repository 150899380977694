const styles = theme => ({
  container: {
    margin: '20px 20px 16px 20px',
    textAlign: 'left',
  },
  heading: {
    color: theme.colors.themeBrandColor,
    fontFamily: theme.fedraSerif,
    lineHeight: '21px',
    marginBottom: '2px',
  },
  starsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  starsLabel: {
    color: theme.colors.themeDarkestGray,
    opacity: '0.6',
    fontSize: theme.labelFontSize,
    paddingLeft: '8px',
  },
  stars: {
    '& .ee_star-rating__star--filled': {
      fill: `${theme.colors.themeBrandColor} !important`,
    },
  },
  description: {
    color: theme.colors.themeDarkestGray,
    fontSize: theme.labelFontSize,
    marginTop: '16px',
    lineHeight: '21px',
  },
});

export default styles;
