import {
  getOneFromMultiProxy,
  getDateValueProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  trafficOptions,
  trafficVehicleNumbers,
  trafficDamageOptions,
  trafficOffenceOptions,
  trafficContractOptions,
} from '../../../helpers/formData/legalProtectionFormData';

export default {
  traffic_options: [
    claimsKeys.legalProtection.CATEGORY,
    getOneFromMultiProxy({
      damages: trafficOptions.DAMAGES,
      offence: trafficOptions.OFFENCE,
      'contract-law': trafficOptions.CONTACT_LAW,
      different: trafficOptions.DIFFERENT,
    }),
  ],
  'traffic_vehicle-number': [
    claimsKeys.legalProtection.HOLDER,
    getOneFromMultiProxy({
      policyholder: trafficVehicleNumbers.POLICYHOLDER,
      spouse: trafficVehicleNumbers.SPOUSE,
      child: trafficVehicleNumbers.CHILD,
      different: trafficVehicleNumbers.DIFFERENT,
    }),
  ],
  traffic_damages_options: [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      property: trafficDamageOptions.PROPERTY,
      'personal-injury': trafficDamageOptions.PERSONAL_INJURY,
      both: trafficDamageOptions.BOTH,
      different: trafficDamageOptions.DIFFERENT,
    }),
  ],
  traffic_offence_options: [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'speed-violation': trafficOffenceOptions.SPEED_VIOLATION,
      accident: trafficOffenceOptions.ACCIDENT,
      'red-light-violation': trafficOffenceOptions.RED_LIGHT_VIOLATION,
      distance: trafficOffenceOptions.DISTANCE,
      'unauthorized-removal': trafficOffenceOptions.UNAUTHORIZED_REMOVAL,
      'physical-injury': trafficOffenceOptions.PHYSICAL_INJURY,
      'drink-driving': trafficOffenceOptions.DRINK_DRIVING,
      different: trafficOffenceOptions.DIFFERENT,
    }),
  ],
  traffic_contract_options: [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'contract-of-sale': trafficContractOptions.CONTRACT_OF_SALE,
      repair: trafficContractOptions.REPAIR,
      different: trafficContractOptions.DIFFERENT,
    }),
  ],
  traffic_damages_what: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  traffic_offence_what: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  traffic_contract_what: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  traffic_other: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
};
