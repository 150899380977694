import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import TelephoneIcon from '@eg/elements/Icons/TelephoneIcon';
import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import {
  SvgRechtsberatung,
  SvgDoktorhut,
  SvgHaende1,
} from '../../../helpers/IconLoader';
import styles from './styles.jss';

const LegalProtectionThankYou = ({
  classes, getTranslation, options,
}) => {
  const icons = {
    'law-consulting': SvgRechtsberatung,
    mortarboard: SvgDoktorhut,
    handshake: SvgHaende1,
  };

  const openCallApp = () => {
    window.location.href = `tel:${getTranslation('call-number-value')}`;
  };

  return (
    <div className={`esc_grid ${classes.container}`}>
      <div className="esc_grid__wrapper">
        <div className="esc_col-12">
          <p className={classes.line} />
        </div>
        <div className="esc_col-12">
          <h2 className={classes.title}>{getTranslation('legal-protection-heading')}</h2>
        </div>
        <div className="esc_col esc_col-12">
          <div className="esc_grid__wrapper">
            {options.map(({ key, icon }, index) => {
              const IconComponent = icons[icon];

              return (
                <div
                  key={key}
                  className={`${classes.cardContainer} esc_col-12 esc_col-s-6 esc_col-m-4 esc_col-l-4`}
                >
                  <Card padding="small" className={classes.card}>
                    <div>
                      <IconComponent className={classes.cardIcon} />
                      <h4 className={classes.cardTitle}>
                        {getTranslation(`services.${key}.title`)}
                      </h4>
                      <p className={classes.cardContent}>
                        {getTranslation(`services.${key}.desc`)}
                      </p>
                    </div>
                    <div className={`esc_col-12 ${classes.cardBottom}`}>
                      <Button
                        colorScheme="regular"
                        className={classes.cardButton}
                        variant="primary"
                        onClick={() => openCallApp()}
                      >
                        <span className={classes.cardButtonContent}>
                          <TelephoneIcon width={20} height={20} />
                          {getTranslation('call-number-label')}
                        </span>
                      </Button>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.messagesContainer}>
          <p className={classes.message}>{getTranslation('footer.message-first')}</p>
          <p className={classes.message}>{getTranslation('footer.message-second')}</p>
        </div>
      </div>
    </div>
  );
};

LegalProtectionThankYou.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  getTranslation: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOf([
      'legal-advice',
      'specialized-attorney',
      'mediation',
    ]),
    icon: PropTypes.oneOf([
      'law-consulting',
      'mortarboard',
      'handshake',
    ]),
  })),
};

LegalProtectionThankYou.defaultProps = {
  classes: {},
  getTranslation: () => {},
  options: [],
};

LegalProtectionThankYou.contextTypes = {
  t: PropTypes.func,
};

export { LegalProtectionThankYou as LegalProtectionThankYouPlain };
export default compose(
  injectJss(styles),
)(LegalProtectionThankYou);
