const styles = theme => ({
  footer: {
    bottom: 0,
    width: '100vw',
    backgroundColor: theme.colors.themePrimary,
  },
  wrapper: {
    color: theme.colors.themeWhite,
    lineHeight: '24px',
  },
  leftCol: {
    fontSize: '12px',
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      textAlign: 'center',
    },
  },
  rightCol: {
    textAlign: 'right',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      textAlign: 'center',
    },
  },
  link: {
    fontSize: '14px',
    color: theme.colors.themeWhite,
    textDecoration: 'none',
    '&:first-child': {
      marginRight: '8px',
    },
    '&:last-child': {
      marginLeft: '8px',
    },
  },
});

export default styles;
