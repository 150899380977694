import PropTypes from 'prop-types';
import { startsWith } from 'lodash';

const props = {
  localePath: PropTypes.string,
  data: PropTypes.shape({
    'claim-number': PropTypes.string,
    'claim-module': PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
  updateField: PropTypes.func,
  pathName: PropTypes.string,
  name: PropTypes.string,
};

const CLAIM_NUMBER_CONTEXTS = [
  {
    contains: ['KS', 'SD'],
    path: 'vehicle',
  },
  {
    contains: ['US'],
    path: 'accident',
  },
  {
    contains: ['HS', 'CS'],
    path: 'private-liability',
  },
  {
    contains: ['SS', 'SI', 'TS'],
    path: 'property',
  },
  {
    contains: ['SR'],
    path: 'legal-protection',
  },
];

export const getClaimModule = claimNumber => CLAIM_NUMBER_CONTEXTS
  .find(ctx => ctx.contains.some(startingChars => startsWith(claimNumber.toLowerCase(), startingChars.toLowerCase())));

const defaults = {
  localePath: '',
  data: {
    'claim-number': '',
    'claim-module': '',
  },
  isMobile: false,
  classes: {},
  updateField: null,
  pathName: '',
  name: '',
  getValidationProps: () => {},
};

export const fieldName = {
  CLAIM_MODULE: 'claim-module',
  CLAIM_NUMBER: 'claim-number',
};

export default {
  props,
  defaults,
};
