import PropTypes from 'prop-types';

import {
  SvgCarGlasbruch,
  SvgCarVandalism,
  SvgTiere,
  SvgDerSchadenDesUnfallbeteiligten,
  SvgCarDiebstahl,
  SvgCarExplosion,
  SvgCarUnwetter,
  SvgCheck,
  SvgFamilie,
  SvgHundHundehalter,
  SvgKey,
  // legal-protection module
  SvgDigitalePost,
  SvgRechtsberatung,
  SvgTelefonhoerer,
  SvgImmobilien,
  // SvgAuto4,
  SvgHaus,
  SvgDokument7,
  SvgReise,
  SvgHand9,
  SvgParagraph,
  SvgHand6,
  SvgBlaulicht,
  SvgRechtsschutzVerkehr,
  // accident module
  SvgHaushalt,
  SvgBerufsSchule,
  SvgArbeitsSchulweg,
  SvgMannGipsarm4,
  SvgFussball,
  SvgBall,
  SvgVerkehr,
  SvgAuto4,
  SvgPflaster,
  SvgSchneeflocke,
  SvgFahrrad1,
  SvgPferd,
  SvgGolftascheGolfschlaeger,
  SvgSportShoes,
  SvgFußgaenger,
  SvgEBike,
  SvgSegway,
  SvgMotorrad,
  // SvgLadestation,
  SvgWohnmobil,
  // property module
  SvgFeuer,
  SvgBett,
  SvgEigenverschulden,
  SvgEuro,
  SvgFensterGlas,
  SvgFremdverschulden,
  SvgGlasbruch,
  SvgHaus2,
  SvgHaus9,
  SvgLadenMarkise,
  SvgLeitungswasser,
  SvgPhotoCopy4,
  SvgPolice,
  SvgSchaden,
  SvgSpuelmaschine,
  SvgSturm,
  SvgSturmUndHagel,
  SvgUeberschwemmung,
  SvgWand,
  SvgWasserhahn,
  SvgFragezeichen1,
  SvgBlackFactory,
  SvgAnwalt,
  SvgAnlagevermittler,
  SvgSteuerberater,
  SvgImmobilienverwalter,
  SvgVerwalter,
  SvgItUnternehmen,
} from '../../helpers/IconLoader';

const icons = {
  collision: SvgDerSchadenDesUnfallbeteiligten,
  fire: SvgCarExplosion,
  glass: SvgCarGlasbruch,
  theft: SvgCarDiebstahl,
  vandalism: SvgCarVandalism,
  water: SvgCarUnwetter,
  animal: SvgTiere,
  'person-damage': SvgFamilie,
  'animal-damage': SvgHundHundehalter,
  'key-loss': SvgKey,
  // legal-protection module
  'digital-mail': SvgDigitalePost,
  'law-consulting': SvgRechtsberatung,
  'telephone-receiver': SvgTelefonhoerer,
  'real-estate': SvgImmobilien,
  car: SvgAuto4,
  house: SvgHaus,
  'document-with-pencil': SvgDokument7,
  travel: SvgReise,
  'hand-with-heart': SvgHand9,
  paragraph: SvgParagraph,
  'hand-with-money': SvgHand6,
  'blue-light': SvgBlaulicht,
  'legal-protection-traffic': SvgRechtsschutzVerkehr,
  // accident module
  household: SvgHaushalt,
  occupational: SvgBerufsSchule,
  onTheWay: SvgArbeitsSchulweg,
  'plaster-cast-arm': SvgMannGipsarm4,
  football: SvgFussball,
  ball: SvgBall,
  // TODO remove different namings
  traffic: SvgVerkehr,
  auto: SvgAuto4,
  plaster: SvgPflaster,
  snowflake: SvgSchneeflocke,
  bicycle: SvgFahrrad1,
  horse: SvgPferd,
  golfclub: SvgGolftascheGolfschlaeger,
  'sport-shoes': SvgSportShoes,
  pedestrian: SvgFußgaenger,
  ebike: SvgEBike,
  motorcycle: SvgMotorrad,
  'charging-station': SvgSegway,
  camper: SvgWohnmobil,
  // property module
  flame: SvgFeuer,
  bed: SvgBett,
  'own-fault': SvgEigenverschulden,
  euro: SvgEuro,
  'window-glass': SvgFensterGlas,
  'broken-glass': SvgGlasbruch,
  house2: SvgHaus2,
  'house-on-fire': SvgHaus9,
  'shop-awning': SvgLadenMarkise,
  'tap-water': SvgLeitungswasser,
  photoCopy: SvgPhotoCopy4,
  police: SvgPolice,
  damage: SvgSchaden,
  dishwasher: SvgSpuelmaschine,
  storm: SvgSturm,
  'storm-and-hail': SvgSturmUndHagel,
  flooding: SvgUeberschwemmung,
  wall: SvgWand,
  'water-tap': SvgWasserhahn,
  'question-mark': SvgFragezeichen1,
  'third-party-fault': SvgFremdverschulden,
  'black-factory': SvgBlackFactory,
  lawyer: SvgAnwalt,
  'tax-advisor': SvgSteuerberater,
  'investment-agent': SvgAnlagevermittler,
  'property-manager': SvgImmobilienverwalter,
  association: SvgVerwalter,
  'it-software': SvgItUnternehmen,
};

const checkIcon = SvgCheck;

const optionKeys = [
  'household',
  'occupational',
  'onTheWay',
  'other',
  'football',
  'ski',
  'bike',
  'horse',
  'other-ballrelated',
  'other',
  'sport',
  'car',
  'motorcycle',
  'bike',
  'pedestrian',
  'ebike',
  'other-electric',
  'traffic',
  'fire-or-explosion',
  'collision',
  'window-damage',
  'theft',
  'vandalism',
  'damage-caused-by-weather',
  'damage-caused-by-an-animal',
  'person-damage',
  'animal-damage',
  'key-loss',
  'private',
  'occupation',
  'traffic',
  'living-and-renting',
  'call-app',
  'call-service',
  'contract-law',
  'travel-law',
  'social-law',
  'law-pension',
  'different',
  'damages',
  'offence',
  'contract-law',
  'wind',
  'personal-negligence',
  'external-negligence',
];

const buttonImageResource = PropTypes.shape({
  key: PropTypes.oneOf(optionKeys),
  icon: PropTypes.oneOf(Object.keys(icons)),
  include: PropTypes.arrayOf(PropTypes.string),
});

const props = {
  localePath: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.oneOf(optionKeys),
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(buttonImageResource),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
  classes: PropTypes.object,
  pathName: PropTypes.string,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

const defaults = {
  localePath: '',
  data: '',
  classes: {},
  options: [],
  pathName: '',
  onChange: () => {},
  goForward: () => {},
  updateField: () => {},
  getValidationProps: () => {},
};

export default {
  props,
  defaults,
  icons,
  checkIcon,
};
