const styles = ({ colors }) => ({
  gridWrapper: {
    justifyContent: 'center',
  },
  messageBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0px !important',
  },
  infoIcon: {
    paddingRight: 8,
    fill: `${colors.themeDarkerGray} !important`,
  },
  messageBoxContent: {
    width: '100%',
    paddingTop: '5px',
    paddingLeft: '5px',
  },
  link: {
    color: colors.themePrimary,
    textDecoration: 'none',
  },
  headingPosition: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0px 8px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  toggleLabel: {
    fontSize: '16px',
    width: '100%',
    height: '100%',
    color: colors.themeDarkestGray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
    padding: '8px 0 16px',
  },
  icon: {
    color: colors.extendedIconBlack,
    width: '120px !important',
    height: '120px !important',
    marginTop: '16px',
  },
});

export default styles;
