import {
  NO,
  getPhoneValue,
  getYesNoValue,
  getTrackerAgreement,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getDateValueProxy,
  arrayIncludesKeyAndValueProxy,
  getCountryCode,
  getSpecialLicenseNumber,
  stripSpacesFromIban,
  getSalutationValue,
} from '../claimData.helpers';
import { claimsKeys, getCarMapKey } from '../../../helpers/formData/formData';

export default {
  vandalism_day: {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  vandalism_location: {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'vandalism_is-ready-for-driving': [claimsKeys.IS_READY_FOR_DRIVING, getYesNoValue],
  'vandalism_airbags-were-deployed': [claimsKeys.AIRBAGS_WERE_DEPLOYED, getYesNoValue],
  'vandalism_warning-lights-activated': [claimsKeys.WARNING_LIGHTS_ACTIVATED, getYesNoValue],
  'vandalism_fluids-lost': [claimsKeys.FLUIDS_LOST, getYesNoValue],
  'vandalism_car-map': {
    describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
    'front-left': [getCarMapKey('front-left'), arrayIncludesKeyAndValueProxy('carMap', 'front-left')],
    'front-middle': [getCarMapKey('front-middle'), arrayIncludesKeyAndValueProxy('carMap', 'front-middle')],
    'front-right': [getCarMapKey('front-right'), arrayIncludesKeyAndValueProxy('carMap', 'front-right')],
    'middle-left': [getCarMapKey('middle-left'), arrayIncludesKeyAndValueProxy('carMap', 'middle-left')],
    'middle-part': [getCarMapKey('middle-part'), arrayIncludesKeyAndValueProxy('carMap', 'middle-part')],
    'middle-right': [getCarMapKey('middle-right'), arrayIncludesKeyAndValueProxy('carMap', 'middle-right')],
    'back-left': [getCarMapKey('back-left'), arrayIncludesKeyAndValueProxy('carMap', 'back-left')],
    'back-middle': [getCarMapKey('back-middle'), arrayIncludesKeyAndValueProxy('carMap', 'back-middle')],
    'back-right': [getCarMapKey('back-right'), arrayIncludesKeyAndValueProxy('carMap', 'back-right')],
  },
  // !Special case after prefilling data
  'vandalism_vehicle-id-ergo_license-number': {
    part1: [claimsKeys.owner.LICENSE_NUMBER, getSpecialLicenseNumber],
    claimNumber: claimsKeys.POLICY_NUMBER,
  },
  'vandalism_contact-data': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
    agreement: [claimsKeys.owner.TRACKER_AGREEMENT, getTrackerAgreement],
  },
  'vandalism_police_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'vandalism_police_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'vandalism_damaged-parts': claimsKeys.DAMAGED_PARTS,
  'vandalism_car-repair_use-calculation-app': [claimsKeys.USE_CALCULATION_APP, getYesNoValue],
  'vandalism_vehicle-type': [claimsKeys.VEHICLE_TYPE, getOneFromMultiProxy({
    car: claimsKeys.vehicleType.CAR,
    truck: claimsKeys.vehicleType.TRUCK,
    motorcycle: claimsKeys.vehicleType.MOTORCYCLE,
    other: claimsKeys.vehicleType.OTHER,
  })],
  'vandalism_car-repair_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'vandalism_car-repair_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'vandalism_car-repair_customer-services_use-kva-service': [claimsKeys.USE_KVA_SERVICE, getYesNoValue],
  'vandalism_car-repair_customer-services_select-workshop': {
    name: claimsKeys.KVA_SERVICE_WORKSHOP_NAME,
    address: claimsKeys.KVA_SERVICE_WORKSHOP_ADDRESS,
    city: claimsKeys.KVA_SERVICE_WORKSHOP_CITY,
    postalcode: claimsKeys.KVA_SERVICE_WORKSHOP_PLZ,
  },
  'vandalism_car-repair_repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'vandalism_car-repair_repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'vandalism_car-repair_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'vandalism_car-repair_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'vandalism_car-repair_want-to-repair': [
    claimsKeys.DAMAGE_REPAIR,
    getOneFromMultiProxy({
      yes: claimsKeys.REPAIR,
      no: claimsKeys.PAYOUT,
    }),
  ],
  'vandalism_car-repair_not-repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'vandalism_car-repair_not-repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'vandalism_car-repair_want-to-recommend-repair-shop': [claimsKeys.RECOMMEND_REPAIR_SHOP, getYesNoValue],
  'vandalism_car-repair_not-repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'vandalism_car-repair_is-vehicle-in-workshop': [claimsKeys.WORKSHOP, getYesNoValue],
  'vandalism_car-repair_postal-code': claimsKeys.WORKSHOP_POSTAL_CODE,
  'vandalism_car-repair_select-workshop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    address: claimsKeys.WORKSHOP_ADDRESS,
    phone: claimsKeys.WORKSHOP_PHONE,
    city: claimsKeys.WORKSHOP_CITY,
    postalcode: claimsKeys.WORKSHOP_POSTAL_CODE,
  },
};
