import { equals } from 'ramda';
import { types } from '../../actions/constants';

const initialState = {
  delay: null,
  current: {
    root: '',
    params: {},
  },
  next: null,
  isNextActive: true,
  isPreviousActive: true,
};

const convertToNavigationParams = match => ({
  root: match.url.split('/')[1],
  params: match.params,
});


const navigation = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case types.NAVIGATION_UPDATE_END: {
      const { match } = action;
      const newState = {
        ...state,
        delay: null,
        current: convertToNavigationParams(match),
        next: null,
      };

      if (equals(state, newState)) {
        return state;
      }

      return newState;
    }


    case types.NAVIGATION_UPDATE_START: {
      const { match, delay } = action;
      const newState = {
        ...state,
        isNextActive: false,
        isPreviousActive: true,
        delay,
        next: convertToNavigationParams(match),
      };

      if (equals(state, newState)) {
        return state;
      }

      return newState;
    }

    case types.NAVIGATION_UPDATE_STATE: {
      const { isNextActive, isPreviousActive } = action;
      return {
        ...state,
        isNextActive,
        isPreviousActive,
      };
    }

    case types.NAVIGATION_SET_SUMMARY_PAGE: {
      return {
        ...state,
        summaryPage: action.summaryPage,
      };
    }

    default: {
      return state;
    }
  }
};

export default navigation;
