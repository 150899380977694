import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getBrokerContactData } from '../../../helpers/formData/vehicleFormData';

const ContactDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isAnimalFlow,
    firstName,
    lastName,
    phone,
    email,
    isAnimalDamage,
    ownerIsDriver,
    driver,
  } = getBrokerContactData(formData);

  if (isLiabilityFlow) {
    return false;
  }
  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={t(`${localePath}.contact.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact.content-name`, { firstName, lastName })}
      </p>
      <p>
        {t(`${localePath}.contact.content-phone`, { phone })}
      </p>
      <p>
        {t(`${localePath}.contact.content-email`, { email })}
      </p>
      { isAnimalFlow && isAnimalDamage && (
        <div>
          {t(`${localePath}.contact.animal-content-driver`, {
            driver: ownerIsDriver
              ? `${firstName}, ${lastName}`
              : `${driver.firstName}, ${driver.lastName}`,
          })}
          {!ownerIsDriver && driver.phone && (
            <div>
              {t(`${localePath}.contact.animal-content-driver-contact`, { phone: driver.phone })}
            </div>
          )}
        </div>
      )}
    </Details>
  );
};

ContactDetails.propTypes = legalConsentProps.sectionProps;
ContactDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetails;
