import React from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import { multipleSelectProps } from '../../models/panels';
import ErgoCheckbox from '../../components/ErgoCheckbox';
import { types } from '../../actions/constants';
import styles from './styles.jss';

class MultipleSelect extends React.PureComponent {
  handleClick = key => {
    const { updateField, pathName, getValidationProps } = this.props;
    const { name } = getValidationProps();
    updateField(name, key, pathName);
  };

  // TODO before upgrading React to 17: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();

    if (!isEqual(data, nextProps.data)) {
      const target = {
        name,
        value: nextProps.data,
      };
      onChange({ target });
    }
  }

  renderLabel = option => {
    const { classes, localePath } = this.props;
    const { t } = this.context;

    const Icon = multipleSelectProps.iconList[option]
      ? multipleSelectProps.iconList[option]
      : null;

    return (
      <div className={classes.labelWrapper}>
        {Icon
        && (
        <span className={classes.icon}>
          <Icon viewBox="0 0 48 48" />
        </span>
        )}
        {t(`${localePath}.options.${option}`)}
      </div>
    );
  };

  render() {
    const {
      classes, options, data, getValidationProps,
    } = this.props;
    const { onChange } = getValidationProps();

    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          {options.map(option => (
            <div key={option.key} className={`esc_col esc_col-12 ${classes.option}`}>
              <ErgoCheckbox
                onClick={() => this.handleClick(option.key)}
                onChange={onChange}
                label={this.renderLabel(option.key)}
                checked={data.includes(option.key)}
                displayError={false}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

MultipleSelect.propTypes = multipleSelectProps.props;

MultipleSelect.defaultProps = multipleSelectProps.defaults;

MultipleSelect.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE_MULTI,
    fieldName: name,
    pathName,
    payload,
  }),
});

export { MultipleSelect as MultipleSelectPlain };
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectJss(styles),
  withSingleFieldValidation,
)(MultipleSelect);
