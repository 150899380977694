import CommonService from '../commonService';

import { userFlows as privateLiabilityUserFlows } from '../../helpers/formData/privateLiabilityFormData';
import { userFlows as vehicleUserFlows, isCollisionFlow } from '../../helpers/formData/vehicleFormData';
import { userFlows as propertyUserFlows } from '../../helpers/formData/propertyFormData';
import { userFlows as legalProtectionUserFlows } from '../../helpers/formData/legalProtectionFormData';
import { userFlows as accidentUserFlows } from '../../helpers/formData/accidentFormData';
import { claimsKeys, isLiabilityFlow } from '../../helpers/formData/formData';

const mappedUserFlows = {
  'legal-protection': legalProtectionUserFlows,
  property: propertyUserFlows,
  vehicle: vehicleUserFlows,
  broker: vehicleUserFlows,
  'private-liability': privateLiabilityUserFlows,
  accident: accidentUserFlows,
};

export const claimDataQueryBuilder = (claimObj, resourceType) => {
  const whoFills = claimObj[claimsKeys.WHO_FILLS];
  const whatHappened = claimObj[claimsKeys.WHAT_HAPPENED];
  const kindOfDamage = claimObj[claimsKeys.KIND_OF_DAMAGE];
  const accidentType = claimObj[claimsKeys.accident.TYPE];

  if (whoFills && isLiabilityFlow(claimObj)) {
    return `?moduleName=${resourceType}&flowName=liability`;
  }

  if (whoFills && isCollisionFlow(whoFills, whatHappened)) {
    return `?moduleName=${resourceType}&flowName=collision`;
  }

  const cumulatedFlows = mappedUserFlows[resourceType] || {};

  const gevoMatched = Object.keys(cumulatedFlows).find(
    key => cumulatedFlows[key] === whatHappened,
  );

  const sartkzMatched = Object.keys(cumulatedFlows).find(
    key => cumulatedFlows[key] === kindOfDamage,
  );

  const uartMatched = Object.keys(cumulatedFlows).find(
    key => cumulatedFlows[key] === accidentType,
  );

  const matchingFlowKey = gevoMatched || sartkzMatched || uartMatched;

  return matchingFlowKey
    ? `?moduleName=${resourceType}&flowName=${matchingFlowKey.toLowerCase()}`
    : `?moduleName=${resourceType}`;
};


const saveClaimData = (serviceType, data) => new CommonService(
  'bff-request-manager',
  `claim${claimDataQueryBuilder(data, serviceType)}`,
);

export default saveClaimData;
