import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { userTypes } from '../../../helpers/formData/formData';
import {
  getDamageOfAccidentData,
  getCarMapDamages,
} from '../../../helpers/formData/vehicleFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isCollisionFlow,
    isPersonalInjury,
    isPrivateVehicle,
    isThirdPartyPrivateVehicle,
    isItemDamage,
    isOwnCar,
    personalInjuryDesc,
    itemDamageDesc,
    vehicleType,
    licenseNumber,
    describeDamage,
  } = getDamageOfAccidentData(formData);

  if (!(isLiabilityFlow || (isCollisionFlow && !isOwnCar))) {
    return false;
  }

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.company-car`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.company-car`)
      : t(`${localePath}.incident.private-car`);
  };

  const getCarDamageMainText = () => (isLiabilityFlow ? `${localePath}.damage-of-accident.content-vehicle-liability` : `${localePath}.damage-of-accident.content-vehicle`);

  return (
    <Details
      iconLeft={SvgDerSchadenDesUnfallbeteiligten}
      summary={
        isLiabilityFlow
          ? t(`${localePath}.damage-of-accident.liability-title`)
          : t(`${localePath}.damage-of-accident.title`)
      }
      className={className}
    >
      {t(`${localePath}.damage-of-accident.content`, {
        contentDamage: t(`${localePath}.damage-of-accident.content-damage`, {
          personDamage: !isPersonalInjury
            ? t(`${localePath}.damage-of-accident.no-damage`) : '',
          itemDamage: !isItemDamage
            ? t(`${localePath}.damage-of-accident.no-damage`) : '',
        }),
        personalInjury: isPersonalInjury
          ? t(`${localePath}.damage-of-accident.personal-injury-desc`, { personalInjuryDesc }) : '',
        itemDamage: isItemDamage
          ? t(`${localePath}.damage-of-accident.item-damage-desc`, { itemDamageDesc }) : '',
        carDamage: vehicleType
          ? t(getCarDamageMainText(), {
            licenseNumber,
            carDamages: getCarMapDamages(formData, userTypes.THIRD_PARTY),
            vehicleType,
            liabilityVehicleType: getPrivateCompanyCarText(),
          })
          : '',
        describeDamage: describeDamage
          ? t(`${localePath}.damage-of-accident.describe-damage`, { describeDamage }) : '',
      })}
    </Details>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
