import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { getWhatDamagedBuildingTheftBusinessValueProxy } from './claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { theftBusinessOptions } from '../../../helpers/formData/propertyFormData';

const mappingPoliceIsReported = [claimsKeys.POLICE, getYesNoValue];

const mappingPoliceReportDetails = {
  department: claimsKeys.DEPARTMENT,
  caseNumber: claimsKeys.CASE_NUMBER,
};

const mappingHaveYouList = [claimsKeys.property.LIST_OF_STOLEN_GOODS, getYesNoValue];

const mappingWhatDamagedBuildingTheftBusiness = [
  claimsKeys.property.CAUSE_NOTE,
  getWhatDamagedBuildingTheftBusinessValueProxy,
];

export default {
  'theft-business_theft-business-damage-circumstances_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      burglary: theftBusinessOptions.BURGLARY,
      'attempted-burglary': theftBusinessOptions.ATTEMPTED_BURGLARY,
      robbery: theftBusinessOptions.ROBBERY,
      graffiti: theftBusinessOptions.GRAFFITI,
      different: theftBusinessOptions.DIFFERENT,
    }),
  ],
  'theft-business_theft-business-damage-circumstances_police-report_is-reported': mappingPoliceIsReported,
  'theft-business_theft-business-damage-circumstances_police-report_report-details': mappingPoliceReportDetails,
  'theft-business_have-you-list': mappingHaveYouList,
  'theft-business_theft-business-what-damaged-building_options': mappingWhatDamagedBuildingTheftBusiness,
};
