import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getAffectedAreaOfLifeDetails } from '../../../helpers/formData/legalProtectionFormData';
import { SvgParagraph } from '../../../helpers/IconLoader';

const AffectedAreaOfLifeDetails = ({ formData, localePath, className }, { t }) => {
  const {
    areaOfLife,
    relevantPerson,
    isLivingAndRentingFlow,
    primaryResidence,
    relevantPrimaryResidence,
    category,
    incident,
    date,
    description,
  } = getAffectedAreaOfLifeDetails(formData);

  let incidentNew = incident;

  if (incidentNew.includes('Testamentserrichtng')) {
    incidentNew = incidentNew.replace('Testamentserrichtng', 'Testamentserrichtung');
  }

  return (
    <Details
      iconLeft={SvgParagraph}
      summary={t(`${localePath}.affected.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.affected.area-of-life`, { areaOfLife })}
      </p>
      <p>
        {t(`${localePath}.affected.relevant-person`, { relevantPerson })}
      </p>
      {isLivingAndRentingFlow && (
        <>
          <p>
            {t(`${localePath}.affected.primary-residence`, { primaryResidence })}
          </p>
          {relevantPrimaryResidence.address && (
            <p>
              {t(`${localePath}.affected.relevant-primary-residence`, { ...relevantPrimaryResidence })}
            </p>
          )}
        </>
      )}
      {category && (
        <p>
          {t(`${localePath}.affected.i-am`, { category })}
        </p>
      )}
      <p>
        {t(`${localePath}.affected.kind-of-incident`, { incidentNew })}
      </p>
      {date && (
        <p>
          {t(`${localePath}.affected.date`, { date })}
        </p>
      )}
      { description && (
        <p>
          {t(`${localePath}.affected.description`, { description })}
        </p>
      )}
    </Details>
  );
};

AffectedAreaOfLifeDetails.propTypes = legalConsentProps.sectionProps;
AffectedAreaOfLifeDetails.defaultProps = legalConsentProps.sectionDefaults;

AffectedAreaOfLifeDetails.contextTypes = {
  t: PropTypes.func,
};

export default AffectedAreaOfLifeDetails;
