import { mediaQuery } from '../../styles/helpers'; // eslint-disable-line import/prefer-default-export
import { breakpoints } from '../../styles/constants';

const styles = ({
  summaryContainer: {

    ...mediaQuery.max(breakpoints.desktopNav, {
      paddingTop: '12%',
    }),
  },
});

export default styles;
