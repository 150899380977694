const styles = theme => ({
  modal: {
    '& .ee_modal__container': {
      maxWidth: '832px !important',
    },
  },
  mainContainer: {
    position: 'relative',
  },
  container: {
    paddingTop: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  image: {
    height: '274px',
    width: '193px',
    marginRight: '30px',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      height: 'auto',
      width: '100%',
      marginRight: 0,
    },
  },
  heading: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '38px',
  },
  comment: {
    lineHeight: '24px',
  },
});
export default styles;
