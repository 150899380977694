import PropTypes from 'prop-types';

const props = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  isValid: PropTypes.bool,
  onClick: PropTypes.func,
  formData: PropTypes.shape(Object),
};

const defaults = {
  localePath: '',
  classes: {},
  isValid: false,
  onClick: () => {},
  formData: {},
};

const sectionProps = {
  formData: PropTypes.shape(Object).isRequired,
  localePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  whatDocuments: PropTypes.arrayOf(PropTypes.string),
};

const sectionDefaults = {
  className: '',
  whatDocuments: null,
};

export default {
  props,
  defaults,
  sectionProps,
  sectionDefaults,
};
