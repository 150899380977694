import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import SvgIcon from '@eg/elements/SvgIcon';
import { ErgoElementsContext } from '@eg/elements/Provider';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoMaskedText from '../ErgoMaskedText';
import { maskTypes } from '../../helpers/masks';
import styles from './styles.jss';
import Stars from '../../assets/icons/Stars';


const ErgoLicenseNumber = ({
  localePath,
  classes,
  getValidationProps,
}, { t }) => {
  const formatObject = {
    L: {
      validate: (char) => /[a-zA-ZäöüÄÖÜß]/.test(char),
      transform: (char) => char.toUpperCase(),
    },
  };
  const mockedConfigs = [
    {
      arrayKey: 0,
      mask: maskTypes.LICENSE_NUMBER_PART_1,
      className: classes.part1,
      formatCharacters: formatObject,
      placeholder: t(`${localePath}.placeholders.label1`),
      validationProps: 'part1',
    },
    {
      arrayKey: 1,
      mask: maskTypes.LICENSE_NUMBER_PART_2,
      className: classes.part2,
      formatCharacters: formatObject,
      placeholder: t(`${localePath}.placeholders.label2`),
      validationProps: 'part2',
    },
    {
      arrayKey: 2,
      mask: maskTypes.LICENSE_NUMBER_PART_3,
      className: classes.part3,
      placeholder: t(`${localePath}.placeholders.label3`),
      validationProps: 'part3',
    },
  ];
  return (
    <div className="esc_grid">
      <div className={`esc_grid__wrapper ${classes.wrapper}`}>
        <div className={`esc_col esc_col-12 ${classes.headLabel}`}>
          {t(`${localePath}.placeholders.headLabel`)}
        </div>
        <div className={`${classes.rectangle}`}>
          <ErgoElementsContext.Consumer>
            {({ idGenerator }) => (
              <SvgIcon
                idGenerator={idGenerator}
                viewBox="0 0 15 14"
                title={t('content.main-title')}
                width={15}
                height={14}
                className={classes.icon}
              >
                <Stars />
              </SvgIcon>
            )}
          </ErgoElementsContext.Consumer>
          <span className={classes.letter}>D</span>
        </div>
        {mockedConfigs.map(configInfo => (
          <div key={configInfo.arrayKey} className="esc_col-3 esc_col-s-2">
            <ErgoMaskedText {...configInfo} {...getValidationProps(configInfo.validationProps)} />
          </div>
        ))}
      </div>
    </div>
  );
};

ErgoLicenseNumber.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
};

ErgoLicenseNumber.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => { },
};

ErgoLicenseNumber.contextTypes = {
  t: PropTypes.func,
};

export { ErgoLicenseNumber as ErgoLicenseNumberPlain };
export default compose(
  injectSheet(styles),
  withMultiFieldValidation,
)(ErgoLicenseNumber);
