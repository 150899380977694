import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import HeadingForm from '../../layout/HeadingForm';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import styles from './styles.jss';

const SummaryNotice = ({
  localePath,
  classes,
  href,
}, { t }) => (
  <ContentWrapper
    title={t(`${localePath}.button.title`)}
    isButtonSubmit={false}
    href={href}
    className={classes.summaryContainer}
  >
    <HeadingForm
      commentValue={t(`${localePath}.comment`)}
      value={t(`${localePath}.heading`)}
    />
  </ContentWrapper>
);

SummaryNotice.propTypes = {
  localePath: PropTypes.string,
  href: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

SummaryNotice.defaultProps = {
  localePath: '',
  href: '',
  classes: {},
};

SummaryNotice.contextTypes = {
  t: PropTypes.func,
};

export { SummaryNotice as SummaryNoticePlain };
export default injectSheet(styles)(SummaryNotice);
