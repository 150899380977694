import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getContactOfAccidentData } from '../../../helpers/formData/assetLiabilityFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ContactDetailsDamaged = ({ formData, localePath, className }, { t }) => {
  const data = getContactOfAccidentData(formData);
  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      summary={t(`${localePath}.contact-of-accident.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact-of-accident.content-name`, {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-phone`, {
          phone: data.phone,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-address`, {
          address: data.address,
          postCode: data.postCode,
          city: data.city,
        })}
      </p>
    </Details>
  );
};

ContactDetailsDamaged.propTypes = legalConsentProps.sectionProps;
ContactDetailsDamaged.defaultProps = legalConsentProps.sectionDefaults;

ContactDetailsDamaged.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetailsDamaged;
