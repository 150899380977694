import PropTypes from 'prop-types';

export const FirstFromSectionResource = PropTypes.shape({
  name: PropTypes.string,
  component: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  sectionStart: PropTypes.string,
  section: PropTypes.string,
});

export const SectionResource = PropTypes.shape({
  active: PropTypes.bool,
  done: PropTypes.bool,
  first: FirstFromSectionResource,
  isDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
});
