import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getContactOfAccidentData } from '../../../helpers/formData/vehicleFormData';

const ContactOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isCollisionFlow,
    firstName,
    lastName,
    phone,
    email,
    address,
    postCode,
    city,
  } = getContactOfAccidentData(formData);

  if (!(isLiabilityFlow || (isCollisionFlow && firstName))) {
    return false;
  }

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      summary={
        isLiabilityFlow
          ? t(`${localePath}.contact-of-accident.liability-title`)
          : t(`${localePath}.contact-of-accident.title`)
      }
      className={className}
    >
      <p>
        {t(`${localePath}.contact-of-accident.content-name`, { firstName, lastName })}
      </p>
      { phone && (
        <p>
          {t(`${localePath}.contact-of-accident.content-phone`, { phone })}
        </p>
      )}
      { email && (
        <p>
          {t(`${localePath}.contact-of-accident.content-email`, { email })}
        </p>
      )}
      <p>
        {t(`${localePath}.contact-of-accident.content-address`, {
          address,
          postCode,
          city,
        })}
      </p>
    </Details>
  );
};

ContactOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
ContactOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactOfAccidentDetails;
