import PropTypes from 'prop-types';

const props = {
  localePath: PropTypes.string,
  classes: PropTypes.object,
  phoneNumber: PropTypes.string,
};

const defaults = {
  localePath: '',
  classes: {},
  phoneNumber: '',
  getValidationProps: () => {},
};

export default {
  props,
  defaults,
};
