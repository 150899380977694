import PropTypes from 'prop-types';

const props = {
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  setNavigationState: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string),
};

const defaults = {
  localePath: '',
  pathName: '',
  setNavigationState: () => {},
  formData: {},
  classes: {},
};

export default {
  props,
  defaults,
};
