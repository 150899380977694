import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgPolizeiUndZeugen } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getDamagedItems } from '../../../helpers/formData/propertyFormData';

const StolenDamagedObjectsDetails = ({ formData, localePath, className }, { t }) => {
  const items = getDamagedItems(formData);
  if (items.length === 0) return false;

  return (
    <Details
      iconLeft={SvgPolizeiUndZeugen}
      summary={t(`${localePath}.stolen-damaged-objects.title`)}
      className={className}
    >
      { items.map((item, i) => (
        <p key={item} style={{ wordBreak: 'break-all' }}>
          {t(`${localePath}.stolen-damaged-objects.content`, { item, index: i + 1 })}
        </p>
      ))}
    </Details>
  );
};

StolenDamagedObjectsDetails.propTypes = legalConsentProps.sectionProps;
StolenDamagedObjectsDetails.defaultProps = legalConsentProps.sectionDefaults;

StolenDamagedObjectsDetails.contextTypes = {
  t: PropTypes.func,
};

export default StolenDamagedObjectsDetails;
