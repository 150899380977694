import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { naturalOptions } from '../../../helpers/formData/propertyFormData';

export default {
  'natural-hazards_circumstances_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      flooding: naturalOptions.FLOODING,
      backwater: naturalOptions.BACKWATER,
      snow: naturalOptions.SNOW,
      different: naturalOptions.OTHER,
    }),
  ],
};
