import {
  getYesNoValueProxy,
  POLICYHOLDER,
  getYesNoValue,
  getUploadValues,
  getDescribeCircumstance,
  getOneFromMultiProxy,
  getUploadedFilesProxy,
  getOneCircumstanceFromMultiProxy,
  padPolicyNumber,
  getSalutationValue,
  getFirstNameValue,
  getLastNameValue,
  getPhoneValue,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  userFlows,
  relationships,
} from '../../../helpers/formData/legalProtectionFormData';

export default {
  'who-fills': [claimsKeys.WHO_FILLS, getYesNoValueProxy(POLICYHOLDER)],
  'which-field-of-law': [
    claimsKeys.WHAT_HAPPENED,
    getOneFromMultiProxy({
      private: userFlows.PRIVATE,
      occupation: userFlows.OCCUPATIONAL,
      traffic: userFlows.TRAFFIC,
      'living-and-renting': userFlows.LIVING_AND_RENTING,
    }),
  ],
  'who-need-help': {
    circumstance: [
      claimsKeys.legalProtection.RELATION,
      getOneCircumstanceFromMultiProxy({
        policyholder: relationships.POLICYHOLDER,
        partner: relationships.PARTNER,
        'own-child': relationships.OWN_CHILD,
        parents: relationships.PARENTS,
        different: relationships.DIFFERENT,
      }),
    ],
    describeCircumstance: [claimsKeys.legalProtection.RELATION_DESCRIPTION, getDescribeCircumstance],
  },
  'id-policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'policyholder-contact': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
  },
  'your-message': claimsKeys.YOUR_MESSAGE,
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
