const styles = theme => ({
  heading: {
    display: 'inline',
    lineHeight: '22px',
  },
  button: {
    color: theme.colors.themePrimary,
    backgroundColor: theme.colors.themeWhite,
    lineHeight: '22px',
  },
});

export default styles;
