import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { stormOptions, businessDamagedBuildingInsideOutside } from '../../../helpers/formData/propertyFormData';

export default {
  'storm-hail-business_circumstances_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      storm: stormOptions.STORM,
      hail: stormOptions.HAIL,
    }),
  ],
  'storm-hail-business_damage-building-inside-outside_options': [
    claimsKeys.property.DAMAGED_BUILDING_INSIDE_OUTSIDE,
    getOneFromMultiProxy({
      inside: businessDamagedBuildingInsideOutside.INSIDE,
      outside: businessDamagedBuildingInsideOutside.OUTSIDE,
      both: businessDamagedBuildingInsideOutside.BOTH,
    }),
  ],
};
