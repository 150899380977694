import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getContactData } from '../../../helpers/formData/accidentFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { getDate, getCountryName } from '../../../helpers/formData/formData';

const ContactDetails = ({ formData, localePath, className }, { t }) => {
  const data = getContactData(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={t(`${localePath}.contact.title`)}
      className={className}
    >
      { !data.isReporter && (
        <>
          <p>
            {t(`${localePath}.contact.content-name`, {
              firstName: data.firstName,
              lastName: data.lastName,
            })}
          </p>
          <p>
            {t(`${localePath}.contact.content-phone`, {
              phone: data.phone,
            })}
          </p>
          <p>
            {t(`${localePath}.contact.content-birth-date`, {
              date: getDate(data.date),
            })}
          </p>
          { !data.sameHousehold && (
            <p>
              {t(`${localePath}.contact.content-address`, {
                address: data.address,
                postCode: data.postCode,
                city: data.city,
                country: getCountryName(data.country),
              })}
            </p>
          )}
        </>
      )}
      { data.isReporter && (
        <>
          <p>
            {t(`${localePath}.contact.content-is-reporter`)}
          </p>
          <p>
            {t(`${localePath}.contact.content-release-confidentiality-${data.releaseConfidentiality}`)}
          </p>
        </>
      )}
    </Details>
  );
};

ContactDetails.propTypes = legalConsentProps.sectionProps;
ContactDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetails;
