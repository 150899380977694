import {
  getDateValueProxy,
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  privateOptions,
  privateContractLawOptions,
  privateTravelLawOptions,
  privateSocialLawOptions,
  privateLawPensionOptions,
} from '../../../helpers/formData/legalProtectionFormData';

export default {
  'private-protection_options': [
    claimsKeys.legalProtection.CATEGORY,
    getOneFromMultiProxy({
      'contract-law': privateOptions.CONTRACT_LAW,
      'travel-law': privateOptions.TRAVEL_LAW,
      'social-law': privateOptions.SOCIAL_LAW,
      'law-pension': privateOptions.LAW_PENSION,
      different: privateOptions.DIFFERENT,
    }),
  ],
  // Contract Law
  'private-protection_contract-law_options': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'car-purchase': privateContractLawOptions.CAR_PURCHES,
      'internet-purchase': privateContractLawOptions.INTERNET_PURCHES,
      'capital-investment': privateContractLawOptions.CAPITAL_INVESTMENT,
      voyage: privateContractLawOptions.VOYAGE,
      telecommunications: privateContractLawOptions.TELECOMMUNICATIONS,
      immovable: privateContractLawOptions.IMMOVABLE,
      different: privateContractLawOptions.DIFFERENT,
    }),
  ],
  'private-protection_contract-law_time-obligations': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  // Travel Law
  'private-protection_travel-law_options': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      delay: privateTravelLawOptions.DELAY,
      cancellation: privateTravelLawOptions.CANCELLATION,
      overbooking: privateTravelLawOptions.OVERBOOKING,
      'lack-of-travel': privateTravelLawOptions.LACK_OF_TRAVEL,
      different: privateTravelLawOptions.DIFFERENT,
    }),
  ],
  'private-protection_travel-law_delay': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_travel-law_cancellation': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_travel-law_overbooking': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_travel-law_lack-of-travel': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_travel-law_different': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  // Social Law
  'private-protection_social-law_options': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'pension-matter': privateSocialLawOptions.PENSION_MATTER,
      'degree-of-disability': privateSocialLawOptions.DEGREE_OF_DISABILITY,
      'health-insurance': privateSocialLawOptions.HEALTH_INSURANCE,
      hartz4: privateSocialLawOptions.HARTZ4,
      dispute: privateSocialLawOptions.DISPUTE,
      'unemployment-allowance': privateSocialLawOptions.UNEMPLOYMENT_ALLOWANCE,
      'nursing-insurance': privateSocialLawOptions.NURSING_INSURANCE,
      different: privateSocialLawOptions.DIFFERENT,
    }),
  ],
  'private-protection_social-law_time-obligations': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  // Pension Law
  'private-protection_pension-law_options': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'divorce-or-separation': privateLawPensionOptions.DIVORCE_OR_SEPARATION,
      children: privateLawPensionOptions.CHILDREN,
      alimony: privateLawPensionOptions.ALIMONY,
      'other-family-matters': privateLawPensionOptions.OTHER_FAMILY_MATTERS,
      succession: privateLawPensionOptions.SUCCESSION,
      will: privateLawPensionOptions.WILL,
      different: privateLawPensionOptions.DIFFERENT,
    }),
  ],
  'private-protection_pension-law_divorce-or-separation': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_pension-law_children': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_pension-law_alimony': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_pension-law_other-family-matters': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_pension-law_succession': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'private-protection_pension-law_will': claimsKeys.legalProtection.HERITAGE_ADVICE,
  'private-protection_pension-law_different': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  // Different
  'private-protection_different': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
};
