const styles = theme => ({
  markerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: -20,
    textAlign: 'left',
  },
  marker: {
    cursor: 'pointer',
  },
  selectedInfoBox: {
    zIndex: 10,
  },
  infoBox: {
    position: 'relative',
    bottom: -30,
    backgroundColor: theme.colors.themeWhite,
    transform: 'translate(-46%, 0)',
    paddingY: 12,
    width: 350,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    fontSize: 14,
  },
  distance: {
    color: theme.colors.themePrimary,
    fontWeight: 700,
  },
  cardName: {
    fontWeight: 700,
    marginBottom: 12,
    fontSize: 16,
  },
  closeButton: {
    fontSize: 8,
    cursor: 'pointer',
    zIndex: 40,
    backgroundColor: 'transparent',
    position: 'relative',
    maxWidth: 10,
  },
  cardFullAddress: {
    color: theme.fifthFontColor,
    fontSize: 14,
    marginBottom: 4,
  },
  cardAddress: {
    marginBottom: 4,
  },
});

export default styles;
