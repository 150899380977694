const styles = theme => ({
  container: {
    padding: '0',
    marginLeft: '10px',
  },
  wrapper: {
    paddingBottom: 40,
  },
  item: {
    display: 'flex',
    textAlign: 'left',
  },
  workshopIcon: {
    flexShrink: '0',
    color: theme.colors.themeSuccessColor,
    marginRight: 8,
    marginTop: 3,
  },
  uploadIcon: {
    flexShrink: '0',
    color: theme.colors.extendedIconBlack,
    marginRight: 8,
    marginTop: 3,
  },
  text: {
    flexShrink: '1',
    color: theme.colors.themeDarkestGray,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 8,
  },
  headingText: {
    marginBottom: 16,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold',
    [`@media (max-width: ${theme.breakpoints.breakpointM})`]: {
      marginTop: 16,
    },
  },
  // eslint-disable-next-line
  ['@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
    // IE10+ CSS here
    uploadIcon: {
      width: '16px',
    },
    workshopIcon: {
      width: '16px',
    },
  },
});
export default styles;
