const styles = ({ breakpoints }) => ({
  removeDescription: {
    '& .ee_form-row__descriptor': {
      width: '0 !important',
    },
    '& .ee_form-row__messages': {
      paddingLeft: '0 !important',
    },
    '& .ee_form-row__item-container': {
      [`@media (max-width: ${breakpoints.breakpointS})`]: {
        textAlign: 'left',
      },
    },
  },
});

export default styles;
