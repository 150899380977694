import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import injectSheet from 'react-jss';
import CloseIcon from '@eg/elements/Icons/CloseIcon';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ErgoInputText from '../../components/ErgoInputText';
import Button from '../../components/Button/Button';
import styles from './styles.jss';
import FormMessage from '../../components/FormMessage';
import {
  required as requiredValidator, icdCodes as icdCodesValidator,
  icdCodesDuplicate as icdCodesDuplicateValidator,
} from '../../helpers/validation/common.validators';

class ICDCode extends React.Component {
  constructor() {
    super();

    this.state = {
      error: '',
      data: [],
      icdCode: '',
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data.length !== 0) {
      this.setState({ data });
    }
  }

  componentDidUpdate({ data: previousData }) {
    const { getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();
    const { data } = this.state;
    onChange({ target: { name, value: data } });
  }

  removeHandler = (e) => {
    const eventValue = e.currentTarget.value;
    this.setState((state) => ({ data: state.data.filter(item => item !== eventValue) }));
  };

  submitHandler = () => {
    const { icdCode, data } = this.state;
    const { localePath } = this.props;
    if (icdCode) {
      if (!data.includes(icdCode)) {
        this.setState((state) => ({ data: [...state.data, state.icdCode] }));
        this.setState(() => ({ icdCode: '' }));
      } else {
        const validateDuplicates = icdCodesDuplicateValidator({
          localePath,
          message: 'validation-rules.duplicate-icd-code',
        });
        this.setState(() => ({ error: validateDuplicates() }));
      }
    }
  };

  handleChange = (e) => {
    const { localePath } = this.props;
    const validateInput = icdCodesValidator({
      localePath,
      message: 'validation-rules.invalid-icd-code',
    });

    const eventValue = e.currentTarget.value.toUpperCase();
    this.setState(() => ({
      error: validateInput(eventValue),
    }));
    this.setState(() => ({ icdCode: eventValue }));
  };


  render() {
    const {
      localePath, classes, getValidationProps,
    } = this.props;
    const { t } = this.context;
    const { error, icdCode, data } = this.state;

    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.container}`}>

          {data && Array.isArray(data) && data.map((icdCodeMapped) => (
            <div key={icdCodeMapped} className="esc_col esc_col-6 esc_col-s-3">
              <div className={classes.preview}>
                <div className={classes.fileWrap}>
                  <span>{icdCodeMapped}</span>
                </div>
                <button
                  type="button"
                  className={classes.closeButton}
                  onClick={this.removeHandler}
                  value={icdCodeMapped}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className={`esc_grid__wrapper ${classes.container}`}>
          <div className={`esc_col esc_col-10 esc_col-s-6 ${classes.containerInput}`}>
            <ErgoInputText
              {...getValidationProps()}
              description={t(`${localePath}.description`)}
              placeholder={t(`${localePath}.placeholder`)}
              onChange={this.handleChange}
              value={icdCode}
            />
            {error && (
              <div>
                <FormMessage error={`${error}`} />
              </div>
            )}
          </div>
          <div className="esc_col esc_col-2 esc_col-s-6">
            <Button
              className={` ${classes.addButton} ${classes.containerInput}`}
              title="+"
              onClick={this.submitHandler}
              disabled={error}
            />
          </div>
        </div>
      </div>
    );
  }
}

ICDCode.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.objectOf(Object),
};

ICDCode.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
  data: [],
  classes: {},
};

ICDCode.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({
  data: formData[ownProps.pathName][ownProps.name],
}
);

export { ICDCode as ICDCodePlain };
export default compose(
  connect(
    mapStateToProps,
  ),
  withSingleFieldValidation,
  injectSheet(styles),
)(ICDCode);
