import {
  glassWhatDamaged,
  glassBusinessWhatDamaged,
  whatDamagedBuilding,
  whatDamagedBuildingBusiness,
  whatDamagedHousehold,
  whatDamagedHouseholdBusiness,
  whichFloor,
  whatDamagedBuildingTheftBusiness,
  whatItemsStolen,
} from '../../../helpers/formData/propertyFormData';
import { getTranslationArray } from '../../../helpers/polyglot';

const getFormattedData = (formValue, labels) => {
  const values = formValue.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  return values.join(', ');
};

export const getGlassWhatDamagedValueProxy = formValue => getFormattedData(formValue, glassWhatDamaged);

export const getGlassBusinessWhatDamagedValueProxy = formValue => getFormattedData(formValue, glassBusinessWhatDamaged);

export const getWhatDamagedBuildingValueProxy = formValue => (
  getFormattedData(formValue, whatDamagedBuilding)
);

export const getWhatDamagedBuildingBusinessValueProxy = formValue => (
  getFormattedData(formValue, whatDamagedBuildingBusiness)
);

export const getWhatDamagedBuildingTheftBusinessValueProxy = formValue => (
  getFormattedData(formValue, whatDamagedBuildingTheftBusiness)
);

export const getWhichFloorValueProxy = formValue => (
  getFormattedData(formValue, whichFloor)
);

export const getWhatDamagedHouseholdValueProxy = formValue => (
  getFormattedData(formValue, whatDamagedHousehold)
);

export const getWhatItemsStolenValueProxy = formValue => (
  getFormattedData(formValue, whatItemsStolen)
);

export const getStolenItemsValueProxy = formValue => {
  const labels = getTranslationArray('property.bicycle-theft.stolen-accessoires-list.options');
  const { typesOfItemDamage, describeDamage } = formValue;
  const damagedParts = typesOfItemDamage.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  const descriptionParts = describeDamage !== ' ' ? `; ${describeDamage}` : '';
  return `${damagedParts.join(', ')}${descriptionParts}`;
};

export const getStolenPartsValueProxy = formValue => {
  const labels = getTranslationArray('property.bicycle-theft.which-parts-were-stolen.options');
  const { typesOfItemDamage, describeDamage } = formValue;
  const damagedParts = typesOfItemDamage.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  const descriptionParts = describeDamage !== ' ' ? `; ${describeDamage}` : '';
  return `${damagedParts.join(', ')}${descriptionParts}`;
};

export const getWhatDamagedHouseholdBusinessValueProxy = formValue => (
  getFormattedData(formValue, whatDamagedHouseholdBusiness)
);

export const getDamagedValueProxy = field => formValue => {
  const value = formValue[field];
  if (!formValue || !value || (value === '')) {
    return undefined;
  }
  return value;
};
