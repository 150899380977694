const styles = theme => ({
  container: {
    border: `1px solid ${theme.colors.themeLighterGray}`,
    borderRadius: '4px',
    display: 'flex',
    padding: '25px 23px 24px 26px',
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      padding: '2px 12px 15px',
    },
  },
  image: {
    height: '221px',
    width: '102px',
    marginRight: '17px',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      display: 'none',
    },
  },
  line: {
    height: '1px',
    backgroundColor: theme.colors.themeLighterGray,
    margin: '16px 0',
  },
});

export default styles;
