import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgPolizeiUndZeugen } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPoliceData, getWitnessesDetails } from '../../../helpers/formData/vehicleFormData';

const PoliceDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isCollisionFlow,
    isPolice,
    isAnyWitness,
    department,
    caseNumber,
    wasReported,
  } = getPoliceData(formData);
  let witnesses = [];
  if (!isPolice && !isAnyWitness) return false;
  if (isAnyWitness) {
    witnesses = getWitnessesDetails(formData);
  }

  return (
    <Details
      iconLeft={SvgPolizeiUndZeugen}
      summary={
        isLiabilityFlow || isCollisionFlow
          ? t(`${localePath}.police.liability-title`)
          : t(`${localePath}.police.title`)
      }
      className={className}
    >
      {isPolice && (
        <div style={{ marginBottom: '20px' }}>
          {department && (t(`${localePath}.police.department`, { department }))}
          {caseNumber && (t(`${localePath}.police.case-number`, { caseNumber }))}
          {wasReported && (t(`${localePath}.police.was-reported`))}
        </div>
      )}
      {isAnyWitness && witnesses.length > 0 && (
        <>
          <p>{t(`${localePath}.police.content-witness`)}</p>
          {witnesses.map(witness => (
            <p key={witness.firstName}>
              {t(`${localePath}.police.witness`, {
                firstName: witness.firstName,
                lastName: witness.lastName,
                phone: witness.phone ? t(`${localePath}.police.witness-phone`, { phone: witness.phone }) : '',
              })}
            </p>
          ))}
        </>
      )}
    </Details>
  );
};

PoliceDetails.propTypes = legalConsentProps.sectionProps;
PoliceDetails.defaultProps = legalConsentProps.sectionDefaults;

PoliceDetails.contextTypes = {
  t: PropTypes.func,
};

export default PoliceDetails;
