import {
  getDateValueProxy,
  getOneFromMultiProxy,
  getYesNoValueUnknown,
  getCountryCode,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  propertyOptions,
  propertyTenantLandlordOptions,
  propertyOwnerOptions,
} from '../../../helpers/formData/legalProtectionFormData';

export default {
  property_rent: [
    claimsKeys.legalProtection.CATEGORY,
    getOneFromMultiProxy({
      'tenant-or-landlord': propertyOptions.TENANT_OR_LANDLORD,
      owner: propertyOptions.OWNER,
    }),
  ],
  'property_first-home': [claimsKeys.legalProtection.PRIMARY_RESIDENCE, getYesNoValueUnknown],
  property_address: {
    country: [claimsKeys.legalProtection.COUNTRY, getCountryCode],
    address: claimsKeys.legalProtection.ADDRESS,
    postCode: claimsKeys.legalProtection.POST_CODE,
    city: claimsKeys.legalProtection.CITY,
  },
  // Tenant or Landlord
  'property_tenant-or-landlord_options': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'service-charges': propertyTenantLandlordOptions.SERVICE_CHARGES,
      reduction: propertyTenantLandlordOptions.REDUCTION,
      'rent-payment': propertyTenantLandlordOptions.RENT_PAYMENT,
      'rent-increase': propertyTenantLandlordOptions.RENT_INCREASE,
      damages: propertyTenantLandlordOptions.DAMAGES,
      'neighborhood-law': propertyTenantLandlordOptions.NEIGHBORHOOD_LAW,
      different: propertyTenantLandlordOptions.DIFFERENT,
    }),
  ],
  'property_tenant-or-landlord_service-charges': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_reduction': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_rent-payment': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_rent-increase': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_damages': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_neighborhood-law': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_tenant-or-landlord_different': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  // Owner
  property_owner_options: [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      association: propertyOwnerOptions.ASSOCIATION,
      'neighborhood-law': propertyOwnerOptions.NEIGHBORHOOD_LAW,
      sale: propertyOwnerOptions.SALE,
      different: propertyOwnerOptions.DIFFERENT,
    }),
  ],
  property_owner_association: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'property_owner_neighborhood-law': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  property_owner_sale: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  property_owner_different: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
};
