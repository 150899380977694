import {
  getYesNoValueUnknown,
  getYesNoValue,
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { trafficEventTypes } from '../../../helpers/formData/accidentFormData';

export default {
  'event-traffic_vehicle-type': [
    claimsKeys.accident.TYPE_DETAILS,
    getOneFromMultiProxy({
      car: trafficEventTypes.CAR,
      motorcycle: trafficEventTypes.MOTORBIKE,
      bike: trafficEventTypes.BIKE,
      pedestrian: trafficEventTypes.PEDESTRIAN,
      ebike: trafficEventTypes.E_BIKE,
      'other-electric': trafficEventTypes.OTHER_ELECTRIC,
      other: trafficEventTypes.OTHER,
    }),
  ],
  'on-the-way-event-traffic_vehicle-type': [
    claimsKeys.accident.TYPE_DETAILS,
    getOneFromMultiProxy({
      car: trafficEventTypes.CAR,
      motorcycle: trafficEventTypes.MOTORBIKE,
      bike: trafficEventTypes.BIKE,
      pedestrian: trafficEventTypes.PEDESTRIAN,
      ebike: trafficEventTypes.E_BIKE,
      'other-electric': trafficEventTypes.OTHER_ELECTRIC,
      other: trafficEventTypes.OTHER,
    }),
  ],
  'event-traffic_who-was-injured': [claimsKeys.accident.WHO_WAS_INJURED, getYesNoValue],
  'on-the-way-event-traffic_who-was-injured': [claimsKeys.accident.WHO_WAS_INJURED, getYesNoValue],
  'event-traffic-details_alcohol': [claimsKeys.accident.ALCOHOL_INTAKE, getYesNoValueUnknown],
  'event-traffic-details_is-reported': [claimsKeys.POLICE, getYesNoValueUnknown],
  'event-traffic-details_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'on-the-way-event-traffic-details_alcohol': [claimsKeys.accident.ALCOHOL_INTAKE, getYesNoValueUnknown],
  'on-the-way-event-traffic-details_is-reported': [claimsKeys.POLICE, getYesNoValueUnknown],
  'on-the-way-event-traffic-details_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
};
