import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { userTypes, getAdditionalDamageDetailsText } from '../../../helpers/formData/formData';
import {
  getDamageOfAccidentData,
  getCarMapDamages,
} from '../../../helpers/formData/vehicleFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isCollisionFlow,
    isPersonalInjury,
    isPrivateVehicle,
    isThirdPartyPrivateVehicle,
    isItemDamage,
    isOwnCar,
    personalInjuryDesc,
    itemDamageDesc,
    vehicleType,
    licenseNumber,
    describeDamage,
    isReady,
    airbagsDeployed,
    warningLights,
    fluidsLost,
  } = getDamageOfAccidentData(formData);

  if (!(isLiabilityFlow || (isCollisionFlow && !isOwnCar))) {
    return false;
  }

  const getAdditionalDamageDetails = (isReadyValue, airbagsDeployedValue, warningLightsValue, fluidsLostValue) => {
    let additionalDamageDetails = '';
    if (isReadyValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'is-ready-for-driving', isReadyValue);
    }
    if (airbagsDeployedValue) {
      additionalDamageDetails
        += getAdditionalDamageDetailsText(localePath, 'airbags-deployed', airbagsDeployedValue);
    }
    if (warningLightsValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'warning-lights', warningLightsValue);
    }
    if (fluidsLostValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'fluids-lost', fluidsLostValue);
    }
    return additionalDamageDetails;
  };

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.company-car`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.company-car`)
      : t(`${localePath}.incident.private-car`);
  };

  const getCarDamageMainText = () => (isLiabilityFlow ? `${localePath}.damage-of-accident.content-vehicle-liability` : `${localePath}.damage-of-accident.content-vehicle`);

  return (
    <Details
      iconLeft={SvgDerSchadenDesUnfallbeteiligten}
      summary={
        isLiabilityFlow
          ? t(`${localePath}.damage-of-accident.liability-title`)
          : t(`${localePath}.damage-of-accident.title`)
      }
      className={className}
    >
      {t(`${localePath}.damage-of-accident.content`, {
        contentDamage: isPersonalInjury || isItemDamage
          ? t(`${localePath}.damage-of-accident.content-damage`, {
            personDamage: isPersonalInjury
              ? t(`${localePath}.damage-of-accident.person-damage`) : '',
            itemDamage: isItemDamage
              ? t(`${localePath}.damage-of-accident.item-damage`) : '',
            collectDamage: isPersonalInjury && isItemDamage
              ? t(`${localePath}.damage-of-accident.collect-damage`) : '',
          }) : '',
        personalInjury: isPersonalInjury
          ? t(`${localePath}.damage-of-accident.personal-injury-desc`, { personalInjuryDesc }) : '',
        itemDamage: isItemDamage
          ? t(`${localePath}.damage-of-accident.item-damage-desc`, { itemDamageDesc }) : '',
        carDamage: vehicleType
          ? (`${t(getCarDamageMainText(), {
            licenseNumber,
            carDamages: getCarMapDamages(formData, userTypes.THIRD_PARTY),
            vehicleType,
            liabilityVehicleType: getPrivateCompanyCarText(),
          })}
          ${getAdditionalDamageDetails(isReady, airbagsDeployed, warningLights, fluidsLost)}`)
          : '',
        describeDamage: describeDamage
          ? t(`${localePath}.damage-of-accident.describe-damage`, { describeDamage }) : '',
      })}
    </Details>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
