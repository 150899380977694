import {
  NO,
  getPhoneValue,
  getYesNoValue,
  getTrackerAgreement,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getDateValueProxy,
  getCountryCode,
  getSpecialLicenseNumber,
  stripSpacesFromIban,
  getSalutationValue,
} from '../claimData.helpers';
import { getTheftCircumstancesValues } from './claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { theftDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'theft_what-was-stolen': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      car: theftDamages.CAR,
      parts: theftDamages.PARTS,
    }),
  ],
  // Car
  'theft_theft-day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'theft_theft-location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  // !Special case after prefilling data
  'theft_license-number': {
    part1: [claimsKeys.owner.LICENSE_NUMBER, getSpecialLicenseNumber],
    claimNumber: claimsKeys.POLICY_NUMBER,
  },
  'theft_contact-person': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
    agreement: [claimsKeys.owner.TRACKER_AGREEMENT, getTrackerAgreement],
  },
  'theft_police_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'theft_police_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  // Part
  'theft_part_theft-day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'theft_part_theft-location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  // ToDo: add mapping for this view. We don't have keys.
  'theft_part_theft-circumstances': [claimsKeys.owner.DESCRIBE_DAMAGE, getTheftCircumstancesValues],
  // !Special case after prefilling data
  'theft_part_license-number': {
    part1: [claimsKeys.owner.LICENSE_NUMBER, getSpecialLicenseNumber],
    claimNumber: claimsKeys.POLICY_NUMBER,
  },
  'theft_part_contact-person': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
    agreement: [claimsKeys.owner.TRACKER_AGREEMENT, getTrackerAgreement],
  },
  'theft_part_police_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'theft_part_police_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'theft_car-repair_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'theft_car-repair_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'theft_car-repair_repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'theft_car-repair_repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'theft_car-repair_have-you-invoices': ['RechnungKz', getYesNoValue],
  'theft_car-repair_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'theft_car-repair_want-to-repair': [
    claimsKeys.DAMAGE_REPAIR,
    getOneFromMultiProxy({
      yes: claimsKeys.REPAIR,
      no: claimsKeys.PAYOUT,
    }),
  ],
  'theft_car-repair_not-repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'theft_car-repair_not-repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'theft_car-repair_want-to-recommend-repair-shop': [claimsKeys.RECOMMEND_REPAIR_SHOP, getYesNoValue],
  'theft_car-repair_not-repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'theft_car-repair_is-vehicle-in-workshop': [claimsKeys.WORKSHOP, getYesNoValue],
  'theft_car-repair_postal-code': claimsKeys.WORKSHOP_POSTAL_CODE,
  'theft_car-repair_select-workshop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    address: claimsKeys.WORKSHOP_ADDRESS,
    phone: claimsKeys.WORKSHOP_PHONE,
    city: claimsKeys.WORKSHOP_CITY,
    postalcode: claimsKeys.WORKSHOP_POSTAL_CODE,
  },
};
