import saveClaimData from '../saveClaimService/saveClaimService';
import vehicleTransform from './vehicle';
import privateLiabilityTransform from './private-liability';
import brokerTransform from './broker';
import accidentTransform from './accident';
import legalProtectionTransform from './legal-protection';
import propertyTransform from './property';
import uploadTransform from './upload';
import collisionAbroadTransform from './collision-abroad';
import assetLiabilityTransform from './asset-liability';
import { claimsKeys } from '../../helpers/formData/formData';

export const moduleNames = {
  VEHICLE: 'vehicle',
  ASSET_LIABILITY: 'asset-liability',
  PRIVATE_LIABILITY: 'private-liability',
  BROKER: 'broker',
  LEGAL_PROTECTION: 'legal-protection',
  ACCIDENT: 'accident',
  PROPERTY: 'property',
  UPLOAD: 'upload',
  COLLISION_ABROAD: 'collision-abroad',
};


export const mapClaimData = (formData, moduleName = moduleNames.VEHICLE) => {
  if (!formData || !formData[moduleName]) {
    return null;
  }

  switch (moduleName) {
    case moduleNames.PRIVATE_LIABILITY:
      return privateLiabilityTransform(formData[moduleName]);
    case moduleNames.BROKER:
      return brokerTransform(formData[moduleName]);
    case moduleNames.ASSET_LIABILITY:
      return assetLiabilityTransform(formData[moduleName]);
    case moduleNames.ACCIDENT:
      return accidentTransform(formData[moduleName]);
    case moduleNames.LEGAL_PROTECTION:
      return legalProtectionTransform(formData[moduleName]);
    case moduleNames.PROPERTY:
      return propertyTransform(formData[moduleName]);
    case moduleNames.UPLOAD:
      return uploadTransform(formData[moduleName]);
    case moduleNames.COLLISION_ABROAD:
      return collisionAbroadTransform(formData[moduleName]);
    default:
      return vehicleTransform(formData[moduleName]);
  }
};

export const sendClaimData = async (formData, moduleName = moduleNames.VEHICLE) => {
  const claimData = mapClaimData(formData, moduleName);

  if (!claimData || Object.keys(claimData.data).length <= 3) {
    return null;
  }
  const {
    data, additionalData, summary, serviceType,
  } = claimData;

  const documents = data[claimsKeys.FILES];
  delete data[claimsKeys.FILES];

  const resource = saveClaimData(serviceType, data);
  return resource.create({
    claims: data, additionalData, summary, documents,
  }) === 200;
};

export default sendClaimData;
