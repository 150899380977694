import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgAuszahlung } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPayingOutData } from '../../../helpers/formData/vehicleFormData';

const PayingOutDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isWholeCarTheftFlow,
    recommendRepairShop,
    calcAppValue,
    kvaServiceValue,
    bankAccount,
    ibanName,
    iban,
    invoices,
    repairShopName,
    workshopAddress,
    workshopPhone,
    kvaWorkshopName,
    kvaWorkshopAddress,
    kvaWorkshopCity,
    kvaWorkshopPLZ,
  } = getPayingOutData(formData);

  const isUsingCalculationApp = calcAppValue === 'j';
  const isUsingKVAService = kvaServiceValue === 'j';
  const shouldShowInvoicesText = invoices === 'j';
  const shouldShowNoInvoicesText = invoices === 'n';

  return (
    <>
      {!isWholeCarTheftFlow
        && !recommendRepairShop
        && (bankAccount || (isLiabilityFlow && ibanName))
        && (
          <Details iconLeft={SvgAuszahlung} summary={t(`${localePath}.paying-out.title`)} className={className}>
            {isUsingCalculationApp && <p>{t(`${localePath}.paying-out.use-calculation-app`)}</p>}
            {isUsingKVAService && (
              t(`${localePath}.paying-out.use-kva-service`, {
                kvaWorkshopName,
                address: kvaWorkshopAddress ? t(`${localePath}.paying-out.address`, { kvaWorkshopAddress }) : '',
                city: kvaWorkshopCity ? t(`${localePath}.paying-out.city`, { kvaWorkshopPLZ, kvaWorkshopCity }) : '',
              })
            )}
            {(bankAccount || (isLiabilityFlow && ibanName)) && (
              t(`${localePath}.paying-out.content`, {
                account: iban
                  ? t(`${localePath}.paying-out.account-details`, {
                    ibanName,
                    iban,
                  }) : t(`${localePath}.paying-out.another-account`),
              })
            )}
            {shouldShowInvoicesText && (t(`${localePath}.paying-out.invoices`))}
            {shouldShowNoInvoicesText && (t(`${localePath}.paying-out.no-invoces`))}
            {repairShopName && (
              t(`${localePath}.paying-out.workshop`, {
                repairShopName,
                address: workshopAddress ? t(`${localePath}.paying-out.address`, { workshopAddress }) : '',
                phone: workshopPhone ? t(`${localePath}.paying-out.phone`, { workshopPhone }) : '',
              })
            )}
          </Details>
        )}
    </>
  );
};

PayingOutDetails.propTypes = legalConsentProps.sectionProps;
PayingOutDetails.defaultProps = legalConsentProps.sectionDefaults;

PayingOutDetails.contextTypes = {
  t: PropTypes.func,
};

export default PayingOutDetails;
