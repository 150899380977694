import { before } from 'lodash';

const styles = theme => ({
  container: {
    border: `1px solid ${theme.colors.themeLighterGray}`,
    display: 'flex',
    padding: '20px 25px',
    borderRadius: '4px',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      padding: '18px',
    },
  },
  iconContainer: {
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      display: 'none',
    },
  },
  icon: {
    width: '250px',
  },
  textContainer: {
    padding: '0 16px',
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      padding: 0,
    },
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '11px',
  },
  listHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '11px',
    paddingTop: '25px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '22px',
  },
  itemList: {
    fontSize: '16px',
    lineHeight: '22px',
    paddingBottom: '10px',
    marginLeft: '35px',
  },
});

export default styles;
