import {
  POLICYHOLDER,
  getYesNoValue,
  getUploadValues,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getUploadedFilesProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { userFlows } from '../../../helpers/formData/privateLiabilityFormData';
import privateLiabilityFlows from '../../../models/dataMappings/privateLiabilityFlows';

export default {
  'who-fills': [claimsKeys.WHO_FILLS, getYesNoValueProxy(POLICYHOLDER)],
  'what-happened': [
    claimsKeys.WHAT_HAPPENED,
    getOneFromMultiProxy({
      [privateLiabilityFlows.ANIMAL_DAMAGE]: userFlows.ANIMAL_DAMAGE,
      [privateLiabilityFlows.PERSON_DAMAGE]: userFlows.PERSON_DAMAGE,
      [privateLiabilityFlows.KEY_LOSS]: userFlows.KEY_LOSS,
      [privateLiabilityFlows.BUSINESS]: userFlows.BUSINESS,
    }),
  ],
  'your-message': claimsKeys.YOUR_MESSAGE,
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
