import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDokument } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import {
  getCarMapDamages,
  getVehicleDamageData,
} from '../../../helpers/formData/vehicleFormData';
import { getAdditionalDamageDetailsText } from '../../../helpers/formData/formData';

const VehicleDamageDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isTheftFlow,
    licenseNumber,
    damageDescription,
    isRepaired,
    isThirdPartyPrivateVehicle,
    isPrivateVehicle,
    isReady,
    airbagsDeployed,
    warningLights,
    fluidsLost,
  } = getVehicleDamageData(formData);
  const carDamages = getCarMapDamages(formData);

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.company-car`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.company-car`)
      : t(`${localePath}.incident.private-car`);
  };

  const getContent = () => (isLiabilityFlow
    ? `${localePath}.vehicle-damage.content-liability` : `${localePath}.vehicle-damage.content`);

  const getAdditionalDamageDetails = (isReadyValue, airbagsDeployedValue, warningLightsValue, fluidsLostValue) => {
    let additionalDamageDetails = '';
    if (isReadyValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'is-ready-for-driving', isReadyValue);
    }
    if (airbagsDeployedValue) {
      additionalDamageDetails
        += getAdditionalDamageDetailsText(localePath, 'airbags-deployed', airbagsDeployedValue);
    }
    if (warningLightsValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'warning-lights', warningLightsValue);
    }
    if (fluidsLostValue) {
      additionalDamageDetails += getAdditionalDamageDetailsText(localePath, 'fluids-lost', fluidsLostValue);
    }
    return additionalDamageDetails;
  };


  return (
    <>
      {!isLiabilityFlow && !isTheftFlow && (
        <Details
          iconLeft={SvgDokument}
          summary={
            isLiabilityFlow
              ? t(`${localePath}.vehicle-damage.liability-title`)
              : t(`${localePath}.vehicle-damage.title`)
          }
          className={className}
        >
          {t(getContent(), {
            licenseNumber,
            privateCompanyCar: getPrivateCompanyCarText(),
            carDamages,
          })}
          <br />
          {`${getAdditionalDamageDetails(isReady, airbagsDeployed, warningLights, fluidsLost)}`}
          <br />
          {damageDescription && (
            t(`${localePath}.vehicle-damage.describe-damage`, { damageDescription })
          )}
          {isRepaired && (
            t(`${localePath}.vehicle-damage.is-repaired`)
          )}
        </Details>
      )}
    </>
  );
};

VehicleDamageDetails.propTypes = legalConsentProps.sectionProps;
VehicleDamageDetails.defaultProps = legalConsentProps.sectionDefaults;

VehicleDamageDetails.contextTypes = {
  t: PropTypes.func,
};

export default VehicleDamageDetails;
