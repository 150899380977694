import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentDetails } from '../../../helpers/formData/propertyFormData';
import { SvgPflaster } from '../../../helpers/IconLoader';

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    date,
    dmgLocation,
    location,
    country,
    kindOfDamage,
    caseInfo,
    resultStructure,
    isGlassFlow,
    isBicycleTheftFlow,
    division,
    buildingOwner,
    originOfDamage,
    whichFloor,
    isBusinessFlow,
    wasLocked,
    whatWasStolen,
  } = getIncidentDetails(formData);

  let wasLockedValue;
  let whatWasStolenPath;
  if (wasLocked === 'n') {
    wasLockedValue = 'nicht ';
  } else {
    wasLockedValue = '';
  }

  if (whatWasStolen === 'Fahrrad') {
    whatWasStolenPath = 'what-was-stolen-bike';
  } else {
    whatWasStolenPath = 'what-was-stolen-accessoires';
  }


  return (
    <Details
      iconLeft={SvgPflaster}
      summary={t(`${localePath}.incident.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.incident.content-date`, { date })}
      </p>
      { isBicycleTheftFlow && (
        <p>
          {t(`${localePath}.incident.damage-location-bicycle`, { location, country })}
        </p>
      )}
      { dmgLocation && (
        <p>
          {t(`${localePath}.incident.content-damage-location`, {
            dmgLocation,
            place: location
              ? t(`${localePath}.incident.content-damage-place`, { location, country }) : '',
          })}
        </p>
      )}
      <p>
        {t(`${localePath}.incident.content-kind-of-damage`, { kindOfDamage })}
      </p>
      { isBicycleTheftFlow && (
        <p>
          {t(`${localePath}.incident.bike-was-locked`, { wasLockedValue })}
          {t(`${localePath}.incident.${whatWasStolenPath}`)}
        </p>
      )}
      { !isBicycleTheftFlow && (
      <p>
        {t(`${localePath}.incident.content-cause-of-damage`, {
          caseInfo,
          contentResultStructure: isGlassFlow && resultStructure
            ? t(`${localePath}.incident.content-result-structure`, { resultStructure }) : '',
        })}
      </p>
      )}
      { division && (
        <p>
          {t(`${localePath}.incident.content-division`, { division })}
        </p>
      )}
      { buildingOwner && (
        <p>
          {t(`${localePath}.incident.content-building-owner-${buildingOwner}`)}
        </p>
      )}
      { originOfDamage && (
        <p>
          {t(`${localePath}.incident.content-origin-of-damage`, { originOfDamage })}
        </p>
      )}
      {(whichFloor && isBusinessFlow) && (
      <p>
        {t(`${localePath}.incident.which-floor`, { whichFloor })}
      </p>
      )}
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
