import React from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import Checkbox from '@eg/elements/Checkbox';
import * as iconLoader from '../../helpers/IconLoader';
import styles from './styles.jss';

const ErgoMultipleSelectOption = ({
  classes, localePath, option, onClick, data,
}, { t }) => {
  const Icon = iconLoader[option.icon];
  return (
    <div
      className={`esc_col esc_col-12 esc_col-m-${option.gridDesktop} ${classes.container}`}
    >
      <Icon width="48px" height="48px" className={classes.icon} />
      {option.select && option.select.map(item => (
        <div key={item}>
          <Checkbox
            onClick={onClick(item)}
            label={t(`${localePath}.options.${item}`)}
            defaultChecked={data.includes(item)}
          />
        </div>
      ))}
    </div>
  );
};

ErgoMultipleSelectOption.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  option: PropTypes.shape({
    icon: PropTypes.string,
    key: PropTypes.string,
    select: PropTypes.array,
    gridDesktop: PropTypes.number,
  }),
  data: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

ErgoMultipleSelectOption.defaultProps = {
  classes: {},
  localePath: '',
  option: {},
  data: [],
  onClick: () => { },
};

ErgoMultipleSelectOption.contextTypes = {
  t: PropTypes.func,
};

export { ErgoMultipleSelectOption as ErgoMultipleSelectOptionPlain };
export default injectJss(styles)(ErgoMultipleSelectOption);
