import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import InputText from '../../components/InputText/InputText';

import styles from './styles.jss';

const PersonName = (props, { t }) => {
  const {
    classes,
    localePath,
    getValidationProps,
  } = props;

  return (
    <div className={classes.personNameInputBox}>
      <InputText
        type="text"
        label={t(`${localePath}.labels.first-name`)}
        className={classes.nameInputLeft}
        {...getValidationProps('firstName')}
      />
      <InputText
        type="text"
        label={t(`${localePath}.labels.last-name`)}
        className={classes.nameInputRight}
        {...getValidationProps('lastName')}
      />
    </div>
  );
};

PersonName.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
};

PersonName.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => {},
};

PersonName.contextTypes = {
  t: PropTypes.func,
};

export { PersonName as PersonNamePlain };
export default compose(
  injectSheet(styles),
  withMultiFieldValidation,
)(PersonName);
