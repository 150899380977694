import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentDetails, getYesNo } from '../../../helpers/formData/assetLiabilityFormData';
import { SvgPflaster } from '../../../helpers/IconLoader';

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    date,
    typeOfAccusation,
    opinionAvailable,
    whoDamaged,
  } = getIncidentDetails(formData);
  const opinionMapped = getYesNo(opinionAvailable);

  return (
    <Details
      iconLeft={SvgPflaster}
      summary={t(`${localePath}.incident.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.incident.content-date`, { date })}
      </p>
      <p>
        {t(`${localePath}.incident.content-incident`, { typeOfAccusation })}
      </p>
      {whoDamaged && (
        <p>
          {t(`${localePath}.incident.content-who`, { whoDamaged })}
        </p>
      )}
      {opinionAvailable && (
      <p>
        {t(`${localePath}.incident.content-opinion-available`, { opinionMapped })}
      </p>
      )}
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
