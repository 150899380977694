import { mediaQuery } from '../../styles/helpers'; // eslint-disable-line import/prefer-default-export
import { breakpoints } from '../../styles/constants';

const styles = (theme) => ({
  personNameInputBox: {
    display: 'flex',
    justifyContent: 'center',
    ...mediaQuery.min(breakpoints.desktopNav, {
      margin: '0 auto',
    }),
    '& input': {
      ...mediaQuery.max(breakpoints.desktopNav, {
        padding: '6px 0',
      }),
    },
  },
  nameInputLeft: {
    marginRight: theme.elementLargeMargin,
  },
});

export default styles;
