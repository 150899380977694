import PropTypes from 'prop-types';
import {
  SvgBrilleDamage,
  SvgKleidungDamage,
  SvgDamageUnknown,
  SvgSmartphone,
  SvgLaptop,
  SvgMoebelDamage,
  SvgTuerWandBodenDamage,
} from '../../helpers/IconLoader';

export const icons = {
  glasses: SvgBrilleDamage,
  clothing: SvgKleidungDamage,
  different: SvgDamageUnknown,
  smartphone: SvgSmartphone,
  laptop: SvgLaptop,
  furniture: SvgMoebelDamage,
  window: SvgTuerWandBodenDamage,
};

const optionTypes = {
  DIFFERENT: 'different',
};

const dataType = {
  TYPES_OF_ITEM_DAMAGE: 'typesOfItemDamage',
  DESCRIPTION: 'describeDamage',
};

const CircumstanceResource = PropTypes.shape({
  typesOfItemDamage: PropTypes.arrayOf(PropTypes.string),
  describeDamage: PropTypes.string,
});

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  firstCol: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
  })),
  middleCol: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
  })),
  lastCol: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
  })),
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  name: PropTypes.string,
  data: CircumstanceResource,
  onChange: PropTypes.func,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

const defaults = {
  classes: {},
  firstCol: [],
  middleCol: [],
  lastCol: [],
  localePath: '',
  pathName: '',
  name: '',
  data: {
    typesOfItemDamage: [],
    describeCircumstance: ' ',
  },
  onChange: () => {},
  updateField: () => {},
  getValidationProps: () => {},
};

export default {
  icons,
  optionTypes,
  dataType,
  props,
  defaults,
  CircumstanceResource,
};
