import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { types } from '../../actions/constants';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import Toggle from '../../components/Toggle';
import { getSign } from '../../helpers/sections';


class MultiSelect extends React.PureComponent {
  componentDidUpdate({ data: previousData }) {
    const { data: currentData, getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();

    if (!isEqual(previousData, currentData)) {
      const target = {
        name,
        value: currentData,
      };
      onChange({ target });
    }
  }

  handleClick = value => e => {
    const {
      updateField,
      pathName,
      getValidationProps,
    } = this.props;
    const { name } = getValidationProps();
    updateField(name, value, pathName);
  }

  render() {
    const {
      options,
      localePath,
      data,
      yesNoQuestion,
    } = this.props;
    const { t } = this.context;

    return (
      <>
        {options.map(({ key }, index) => (
          <Toggle
            key={key}
            index={index}
            option={key}
            onClick={this.handleClick(key)}
            selected={data.includes(key)}
            sign={getSign(index, yesNoQuestion, t)}
            toggleText={t(`${localePath}.options.${key}`)}
          />
        ))}
      </>
    );
  }
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  localePath: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  pathName: PropTypes.string,
  yesNoQuestion: PropTypes.bool,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

MultiSelect.defaultProps = {
  options: [],
  localePath: '',
  data: [],
  pathName: '',
  yesNoQuestion: false,
  updateField: () => {},
  getValidationProps: () => {},
};

MultiSelect.contextTypes = {
  t: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE_MULTI,
    fieldName: name,
    pathName,
    payload,
  }),
});
export { MultiSelect as MultiSelectPlain };
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withSingleFieldValidation,
)(MultiSelect);
