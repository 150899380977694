import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'recompose';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Radio from '@eg/elements/Radio';
import moment from 'moment';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import { INVALID_DATE } from '../../services/claimData/claimData.helpers';
import { types } from '../../actions/constants';
import DatePickerInput from '../DatePickerInput';
import styles from './styles.jss';

const DATE_FORMAT = 'DD.MM.YYYY';

const formatStringToDate = newDate => moment(newDate, DATE_FORMAT).toDate();

const formatDateToString = newDate => moment(newDate).format(DATE_FORMAT);

const DateFirstAid = ({
  classes, localePath, pathName, getValidationProps,
}, { t }) => {
  const options = {
    FIRST: '1',
    SECOND: '0',
  };
  const {
    name, value, onChange,
  } = getValidationProps();

  const dispatch = useDispatch();
  const { hospitalization, accidentDate } = useSelector(state => {
    const { formData } = state;
    const { date } = formData.accident['event-circumstances_day'];
    return {
      hospitalization: formData.accident.hospital_hospitalization,
      accidentDate: formatStringToDate(date),
    };
  }, shallowEqual);

  const [isHospitalization, setIsHospitalization] = useState(hospitalization === 'yes');
  const [selectedValue, setSelectedValue] = useState(value === INVALID_DATE ? options.SECOND : options.FIRST);
  const [isDisable, setIsDisable] = useState(true);
  const [date, setDate] = useState(value && value !== INVALID_DATE ? formatStringToDate(value) : null);
  const [errorMessage, setErrorMessage] = useState('');

  const setChanges = (payload) => {
    onChange(name)(payload);
    dispatch({
      type: types.FIELD_UPDATE,
      fieldName: name,
      payload,
      pathName,
    });
  };

  const onClick = event => setSelectedValue(event.target.value);

  const onChangeOptional = val => {
    setSelectedValue(options.FIRST);
    setDate(val);
    setChanges(formatDateToString(val));
  };

  useEffect(() => {
    isHospitalization ? setSelectedValue(options.FIRST) : setIsDisable(false);
  }, [isHospitalization]);

  useEffect(() => {
    if (selectedValue === options.SECOND) {
      setChanges(INVALID_DATE);
    }
    if (selectedValue === options.FIRST) {
      date ? setChanges(formatDateToString(date)) : setChanges('');
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!date) return;
    if (moment(date).isBefore(accidentDate, 'day') && selectedValue === options.FIRST) {
      setChanges('');
      setErrorMessage('validation-rules.invalid-date-before-accident');
      return;
    }
    setErrorMessage('');
  }, [date, selectedValue]);

  return (
    <div className={`esc_grid ${classes.container}`}>
      <div className="esc_grid__wrapper">
        <div className={`esc_col esc_col-12 esc_col-s-6 ${classes.dateContainer}`}>
          <Radio
            className={classes.item}
            checked={selectedValue === options.FIRST}
            label=""
            value={1}
            onClick={onClick}
          />
          <div className={classes.datePickerContainer}>
            <DatePickerInput
              placeholder={t(`${localePath}.labels.dateLabel`)}
              description={t(`${localePath}.labels.dateDescription`)}
              type="text"
              name={name}
              value={value}
              onChange={onChange}
              onChangeOptional={onChangeOptional}
              {...(errorMessage !== '' && { error: errorMessage })}
            />
          </div>
        </div>
        <div className="esc_col esc_col-12 esc_col-s-6">
          <Radio
            className={classes.item}
            checked={selectedValue === options.SECOND}
            label={t(`${localePath}.labels.textLabel`)}
            value={0}
            disabled={isDisable}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

DateFirstAid.propTypes = {
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  getValidationProps: PropTypes.func,
  classes: PropTypes.objectOf(Object),
  hospitalization: PropTypes.func,
};

DateFirstAid.defaultProps = {
  localePath: '',
  pathName: '',
  classes: {},
  getValidationProps: () => { },
  hospitalization: () => {},
};

DateFirstAid.contextTypes = {
  t: PropTypes.func,
};


export { DateFirstAid as DateFirstAidPlain };
export default compose(
  injectSheet(styles),
  withSingleFieldValidation,
)(DateFirstAid);
