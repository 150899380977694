import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import claimCommon from './claimCommon';
import {
  getAdditionalData, getPageType, mapClaimNumber, getWhatHappened,
} from '../../../helpers/formData/uploadFormData';

const propertyTransform = formData => {
  const claimData = {
    ...claimCommon,
  };
  const data = claimDataTransform(claimData, formData);
  const dataWithClaimNumber = mapClaimNumber(data, formData['claim-number']);
  const additionalData = getAdditionalData(dataWithClaimNumber);
  return {
    data: { ...dataWithClaimNumber, ...getPageType(), ...getWhatHappened() },
    serviceType: moduleNames.UPLOAD,
    ...additionalData,
  };
};

export default propertyTransform;
