import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import privateLiabilityFlows from '../../../models/dataMappings/privateLiabilityFlows';
import { isLiabilityFlow } from '../../../helpers/formData/formData';
import { getContactOfAccidentData } from '../../../helpers/formData/privateLiabilityFormData';

import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ContactOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const data = getContactOfAccidentData(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      summary={
        isLiabilityFlow(formData)
          ? t(`${localePath}.contact-of-accident.title-${privateLiabilityFlows.LIABILITY}`)
          : t(`${localePath}.contact-of-accident.title`)
      }
      className={className}
    >
      <p>
        {t(`${localePath}.contact-of-accident.content-name`, {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-phone`, {
          phone: data.phone,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-address`, {
          address: data.address,
          postCode: data.postCode,
          city: data.city,
        })}
      </p>
    </Details>
  );
};

ContactOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
ContactOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactOfAccidentDetails;
