import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './Card.styles.jss';
import Checklist from './Checklist';
import CardBar from './CardBar';
import calcAppStandardImage from '../../assets/images/calc_app_standard.png';

const Card = ({
  classes, localePath, columns, onOpenDialog,
}, { t }) => (
  <div className={classes.container}>
    <img src={calcAppStandardImage} alt="calculation mobile app" className={classes.image} />
    <div>
      <Checklist columns={columns} headingText={t(`${localePath}.card.checklist.heading`)} />
      <div className={classes.line} />
      <CardBar
        localePath={localePath}
        classes={classes}
        onOpenDialog={onOpenDialog}
      />
    </div>
  </div>
);

Card.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  onOpenDialog: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.array),
};

Card.defaultProps = {
  classes: {},
  localePath: '',
  onOpenDialog: () => {},
  columns: [],
};

Card.contextTypes = {
  t: PropTypes.func,
};

export { Card as CardPlain };
export default injectSheet(styles)(Card);
