import claimFire from './claimFire';
import claimTheft from './claimTheft';
import claimGlass from './claimGlass';
import claimAnimal from './claimAnimal';
import claimWeather from './claimWeather';
import claimVandalism from './claimVandalism';
import claimCommon from './claimCommon';
import claimLiability from './claimLiability';
import claimCollision from './claimCollision';

import vehicleFlows from '../../../models/dataMappings/vehicleFlows';
import {
  getWhatHappened,
  getPageType,
  getBrokerAdditionalData,
  getCategoryForCollision,
} from '../../../helpers/formData/vehicleFormData';
import { claimDataTransform, PARTY } from '../claimData.helpers';
import { moduleNames } from '../claimData';

const assignClaimData = (whatHappened, whoFills) => {
  if (whoFills === PARTY) {
    return claimLiability;
  }

  switch (whatHappened) {
    case vehicleFlows.ANIMAL:
      return claimAnimal;
    case vehicleFlows.WEATHER:
      return claimWeather;
    case vehicleFlows.FIRE:
      return claimFire;
    case vehicleFlows.GLASS:
      return claimGlass;
    case vehicleFlows.VANDALISM:
      return claimVandalism;
    case vehicleFlows.THEFT:
      return claimTheft;
    case vehicleFlows.COLLISION:
      return claimCollision;
    default:
      return {};
  }
};

const vehicleTransform = formData => {
  let claimData = assignClaimData(formData['what-happened'], formData['who-fills']);
  claimData = {
    ...claimCommon,
    ...claimData,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getBrokerAdditionalData(data);

  return {
    data: {
      ...data,
      ...getWhatHappened(data),
      ...getPageType(data),
      ...getCategoryForCollision(data),
    },
    serviceType: moduleNames.BROKER,
    ...additionalData,
  };
};

export default vehicleTransform;
