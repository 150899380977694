import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { claimsKeys, getCountryName, getDate } from '../../../helpers/formData/formData';
import { getFlowSubName } from '../../../helpers/formData/privateLiabilityFormData';

import {
  SvgKey,
  SvgHundHundehalter,
  SvgVorfallLiability,
} from '../../../helpers/IconLoader';

const icons = {
  'key-loss': SvgKey,
  'person-damage': SvgVorfallLiability,
  'animal-damage': SvgHundHundehalter,
  liability: SvgVorfallLiability,
  business: SvgVorfallLiability,
};

const getIconForIncident = flowSubName => icons[flowSubName];

const IncidentDetails = ({ formData, localePath, className }, { t }) => (
  <Details
    iconLeft={getIconForIncident(getFlowSubName(formData))}
    summary={t(`${localePath}.incident.title`)}
    className={className}
  >
    {t(`${localePath}.incident.content-${getFlowSubName(formData)}`, {
      date: getDate(formData[claimsKeys.DATE]),
      circumstances: formData[claimsKeys.CIRCUMSTANCES],
      location: formData[claimsKeys.LOCATION],
      countryName: getCountryName(formData[claimsKeys.COUNTRY_CODE]),
      guilt: formData[claimsKeys.CAUSER_OF_DAMAGE] || formData[claimsKeys.FURTHER_INFORMATION],
    })}
  </Details>
);

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
