import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgReparatur } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getRepairData } from '../../../helpers/formData/vehicleFormData';

const RepairDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isGlassFlow,
    recommendRepairShop,
    name,
    address,
    city,
    phone,
  } = getRepairData(formData);

  if (isGlassFlow || !recommendRepairShop) return false;

  return (
    <Details iconLeft={SvgReparatur} summary={t(`${localePath}.repair.title`)} className={className}>
      {t(`${localePath}.repair.content`, {
        workshop: t(`${localePath}.repair.workshop-${recommendRepairShop}`, {
          name,
          address: address ? t(`${localePath}.repair.address`, { address }) : '',
          city: city ? t(`${localePath}.repair.city`, { city }) : '',
          phone: phone ? t(`${localePath}.repair.phone`, { phone }) : '',
        }),
      })}
    </Details>
  );
};

RepairDetails.propTypes = legalConsentProps.sectionProps;
RepairDetails.defaultProps = legalConsentProps.sectionDefaults;

RepairDetails.contextTypes = {
  t: PropTypes.func,
};

export default RepairDetails;
