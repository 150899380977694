import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { otherPrivateDamageCause } from '../../../helpers/formData/propertyFormData';

export default {
  'other-private_damage_cause': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'transportation-damage': otherPrivateDamageCause.TRANSPORTATION_DAMAGE,
      'unnamed-dangers': otherPrivateDamageCause.UNNAMED_DANGERS,
      different: otherPrivateDamageCause.DIFFERENT,
    }),
  ],
};
