export const convertToString = (dateObj) => {
  if (dateObj) {
    const mnth = (`0${dateObj.getMonth() + 1}`).slice(-2);
    const day = (`0${dateObj.getDate()}`).slice(-2);
    return [day, mnth, dateObj.getFullYear()].join('.');
  }
  return '';
};

// flips DD.MM.YYYY to MM.DD.YYYY
export const convertBack = date => {
  const arr = date.split('.');
  return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`);
};
