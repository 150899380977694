import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PostalCode from '../../PostalCode';
import { updatePostalCode as updatePostalCodeAction } from '../../../actions/workshops';

const RepairShopPostalCode = (props) => {
  const updateOnBlur = () => {
    const {
      values,
      name,
      updatePostalCode,
    } = props;
    if (values && values[name]) {
      updatePostalCode(values[name]);
    }
  };

  return (
    <div onBlur={updateOnBlur}>
      <PostalCode {...props} />
    </div>
  );
};

RepairShopPostalCode.propTypes = {
  updatePostalCode: PropTypes.func,
};

RepairShopPostalCode.defaultProps = {
  updatePostalCode: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  updatePostalCode: postalCode => dispatch(updatePostalCodeAction(postalCode)),
});

export { RepairShopPostalCode as RepairShopPostalCodePlain };
export default connect(null, mapDispatchToProps)(RepairShopPostalCode);
