import {
  NO,
  getEmailValue,
  getPhoneValue,
  getYesNoValue,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getDateValueProxy,
  arrayIncludesKeyAndValueProxy,
  getCountryCode,
  getLicenseNumber,
  stripSpacesFromIban,
  getSalutationValue,
} from '../claimData.helpers';
import { claimsKeys, getCarMapKey } from '../../../helpers/formData/formData';
import { fireDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'fire-or-explosion_type': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      type1: fireDamages.FIRE,
      type2: fireDamages.EXPLOSION,
      type3: fireDamages.SHORT_CIRCUIT,
    }),
  ],
  'fire-or-explosion_event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'fire-or-explosion_event-circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'fire-or-explosion_event-circumstances_car-map': {
    describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
    'front-left': [getCarMapKey('front-left'), arrayIncludesKeyAndValueProxy('carMap', 'front-left')],
    'front-middle': [getCarMapKey('front-middle'), arrayIncludesKeyAndValueProxy('carMap', 'front-middle')],
    'front-right': [getCarMapKey('front-right'), arrayIncludesKeyAndValueProxy('carMap', 'front-right')],
    'middle-left': [getCarMapKey('middle-left'), arrayIncludesKeyAndValueProxy('carMap', 'middle-left')],
    'middle-part': [getCarMapKey('middle-part'), arrayIncludesKeyAndValueProxy('carMap', 'middle-part')],
    'middle-right': [getCarMapKey('middle-right'), arrayIncludesKeyAndValueProxy('carMap', 'middle-right')],
    'back-left': [getCarMapKey('back-left'), arrayIncludesKeyAndValueProxy('carMap', 'back-left')],
    'back-middle': [getCarMapKey('back-middle'), arrayIncludesKeyAndValueProxy('carMap', 'back-middle')],
    'back-right': [getCarMapKey('back-right'), arrayIncludesKeyAndValueProxy('carMap', 'back-right')],
  },
  'fire-or-explosion_vehicle-id-ergo_license-number': [claimsKeys.owner.LICENSE_NUMBER, getLicenseNumber],
  'fire-or-explosion_contact-data': {
    who: [claimsKeys.customer.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.customer.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.customer.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.customer.PHONE, getPhoneValue],
    email: claimsKeys.customer.EMAIL,
  },
  'fire-or-explosion_contact-broker': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    yourReference: claimsKeys.broker.REFERENCE,
  },
  'fire-or-explosion_police-reported_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'fire-or-explosion_police-no-reported_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'fire-or-explosion_police-reported_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  // ToDo: Do we need this?
  'fire-or-explosion_police-no-reported_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'fire-or-explosion_repair-comprehensive_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'fire-or-explosion_repair-comprehensive_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'fire-or-explosion_repair-comprehensive_repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'fire-or-explosion_repair-comprehensive_repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'fire-or-explosion_repair-comprehensive_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'fire-or-explosion_repair-comprehensive_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'fire-or-explosion_repair-comprehensive_want-to-repair': [
    claimsKeys.DAMAGE_REPAIR,
    getOneFromMultiProxy({
      yes: claimsKeys.REPAIR,
      no: claimsKeys.PAYOUT,
    }),
  ],
  'fire-or-explosion_repair-comprehensive_not-repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'fire-or-explosion_repair-comprehensive_not-repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'fire-or-explosion_repair-comprehensive_want-to-recommend-repair-shop': [
    claimsKeys.RECOMMEND_REPAIR_SHOP,
    getYesNoValue,
  ],
  'fire-or-explosion_repair-comprehensive_not-repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'fire-or-explosion_repair-comprehensive_is-vehicle-in-workshop': [claimsKeys.WORKSHOP, getYesNoValue],
  'fire-or-explosion_repair-comprehensive_postal-code': claimsKeys.WORKSHOP_POSTAL_CODE,
  'fire-or-explosion_repair-comprehensive_select-workshop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    address: claimsKeys.WORKSHOP_ADDRESS,
    phone: claimsKeys.WORKSHOP_PHONE,
    city: claimsKeys.WORKSHOP_CITY,
    postalcode: claimsKeys.WORKSHOP_POSTAL_CODE,
  },
};
