import { maskTypes, formatCharactersToMask } from '../helpers/masks';

export const formTypes = Object.freeze({
  CONTACT_PERSON: 'contact-person',
  CONTACT_NON_PERSONAL: 'contact-non-personal',
  CONTACT_REPAIR_SHOP: 'contact-repair-shop',
  CONTACT_NOT_REPAIR_SHOP: 'contact-not-repair-shop',
  IBAN: 'iban',
  WITNESS: 'witness',
  LOCATION: 'location',
  HOSPITAL: 'hospital',
  DOUBLE_POLICY_NUMBER: 'double-policy-number',
  DAMAGE_VALUE: 'item-value-field',
  LICENSE_NUMBER: 'collision-abroad-license-number',
});

export const assignFormType = (formType) => {
  switch (formType) {
    case formTypes.CONTACT_PERSON:
      return {
        firstName: { size: 6 },
        lastName: { size: 6 },
        phoneNumber: {
          size: 6,
          mask: maskTypes.PHONE_NUMBER,
          formatCharacters: formatCharactersToMask.PHONE_NUMBER,
        },
        email: { size: 6 },
      };
    case formTypes.CONTACT_REPAIR_SHOP:
    case formTypes.CONTACT_NOT_REPAIR_SHOP:
      return {
        name: { size: 12 },
        phoneNumber: {
          size: 6,
          mask: maskTypes.PHONE_NUMBER,
          formatCharacters: formatCharactersToMask.PHONE_NUMBER,
        },
        city: { size: 6 },
      };
    case formTypes.IBAN:
      return {
        name: { size: 6 },
        iban: { size: 6 },
      };
    case formTypes.LOCATION:
      return {
        address: { size: 12 },
        postCode: { size: 3, mask: maskTypes.POST_CODE },
        city: { size: 9 },
        country: { size: 5, autocomplete: true },
      };
    case formTypes.HOSPITAL:
      return {
        hospitalName: { size: 6 },
        address: { size: 12 },
        postCode: { size: 3, mask: maskTypes.POST_CODE },
        city: { size: 9 },
        country: { size: 5, autocomplete: true },
      };
    case formTypes.DOUBLE_POLICY_NUMBER:
      return {
        policyNumber: { size: 6, onlyInRow: true, uppercase: true },
        policyNumberSecond: { size: 6, onlyInRow: true, uppercase: true },
      };
    case formTypes.DAMAGE_VALUE:
      return {
        first: { size: 12, onlyInRow: true },
        second: { size: 12, onlyInRow: true },
        third: { size: 12, onlyInRow: true },
        fourth: { size: 12, onlyInRow: true },
        fifth: { size: 12, onlyInRow: true },
      };
    case formTypes.LICENSE_NUMBER:
      return {
        country: { size: 5, autocomplete: true },
        licenseNumber: { size: 5 },
      };
    default:
      return {
        firstName: { size: 6 },
        lastName: { size: 6 },
        phoneNumber: {
          size: 6,
          mask: maskTypes.PHONE_NUMBER,
          formatCharacters: formatCharactersToMask.PHONE_NUMBER,
        },
      };
  }
};
