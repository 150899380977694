import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './Card.styles.jss';
import HandIcon from '../../assets/icons/Hand6';

const Card = ({ classes, localePath }, { t }) => (
  <div className={classes.container}>

    <div className={classes.iconContainer}>
      <HandIcon className={classes.icon} />
    </div>

    <div className={classes.textContainer}>
      <p className={classes.heading}>{t(`${localePath}.card.heading`)}</p>
      <p className={classes.description}>{t(`${localePath}.card.description`)}</p>
    </div>

  </div>
);
Card.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
};

Card.defaultProps = {
  classes: {},
  localePath: '',
};

Card.contextTypes = {
  t: PropTypes.func,
};

export { Card as CardPlain };

export default injectSheet(styles)(Card);
