const styles = () => ({
  wrapper: {
    '& > div': {
      padding: 10,
      position: 'relative',
    },
  },
  error: {
    paddingTop: 10,
  },
});

export default styles;
