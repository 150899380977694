import {
  getYesNoValue,
  getOneFromMultiProxy,
  stripSpacesFromIban,
  getUploadValues,
  getUploadedFilesProxy,
  getDateValueProxy,
  getCountryCode,
  getSalutationValue,
  getFirstNameValue,
  getLastNameValue,
  getPhoneValue,
  padPolicyNumber,
  podPolicyNumberValueProxy,
} from '../claimData.helpers';
import {
  claimsKeys,
  YES_VALUE,
  NO_VALUE,
  PARTLY_VALUE,
  YES_LIMITED,
} from '../../../helpers/formData/formData';
import {
  userFlows,
  insideOutside,
  insideOutsideBusiness,
  damageLocation,
  haveYouDamagedProperty,
} from '../../../helpers/formData/propertyFormData';
import {
  getWhatDamagedHouseholdValueProxy,
  getWhatDamagedBuildingValueProxy,
  getWhatDamagedBuildingBusinessValueProxy,
  getDamagedValueProxy,
  getWhichFloorValueProxy,
  getWhatDamagedHouseholdBusinessValueProxy,
} from './claimData.helpers';

const mappingInsideOutside = [
  claimsKeys.property.DIVISION,
  getOneFromMultiProxy({
    building: insideOutside.BUILDING,
    household: insideOutside.HOUSEHOLD,
    both: insideOutside.BOTH,
  }),
];

const mappingInsideOutsideBusiness = [
  claimsKeys.property.DIVISION,
  getOneFromMultiProxy({
    building: insideOutsideBusiness.BUILDING,
    household: insideOutsideBusiness.HOUSEHOLD,
    both: insideOutsideBusiness.BOTH,
  }),
];

const mappingAffectedOwner = [
  claimsKeys.property.BUILDING_OWNER,
  getYesNoValue,
];

const mappingAffectedOrCircumstancesDay = {
  date: [claimsKeys.DATE, getDateValueProxy],
};

const mappingEventCircumstancesLocation = [
  claimsKeys.property.DAMAGE_LOCATION,
  getOneFromMultiProxy({
    'insured-risk-location': damageLocation.INSURED_RISK_LOCATION,
    'another-place': damageLocation.ANOTHER_PLACE,
  }),
];

const mappingEventCircumstancesLocationActive = {
  country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  location: claimsKeys.LOCATION,
};

const mappingCircumstancesDescription = claimsKeys.CIRCUMSTANCES;

const mappingWhatDamagedBuilding = [
  claimsKeys.property.CAUSE_NOTE,
  getWhatDamagedBuildingValueProxy,
];

const mappingWhatDamagedBuildingBusiness = [
  claimsKeys.property.CAUSE_NOTE,
  getWhatDamagedBuildingBusinessValueProxy,
];

const mappingWhatDamagedHousehold = [
  claimsKeys.property.OBJECT_DESCRIPTION,
  getWhatDamagedHouseholdValueProxy,
];

const mappingWhatDamagedHouseholdBusiness = [
  claimsKeys.property.OBJECT_DESCRIPTION,
  getWhatDamagedHouseholdBusinessValueProxy,
];

const mappingHaveYouDamagedInformation = [
  claimsKeys.property.VALUE_INDICATION,
  getYesNoValue,
];

const mappingHaveYouDamagedValues = {
  first: [claimsKeys.property.damageValue.VALUE_1, getDamagedValueProxy('first')],
  second: [claimsKeys.property.damageValue.VALUE_2, getDamagedValueProxy('second')],
  third: [claimsKeys.property.damageValue.VALUE_3, getDamagedValueProxy('third')],
  fourth: [claimsKeys.property.damageValue.VALUE_4, getDamagedValueProxy('fourth')],
  fifth: [claimsKeys.property.damageValue.VALUE_5, getDamagedValueProxy('fifth')],
};

const mappingHaveYouDamagedIsRepaired = [
  claimsKeys.IS_REPAIRED,
  getOneFromMultiProxy({
    yes: YES_VALUE,
    no: NO_VALUE,
    unknown: PARTLY_VALUE,
  }),
];

const mappingOperations = [
  claimsKeys.property.OPERATIONS,
  getOneFromMultiProxy({
    yes: YES_VALUE,
    'yes-limited': YES_LIMITED,
    no: NO_VALUE,
  }),
];

const mappingHaveYouDamagedProperty = [
  claimsKeys.property.EXECUTION_OF_REPAIRS,
  getOneFromMultiProxy({
    'own-contribution': haveYouDamagedProperty.OWN_CONTRUBUTION,
    partner: haveYouDamagedProperty.PARTNER,
    different: haveYouDamagedProperty.OTHER,
  }),
];

const mappingHaveYouDamageNoArtisanWorkProperty = [
  claimsKeys.property.EXECUTION_OF_REPAIRS,
  getOneFromMultiProxy({
    'own-contribution': haveYouDamagedProperty.OWN_CONTRUBUTION,
    different: haveYouDamagedProperty.OTHER,
  }),
];

const mappingHaveYouDamagedContact = {
  who: [claimsKeys.owner.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
  phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
  email: claimsKeys.owner.EMAIL,
};

const mappingIdPolicyNumber = [
  claimsKeys.POLICY_NUMBER,
  padPolicyNumber,
];

const mappingWhichFloor = [
  claimsKeys.property.WHICH_FLOOR,
  getWhichFloorValueProxy,
];

const mappingIdsPolicyNumber = {
  policyNumber: [claimsKeys.POLICY_NUMBER, podPolicyNumberValueProxy('policyNumber')],
  policyNumberSecond: [claimsKeys.POLICY_NUMBER_SECOND, podPolicyNumberValueProxy('policyNumberSecond')],
};

const mappingDamagedBuildingAccessoriess = [
  claimsKeys.property.BUILDING_ACCESSORIES,
  getYesNoValue,
];

export default {
  'who-fills': [
    claimsKeys.property.WHO_FILLS,
    getOneFromMultiProxy({
      private: claimsKeys.property.PRIVATE,
      business: claimsKeys.property.BUSINESS,
    }),
  ],
  'property-what-happened': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      fire: userFlows.FIRE,
      theft: userFlows.THEFT,
      water: userFlows.WATER,
      storm: userFlows.STORM,
      hazards: userFlows.HAZARDS,
      glass: userFlows.GLASS,
      other: userFlows.OTHER,
      bicycle: userFlows.BICYCLE_THEFT,
    }),
  ],
  'property-business-what-happened': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      fire: userFlows.FIRE,
      theft: userFlows.THEFT,
      water: userFlows.WATER,
      storm: userFlows.STORM,
      hazards: userFlows.HAZARDS,
      glass: userFlows.GLASS,
      other: userFlows.OTHER,
      bicycle: userFlows.BICYCLE_THEFT,
    }),
  ],
  // Inside-Outside
  'fire_inside-outside': mappingInsideOutside,
  'theft_inside-outside': mappingInsideOutside,
  'water_inside-outside': mappingInsideOutside,
  'natural-hazards_inside-outside': mappingInsideOutside,
  'storm-hail_inside-outside': mappingInsideOutside,
  // Inside-Outside Business
  'water-business_inside-outside': mappingInsideOutsideBusiness,
  'theft-business_inside-outside': mappingInsideOutsideBusiness,
  'storm-hail-business_inside-outside': mappingInsideOutsideBusiness,
  'fire-business_inside-outside': mappingInsideOutsideBusiness,
  'natural-hazards-business_inside-outside': mappingInsideOutsideBusiness,
  // Affected
  fire_affected_owner: mappingAffectedOwner,
  fire_affected_day: mappingAffectedOrCircumstancesDay,
  theft_affected_owner: mappingAffectedOwner,
  theft_affected_day: mappingAffectedOrCircumstancesDay,
  water_affected_owner: mappingAffectedOwner,
  water_affected_day: mappingAffectedOrCircumstancesDay,
  'storm-hail_affected_owner': mappingAffectedOwner,
  'storm-hail_affected_day': mappingAffectedOrCircumstancesDay,
  'storm-hail-business_affected_owner': mappingAffectedOwner,
  'storm-hail-business_affected_day': mappingAffectedOrCircumstancesDay,
  'natural-hazards_affected_owner': mappingAffectedOwner,
  'natural-hazards_affected_day': mappingAffectedOrCircumstancesDay,
  'water-business_affected_owner': mappingAffectedOwner,
  'water-business_affected_day': mappingAffectedOrCircumstancesDay,
  'theft-business_affected_owner': mappingAffectedOwner,
  'natural-hazards-business_affected_owner': mappingAffectedOwner,
  'fire-business_affected_owner': mappingAffectedOwner,
  'fire-business_affected_day': mappingAffectedOrCircumstancesDay,
  // EventCircumstancesDay
  'fire_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'theft_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'bicycle-theft_day': mappingAffectedOrCircumstancesDay,
  'bicycle-theft-business_day': mappingAffectedOrCircumstancesDay,
  'water_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'storm-hail_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'natural-hazards_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'glass-breakage_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'other-business_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'other-private_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'glass-breakage-business_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'fire-business_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  'theft-business_affected_day': mappingAffectedOrCircumstancesDay,
  'natural-hazards-business_affected_day': mappingAffectedOrCircumstancesDay,
  'natural-hazards-business_event-circumstances_day': mappingAffectedOrCircumstancesDay,
  // EventCircumstancesLocation
  'fire_event-circumstances_location': mappingEventCircumstancesLocation,
  'theft_event-circumstances_location': mappingEventCircumstancesLocation,
  'water_event-circumstances_location': mappingEventCircumstancesLocation,
  'storm-hail_event-circumstances_location': mappingEventCircumstancesLocation,
  'storm-hail-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'natural-hazards_event-circumstances_location': mappingEventCircumstancesLocation,
  'glass-breakage_event-circumstances_location': mappingEventCircumstancesLocation,
  'other-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'other-private_event-circumstances_location': mappingEventCircumstancesLocation,
  'glass-breakage-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'water-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'theft-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'natural-hazards-business_event-circumstances_location': mappingEventCircumstancesLocation,
  'fire-business_event-circumstances_location': mappingEventCircumstancesLocation,
  // EventCircumstancesLocationActive
  'fire_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'theft_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'water_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'storm-hail_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'storm-hail-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'natural-hazards_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'glass-breakage_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'other-private_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'other-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'glass-breakage-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'water-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'theft-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'bicycle-theft_location-active': mappingEventCircumstancesLocationActive,
  'bicycle-theft-business_location-active': mappingEventCircumstancesLocationActive,
  'natural-hazards-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  'fire-business_event-circumstances_location-active': mappingEventCircumstancesLocationActive,
  // CircumstancesDescription
  fire_circumstances_description: mappingCircumstancesDescription,
  theft_circumstances_description: mappingCircumstancesDescription,
  'theft_circumstances-building_description': mappingCircumstancesDescription,
  water_circumstances_description: mappingCircumstancesDescription,
  'water-business_circumstances_description': mappingCircumstancesDescription,
  'storm-hail_circumstances_description': mappingCircumstancesDescription,
  'storm-hail-business_circumstances_description': mappingCircumstancesDescription,
  'natural-hazards_circumstances_description': mappingCircumstancesDescription,
  'other-private_damage_circumstances-description': mappingCircumstancesDescription,
  'other-business_damage_circumstances-description': mappingCircumstancesDescription,
  'theft-business_theft-business-damage-circumstances_description': mappingCircumstancesDescription,
  'natural-hazards-business_circumstances_description': mappingCircumstancesDescription,
  'fire-business_circumstances_description': mappingCircumstancesDescription,
  // WhatDamagedBuilding
  'fire_what-damaged-building_options': mappingWhatDamagedBuilding,
  'theft_what-damaged-building_options': mappingWhatDamagedBuilding,
  'water_what-damaged-building_options': mappingWhatDamagedBuilding,
  'storm-hail_what-damaged-building_options': mappingWhatDamagedBuilding,
  'natural-hazards_what-damaged-building_options': mappingWhatDamagedBuilding,
  'storm-hail-business_what-damaged-building-business_options': mappingWhatDamagedBuildingBusiness,
  'water-business_what-damaged-building-business_options': mappingWhatDamagedBuildingBusiness,
  'natural-hazards-business_what-damaged-building-business_options': mappingWhatDamagedBuildingBusiness,
  'fire-business_what-damaged-building-business_options': mappingWhatDamagedBuildingBusiness,
  // WhatDamagedHousehold
  'fire_what-damaged-household_options': mappingWhatDamagedHousehold,
  'theft_what-damaged-household_options': mappingWhatDamagedHousehold,
  'water_what-damaged-household_options': mappingWhatDamagedHousehold,
  'storm-hail_what-damaged-household_options': mappingWhatDamagedHousehold,
  'natural-hazards_what-damaged-household_options': mappingWhatDamagedHousehold,
  'water-business_what-damaged-household-business_options': mappingWhatDamagedHouseholdBusiness,
  'theft-business_what-damaged-household-business_options': mappingWhatDamagedHouseholdBusiness,
  'natural-hazards-business_what-damaged-household-business_options': mappingWhatDamagedHouseholdBusiness,
  'storm-hail-business_what-damaged-household-business_options': mappingWhatDamagedHouseholdBusiness,
  'fire-business_what-damaged-household-business_options': mappingWhatDamagedHouseholdBusiness,
  // HaveYouDamagedInfo
  'fire_have-you-damage_information': mappingHaveYouDamagedInformation,
  'fire_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'theft_have-you-damage_information': mappingHaveYouDamagedInformation,
  'theft_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'water_have-you-damage_information': mappingHaveYouDamagedInformation,
  'water_have-you-damage-with-partner_information': mappingHaveYouDamagedInformation,
  'water_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'storm-hail_have-you-damage-no-artisan-work_information': mappingHaveYouDamagedInformation,
  'storm-hail-business_have-you-damage-no-artisan-work_information': mappingHaveYouDamagedInformation,
  'natural-hazards_have-you-damage-no-artisan-work_information': mappingHaveYouDamagedInformation,
  'theft-business_have-you-damage-no-artisan-work_information': mappingHaveYouDamagedInformation,
  'storm-hail_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'natural-hazards_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'glass-breakage_have-you-damage_information': mappingHaveYouDamagedInformation,
  'other-private_have-you-damage': mappingHaveYouDamagedInformation,
  'other-business_have-you-damage_information': mappingHaveYouDamagedInformation,
  'glass-breakage-business_have-you-damage_information': mappingHaveYouDamagedInformation,
  'water-business_have-you-damage-with-partner_information': mappingHaveYouDamagedInformation,
  'fire-business_have-you-damage-with-partner_information': mappingHaveYouDamagedInformation,
  'theft-business_have-you-damage_information': mappingHaveYouDamagedInformation,
  'theft-business_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  'natural-hazards-business_have-you-damage-with-partner_information': mappingHaveYouDamagedInformation,
  'natural-hazards-business_have-you-damage-no-artisan-work_information': mappingHaveYouDamagedInformation,
  'storm-hail-business_have-you-damage-without-repair_information': mappingHaveYouDamagedInformation,
  // HaveYouDamagedValues
  'fire_have-you-damage_values': mappingHaveYouDamagedValues,
  'fire_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'theft_have-you-damage_values': mappingHaveYouDamagedValues,
  'theft_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'water_have-you-damage_values': mappingHaveYouDamagedValues,
  'water_have-you-damage-with-partner_values': mappingHaveYouDamagedValues,
  'water_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'storm-hail_have-you-damage-no-artisan-work_values': mappingHaveYouDamagedValues,
  'storm-hail_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'storm-hail-business_have-you-damage-no-artisan-work_values': mappingHaveYouDamagedValues,
  'theft-business_have-you-damage-no-artisan-work_values': mappingHaveYouDamagedValues,
  'natural-hazards_have-you-damage-no-artisan-work_values': mappingHaveYouDamagedValues,
  'natural-hazards_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'glass-breakage_have-you-damage_values': mappingHaveYouDamagedValues,
  'other-private_have-you-damage_values': mappingHaveYouDamagedValues,
  'other-business_have-you-damage_values': mappingHaveYouDamagedValues,
  'glass-breakage-business_have-you-damage_values': mappingHaveYouDamagedValues,
  'water-business_have-you-damage-with-partner_values': mappingHaveYouDamagedValues,
  'fire-business_have-you-damage-with-partner_values': mappingHaveYouDamagedValues,
  'theft-business_have-you-damage_values': mappingHaveYouDamagedValues,
  'theft-business_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  'natural-hazards-business_have-you-damage-with-partner_values': mappingHaveYouDamagedValues,
  'natural-hazards-business_have-you-damage-no-artisan-work_values': mappingHaveYouDamagedValues,
  'storm-hail-business_have-you-damage-without-repair_values': mappingHaveYouDamagedValues,
  // HaveYouDamagedIsRepaired
  'fire_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'fire_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'theft_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'theft_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'water_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'water_have-you-damage-with-partner_is-repaired': mappingHaveYouDamagedIsRepaired,
  'water_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'storm-hail_have-you-damage-no-artisan-work_is-repaired': mappingHaveYouDamagedIsRepaired,
  'storm-hail_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'storm-hail-business_have-you-damage-no-artisan-work_is-repaired': mappingHaveYouDamagedIsRepaired,
  'theft-business_have-you-damage-no-artisan-work_is-repaired': mappingHaveYouDamagedIsRepaired,
  'natural-hazards_have-you-damage-no-artisan-work_is-repaired': mappingHaveYouDamagedIsRepaired,
  'natural-hazards_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'glass-breakage_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'glass-breakage-business_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'water-business_have-you-damage-with-partner_is-repaired': mappingHaveYouDamagedIsRepaired,
  'fire-business_have-you-damage-with-partner_is-repaired': mappingHaveYouDamagedIsRepaired,
  'theft-business_have-you-damage_is-repaired': mappingHaveYouDamagedIsRepaired,
  'theft-business_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  'natural-hazards-business_have-you-damage-with-partner_is-repaired': mappingHaveYouDamagedIsRepaired,
  'natural-hazards-business_have-you-damage-no-artisan-work_is-repaired': mappingHaveYouDamagedIsRepaired,
  'storm-hail-business_have-you-damage-without-repair_is-repaired': mappingHaveYouDamagedIsRepaired,
  // HaveYouDamagedIsProperty
  'fire_have-you-damage_property': mappingHaveYouDamagedProperty,
  'theft_have-you-damage_property': mappingHaveYouDamagedProperty,
  'water_have-you-damage-with-partner_property': mappingHaveYouDamagedProperty,
  'water_have-you-damage_property': mappingHaveYouDamagedProperty,
  'storm-hail_have-you-damage-no-artisan-work_property': mappingHaveYouDamagedProperty,
  'natural-hazards_have-you-damage-no-artisan-work_property': mappingHaveYouDamagedProperty,
  'water-business_have-you-damage-with-partner_property': mappingHaveYouDamagedProperty,
  'fire-business_have-you-damage-with-partner_property': mappingHaveYouDamagedProperty,
  'theft-business_have-you-damage-no-artisan-work_property': mappingHaveYouDamageNoArtisanWorkProperty,
  'natural-hazards-business_have-you-damage-with-partner_property': mappingHaveYouDamagedProperty,
  'natural-hazards-business_have-you-damage-no-artisan-work_property': mappingHaveYouDamagedProperty,
  'storm-hail-business_have-you-damage-no-artisan-work_property': mappingHaveYouDamageNoArtisanWorkProperty,
  // HaveYouDamagedContact
  'fire_have-you-damage_contact': mappingHaveYouDamagedContact,
  'fire_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'theft_have-you-damage_contact': mappingHaveYouDamagedContact,
  'theft_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'water_have-you-damage_contact': mappingHaveYouDamagedContact,
  'water_have-you-damage-with-partner_contact': mappingHaveYouDamagedContact,
  'water_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'storm-hail_have-you-damage-no-artisan-work_contact': mappingHaveYouDamagedContact,
  'storm-hail_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'storm-hail-business_have-you-damage-no-artisan-work_contact': mappingHaveYouDamagedContact,
  'theft-business_have-you-damage-no-artisan-work_contact': mappingHaveYouDamagedContact,
  'natural-hazards_have-you-damage-no-artisan-work_contact': mappingHaveYouDamagedContact,
  'natural-hazards_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'glass-breakage_have-you-damage_contact': mappingHaveYouDamagedContact,
  'other-private_contact': mappingHaveYouDamagedContact,
  'other-business_have-you-damage_contact': mappingHaveYouDamagedContact,
  'glass-breakage-business_have-you-damage_contact': mappingHaveYouDamagedContact,
  'water-business_have-you-damage-with-partner_contact': mappingHaveYouDamagedContact,
  'fire-business_have-you-damage-with-partner_contact': mappingHaveYouDamagedContact,
  'theft-business_have-you-damage_contact': mappingHaveYouDamagedContact,
  'bicycle-theft_contact': mappingHaveYouDamagedContact,
  'bicycle-theft-business_contact': mappingHaveYouDamagedContact,
  'theft-business_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  'natural-hazards-business_have-you-damage-with-partner_contact': mappingHaveYouDamagedContact,
  'natural-hazards-business_have-you-damage-no-artisan-work_contact': mappingHaveYouDamagedContact,
  'storm-hail-business_have-you-damage-without-repair_contact': mappingHaveYouDamagedContact,
  // IdsPolicyNumber
  'fire_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'theft_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'water_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'storm-hail_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'natural-hazards_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'water-business_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'theft-business_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'natural-hazards-business_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'fire-business_ids-policy-number_two-items': mappingIdsPolicyNumber,
  'storm-hail-business_ids-policy-number_two-items': mappingIdsPolicyNumber,
  // IdPolicyNumber
  'fire_id-policy-number_one-item': mappingIdPolicyNumber,
  'theft_id-policy-number_one-item': mappingIdPolicyNumber,
  'water_id-policy-number_one-item': mappingIdPolicyNumber,
  'storm-hail_id-policy-number_one-item': mappingIdPolicyNumber,
  'storm-hail-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'natural-hazards_id-policy-number_one-item': mappingIdPolicyNumber,
  'glass-breakage_id-policy-number_one-item': mappingIdPolicyNumber,
  'other-private_id-policy-number_one-item': mappingIdPolicyNumber,
  'other-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'glass-breakage-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'water-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'theft-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'bicycle-theft_id-policy-number_one-item': mappingIdPolicyNumber,
  'bicycle-theft-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'natural-hazards-business_id-policy-number_one-item': mappingIdPolicyNumber,
  'fire-business_id-policy-number_one-item': mappingIdPolicyNumber,
  // WhichFloor
  'other-business_which-floor-business_options': mappingWhichFloor,
  'glass-breakage-business_which-floor-business_options': mappingWhichFloor,
  'water-business_which-floor-business_options': mappingWhichFloor,
  'theft-business_which-floor-business_options': mappingWhichFloor,
  'storm-hail-business_which-floor-business_options': mappingWhichFloor,
  'fire-business_which-floor-business_options': mappingWhichFloor,
  'natural-hazards-business_which-floor-business_options': mappingWhichFloor,
  // DamagedBuildingAccessoriess
  'water-business_damaged-building-accessories_options': mappingDamagedBuildingAccessoriess,
  'fire-business_damaged-building-accessories_options': mappingDamagedBuildingAccessoriess,
  'natural-hazards-business_damaged-building-accessories': mappingDamagedBuildingAccessoriess,
  // Operations
  'other-business_operations-business_options': mappingOperations,
  'water-business_operations-business_options': mappingOperations,
  'theft-business_operations-business_options': mappingOperations,
  'natural-hazards-business_operations-business_options': mappingOperations,
  'storm-hail-business_operations-business_options': mappingOperations,
  'fire-business_operations-business_options': mappingOperations,
  // Iban
  'bicycle-theft_iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'bicycle-theft-business_iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'bicycle-theft-business_iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'bicycle-theft_iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  iban_payment: [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  iban_name: {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'tax-for-damage': [claimsKeys.property.TAX_FOR_DAMAGE, getYesNoValue],
  'your-message': claimsKeys.YOUR_MESSAGE,
  // Upload Documents
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'bicycle-theft_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'bicycle-theft-business_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'bicycle-theft_has-files-with-info': [claimsKeys.HAS_FILES, getYesNoValue],
  'bicycle-theft-business_has-files-with-info': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'bicycle-theft_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'bicycle-theft-business_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
  'bicycle-theft_files': [claimsKeys.FILES, getUploadedFilesProxy],
  'bicycle-theft-business_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
