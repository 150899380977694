import claimCommon from './claimCommon';
import claimFire from './claimFire';
import claimTheft from './claimTheft';
import claimBicycleTheft from './claimBicycleTheft';
import claimBicycleTheftBusiness from './claimBicycleTheftBusiness';
import claimWater from './claimWater';
import claimStorm from './claimStorm';
import claimNatural from './claimNatural';
import claimGlass from './claimGlass';
import claimGlassBusiness from './claimGlassBusiness';
import claimOtherBusiness from './claimOtherBusiness';
import claimWaterBusiness from './claimWaterBusiness';
import claimTheftBusiness from './claimTheftBusiness';
import claimNaturalBusiness from './claimNaturalBusiness';
import claimStormBusiness from './claimStormBusiness';
import claimFireBusiness from './claimFireBusiness';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import claimOtherPrivate from './claimOtherPrivate';
import { getAdditionalData, getPageType, getWhatHappened } from '../../../helpers/formData/propertyFormData';

const propertyTransform = formData => {
  const claimData = {
    ...claimFire,
    ...claimTheft,
    ...claimBicycleTheft,
    ...claimWater,
    ...claimStorm,
    ...claimNatural,
    ...claimGlass,
    ...claimGlassBusiness,
    ...claimOtherPrivate,
    ...claimOtherBusiness,
    ...claimWaterBusiness,
    ...claimTheftBusiness,
    ...claimNaturalBusiness,
    ...claimStormBusiness,
    ...claimFireBusiness,
    ...claimBicycleTheftBusiness,
    ...claimCommon,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data: { ...data, ...getPageType(data), ...getWhatHappened(data) },
    serviceType: moduleNames.PROPERTY,
    ...additionalData,
  };
};

export default propertyTransform;
