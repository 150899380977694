import React from 'react';
import PropTypes from 'prop-types';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ErgoInputText from '../../components/ErgoInputText';

const PolicyNumber = ({
  localePath,
  getValidationProps,
}, { t }) => {
  const { value, ...otherProps } = getValidationProps();
  const validationProps = { value: (value || '').toUpperCase(), ...otherProps };


  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-s-6">
          <ErgoInputText
            {...validationProps}
            description={t(`${localePath}.description`)}
            placeholder={t(`${localePath}.placeholder`)}
          />
        </div>
      </div>
    </div>
  );
};

PolicyNumber.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
};

PolicyNumber.defaultProps = {
  localePath: '',
  getValidationProps: () => ({}),
};

PolicyNumber.contextTypes = {
  t: PropTypes.func,
};

export { PolicyNumber as PolicyNumberPlain };
export default withSingleFieldValidation(PolicyNumber);
