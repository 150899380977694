import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import Radio from '@eg/elements/Radio';
import Benefit from '@eg/elements/Benefit';
import Card from '@eg/elements/Card';
import MailIcon from '@eg/elements/Icons/MailIcon';
import HelpIcon from '@eg/elements/Icons/HelpIcon';
import { AlertIcon } from '@eg/elements/components/Icons';
import { contactDataProps } from '../../models/panels';


import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoMaskedText from '../ErgoMaskedText';
import ErgoInputText from '../../components/ErgoInputText';
import ErgoCheckbox from '../../components/ErgoCheckbox';
import { maskTypes, formatCharactersToMask } from '../../helpers/masks';
import styles from './styles.jss';

class ContactData extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      who: props.data.who,
      agreement: props.data.agreement,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getErrorMessage(validationProps) {
    if (!validationProps || !validationProps.error) {
      return '';
    }

    const { who } = this.state;

    let customError = '';
    if (who === contactDataProps.optionTypes.COMPANY) {
      customError = validationProps.error.company;
    } else {
      customError = validationProps.error.person;
    }

    return customError || validationProps.error;
  }

  handleChange = e => {
    const { getValidationProps } = this.props;
    const { onChange, name } = getValidationProps('who');
    const { value } = e.target;
    const target = {
      name,
      value,
    };
    onChange({ target });
    this.setState({ who: value });
  };

  flipCheckbox = () => {
    const { getValidationProps } = this.props;
    const { onChange, name, value } = getValidationProps('agreement');
    const newValue = !value;
    const target = {
      name,
      value: newValue,
    };
    onChange({ target });
    this.setState({ agreement: newValue });
  }

  render() {
    const {
      localePath, getValidationProps, options, classes,
    } = this.props;
    const { t } = this.context;
    const { who, agreement } = this.state;
    const { name } = getValidationProps('who');
    const agreementValidation = getValidationProps('agreement');
    const agreementLabel = t(`${localePath}.labels.agreement`);
    const agreementTitle = t(`${localePath}.labels.agreementTitle`);
    const agreementInfo = {
      label: t(`${localePath}.labels.trackerInfoTitle`),
      firstLine: t(`${localePath}.labels.trackerInfoFirstLine`),
      secondLine: t(`${localePath}.labels.trackerInfoSecondLine`),
      trackerDisabledHint: t(`${localePath}.labels.trackerDisabledHint`),
      trackerDisabledTitle: t(`${localePath}.labels.trackerDisabledTitle`),
      boxChip: t(`${localePath}.labels.boxChip`),
    };
    const inputs = {
      firstName: { size: 6 },
      lastName: { size: 6 },
      phoneNumber: {
        size: 6,
        mask: maskTypes.PHONE_NUMBER,
        formatCharacters: formatCharactersToMask.PHONE_NUMBER,
      },
      email: { size: 6 },
    };

    const cardConfiguration = {
      padding: 'auto',
      label: agreementInfo.boxChip,
      chipColor: '#E87A16',
      accentColor: '#E87A16',
      labelColor: '#fff',
      alignLabel: 'right',
      className: classes.cardOverWrites,
    };

    return (
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className={`esc_col esc_col-12 ${classes.options}`}>
            <div>
              {t(`${localePath}.labels.head`)}
            </div>
            {options.map(option => (
              <Radio
                className={classes.radioButton}
                onChange={this.handleChange}
                name={name}
                key={option}
                value={option}
                label={t(`${localePath}.labels.${option}`)}
                checked={who === option}
              />
            ))}
          </div>
          {Object.keys(inputs).map(inputName => {
            const input = inputs[inputName];
            const isCompany = who === contactDataProps.optionTypes.COMPANY
              ? contactDataProps.optionTypes.COMPANY : 'person';
            const inputPlaceholder = t(`${localePath}.placeholders.${isCompany}.${inputName}`);
            const inputDescription = t(`${localePath}.descriptions.${isCompany}.${inputName}`);
            const validationProps = getValidationProps(inputName);
            const error = this.getErrorMessage(validationProps);
            const validationPropsWithError = { ...validationProps, error };
            if (input.mask) {
              return (
                <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                  <ErgoMaskedText
                    type="text"
                    placeholder={inputPlaceholder}
                    mask={input.mask}
                    formatCharacters={input.formatCharacters}
                    inputSize={input.size}
                    {...validationPropsWithError}
                  />
                </div>
              );
            }

            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <ErgoInputText
                  {...validationPropsWithError}
                  placeholder={inputPlaceholder}
                  description={inputDescription}
                />
              </div>
            );
          })}
          {agreementLabel ? (
            <Card {...cardConfiguration}>
              <Benefit className={classes.benefitOverWrites} icon={MailIcon} label={agreementTitle}>
                <ErgoCheckbox
                  label={agreementLabel}
                  checked={agreement}
                  error={agreementValidation.error}
                  name={agreementValidation.name}
                  onChange={agreementValidation.onChange}
                  onClick={this.flipCheckbox}
                  className={classes.options}
                />
              </Benefit>
              <Benefit className={classes.benefitOverWrites} icon={HelpIcon} label={agreementInfo.label}>
                <p>{` - ${agreementInfo.firstLine}`}</p>
                <p>{` - ${agreementInfo.secondLine}`}</p>
              </Benefit>
              <Benefit className={classes.benefitOverWrites} icon={AlertIcon} label={agreementInfo.trackerDisabledTitle}>
                <p>{`${agreementInfo.trackerDisabledHint}`}</p>
              </Benefit>

            </Card>
          )
            : ''}
        </div>
      </div>
    );
  }
}

ContactData.propTypes = contactDataProps.props;

ContactData.contextTypes = {
  t: PropTypes.func,
};

ContactData.defaultProps = contactDataProps.defaults;

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

export { ContactData as ContactDataPlain };
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
)(ContactData);
