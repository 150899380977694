import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getDamageOfAccidentDetails } from '../../../helpers/formData/propertyFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    householdItems,
    buildingItems,
    floorWallCovering,
    isRepaired,
    executionOfRepairs,
    damageDescription,
    isTheftFlow,
    isGlassFlow,
    isStormFlow,
    isOtherPrivateFlow,
    operations,
    dmgLocation,
    isBusinessFlow,
    isBicycleTheftFlow,
    bikeType,
    bikeModel,
    bikePrice,
    stolenAccessoires,
    stolenAccessoiresValue,
  } = getDamageOfAccidentDetails(formData);

  const isOperationsVisible = operations && isBusinessFlow && !isGlassFlow;
  const isDmgLocationVisible = dmgLocation && isBusinessFlow && isStormFlow;

  if(isOtherPrivateFlow){
    return false;
  };
  
  return (
    <Details
      iconLeft={SvgDerSchadenDesUnfallbeteiligten}
      summary={t(`${localePath}.damage-of-accident.title`)}
      className={className}
    >
      { isBicycleTheftFlow && bikeType && (
      <p>
        {t(`${localePath}.damage-of-accident.which-type-of-bike`, { bikeType })}
      </p>
      )}
      { isBicycleTheftFlow && bikeModel && (
      <p>
        {t(`${localePath}.damage-of-accident.bike-model-description`, { bikeModel })}
      </p>
      )}
      { isBicycleTheftFlow && bikePrice && (
        <p>
          {t(`${localePath}.damage-of-accident.bike-price`, { bikePrice })}
        </p>
      )}
      { isBicycleTheftFlow && stolenAccessoires && (
        <p>
          {t(`${localePath}.damage-of-accident.stolen-accessoires`, { stolenAccessoires })}
        </p>
      )}
      { isBicycleTheftFlow && stolenAccessoiresValue && (
        <p>
          {t(`${localePath}.damage-of-accident.stolen-accessoires-value`, { stolenAccessoiresValue })}
        </p>
      )}
      { householdItems && !isBicycleTheftFlow && (
        <p>
          { isTheftFlow
            ? t(`${localePath}.damage-of-accident.content-household-theft`, { householdItems })
            : t(`${localePath}.damage-of-accident.content-household`, { householdItems })
          }
        </p>
      )}
      { buildingItems && (
        <p>
          { isGlassFlow
            ? t(`${localePath}.damage-of-accident.content-building-glass`, { buildingItems })
            : t(`${localePath}.damage-of-accident.content-building`, { buildingItems })
          }
        </p>
      )}
      { floorWallCovering && (
        <p>
          {t(`${localePath}.damage-of-accident.content-floor-wall-covering-${floorWallCovering}`)}
        </p>
      )}
      { damageDescription && (
        <p>
          {t(`${localePath}.damage-of-accident.content-damage-description`, { damageDescription })}
        </p>
      )}
      <p>
        {t(`${localePath}.damage-of-accident.content-damage-was-repaired-${isRepaired}`)}
      </p>
      { executionOfRepairs && (
        <p>
          {t(`${localePath}.damage-of-accident.content-execution-of-repairs`, { executionOfRepairs })}
        </p>
      )}
      { isOperationsVisible && (
      <p>
        {t(`${localePath}.damage-of-accident.operations`, { operations })}
      </p>
      )}
      { isDmgLocationVisible && (
        <p>
          {t(`${localePath}.damage-of-accident.dmg-location`, { dmgLocation })}
        </p>
      )}
    </Details>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
