import { accidentGuilt } from '../../../helpers/formData/vehicleFormData';
import { getTranslationArray } from '../../../helpers/polyglot';

export const getDescribeDamageValueProxy = formValue => {
  const labels = getTranslationArray('vehicle.glass-damage.circumstances.describe-damage.labels');
  const parsedParts = JSON.parse(formValue.typesOfGlassDamage);
  const damagedParts = parsedParts.reduce((aggr, value) => {
    aggr.push(labels[value]);
    return aggr;
  }, []);
  return `${damagedParts.join(', ')} ${formValue.describeDamage}`;
};

export const getTheftCircumstancesValues = formValue => {
  const labels = getTranslationArray('vehicle.theft.part.theft-circumstances.options');
  const damagedParts = formValue.reduce((acc, value) => {
    acc.push(labels[value]);
    return acc;
  }, []);
  return damagedParts.join(', ');
};

export const getGuiltValue = field => accidentGuilt[field];
