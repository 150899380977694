import axios from 'axios';
import { config as conf } from '../helpers/config';
/* eslint-disable */

const isCustomerDataRequest = (url) => url === 'bff-customer-data/read';

const requestHandler = async (request) => {
  if (!isCustomerDataRequest(request.url)) {
    const recaptcaToken = await window.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'e2eclaim'})
    request.headers['g-recaptcha-token'] = recaptcaToken || '';
  }
  return request;
};

const successHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  switch (error.response.status) {
    case 404:
      global.communicationModal.showDialog(404, [
        {
          func: "goToUrl",
          name: "cancel",
          variant: "primary",
          opts: {
            location: "/",
          },
        },
      ]);
      break;

    case 422:
      global.communicationModal.showDialog(422, [
        {
          func: "goToUrl",
          name: "cancel",
          variant: "primary",
          opts: {
            location: "/",
          },
        },
      ]);
      break;

    case 500:
      global.communicationModal.showDialog(500, [
        {
          func: "goToUrl",
          name: "cancel",
          variant: "primary",
          opts: {
            location: "/",
          },
        },
      ]);
      break;
    case 503:
      global.communicationModal.showDialog(503, [
        {
          func: "goToUrl",
          name: "cancel",
          variant: "primary",
          opts: {
            location: "/",
          },
        },
      ]);
      break;

    default:
      break;
  }

  return Promise.reject(error);
};

const AxiosInstance = axios.create({
  baseURL: conf.API_HOST,
});

AxiosInstance.interceptors.request.use((request) => requestHandler(request));

AxiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
/* eslint-enable */
export default AxiosInstance;
