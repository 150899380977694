import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import { debounce } from 'lodash';
import withSizes from 'react-sizes';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import ProfileIcon from '@eg/elements/Icons/ProfileIcon';
import MessageBox from '@eg/elements/MessageBox';
import Details from '@eg/elements/Details';
import { mapSizesToProps } from '../../helpers/mapSizesToProp';
import { navigateToPath, setNavigationState } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../../layout/HeadingForm';
import ToggleButton from '../../components/ToggleButton';
import { buttonImageSwitchProps } from '../../models/panels';
import styles from './styles.jss';
import CircleBadge from '../../components/CircleBadge';
import { NAV_DELAY } from '../../helpers/navigation';

const ButtonSwitch = (
  {
    localePath,
    data,
    options,
    classes,
    onChange,
    goForward,
    isMobile,
    hasOptionsTitle,
    hasBadge,
    hasOwnHeight,
    alignBoxes,
  },
  { t },
) => {
  const onClick = (value) => () => {
    onChange({ value });
    goForward();
  };

  const classGridWrapper = alignBoxes
    ? classes.gridWrapperLeft
    : classes.gridWrapperCenter;

  return (
    <ContentWrapper>
      {isMobile ? (
        <Details
          iconLeft={InfoIcon}
          className={classes.mobileAccordion}
          summary={(
            <span className={classes.mobileAccordionTitle}>
              {t(`${localePath}.privacyInfoShowMore`)}
            </span>
          )}
        >
          {t(`${localePath}.legalText`)}
          <a
            href="https://www.ergo.de/de/Service/Datenschutz"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            {t(`${localePath}.legalTextLink`)}
          </a>
          .
        </Details>
      ) : (
        <MessageBox className={classes.messageBox}>
          <InfoIcon width={32} height={32} className={classes.infoIcon} />
          <div className={classes.messageBoxContent}>
            {t(`${localePath}.legalText`)}
            <a
              href="https://www.ergo.de/de/Service/Datenschutz"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              {t(`${localePath}.legalTextLink`)}
            </a>
            .
          </div>
        </MessageBox>
      )}

      {hasBadge ? (
        <div className={`esc_grid ${classes.badgeHeadingContainer}`}>
          <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-12 esc_col-m-2">
              <CircleBadge text={t(`${localePath}.badgeText`)} />
            </div>
            <div className="esc_col esc_col-12 esc_col-m-10">
              <HeadingForm
                value={t(`${localePath}.heading`)}
                commentValue={t(`${localePath}.comment`)}
                classes={{
                  rootPosition: classes.badgeHeadingPosition,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <HeadingForm
          value={t(`${localePath}.heading`)}
          commentValue={t(`${localePath}.comment`)}
          classes={{
            rootPosition: classes.headingPosition,
          }}
        />
      )}

      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classGridWrapper}`}>
          {options.map(({ key, icon }, index) => {
            const IconComponent = buttonImageSwitchProps.icons[icon] || ProfileIcon;
            return (
              <div
                key={key}
                className="esc_col-6 esc_col-s-6 esc_col-m-4 esc_col-l-3"
              >
                <ToggleButton
                  index={index}
                  option={key}
                  onClick={debounce(onClick(key), NAV_DELAY)}
                  selected={data === key}
                  {...(hasOptionsTitle && {
                    radioTile: {
                      title: t(`${localePath}.optionsTitle.${key}`),
                    },
                  })}
                >
                  <div style={{ height: hasOwnHeight }}>
                    <div className={classes.wrapper}>
                      <IconComponent className={classes.icon} />
                      <div className={classes.toggleLabel}>
                        {t(`${localePath}.options.${key}`)}
                      </div>
                    </div>
                  </div>
                </ToggleButton>
              </div>
            );
          })}
        </div>
      </div>
    </ContentWrapper>
  );
};

ButtonSwitch.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
  classes: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isMobile: PropTypes.bool,
  hasOptionsTitle: PropTypes.bool,
  hasBadge: PropTypes.bool,
  hasOwnHeight: PropTypes.string,
  alignBoxes: PropTypes.string,
};

ButtonSwitch.defaultProps = {
  localePath: '',
  data: '',
  classes: {},
  options: [],
  onChange: () => {},
  goForward: () => {},
  isMobile: false,
  hasOptionsTitle: true,
  hasBadge: false,
  hasOwnHeight: 'auto',
  alignBoxes: null,
};

ButtonSwitch.contextTypes = {
  t: PropTypes.func,
};

export { ButtonSwitch as ButtonSwitchPlain };

/* istanbul ignore next */
const mapStateToProps = ({ formData }) => ({ formData });
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  goForward: () => {
    dispatch(setNavigationState(true, true));
    dispatch(navigateToPath(NAV_DIRECTIONS.NEXT, NAV_DELAY));
  },
});

export default compose(
  withSizes(mapSizesToProps),
  withRouter,
  injectSheet(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ButtonSwitch);
