import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { types } from '../../actions/constants';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ErgoMultipleSelectOption from './ErgoMultipleSelectOption';

class ErgoMultipleSelect extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { data, getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();

    if (!isEqual(prevProps.data, data)) {
      const target = {
        name,
        value: data,
      };
      onChange({ target });
    }
  }

  handleClick = value => e => {
    const {
      updateField,
      pathName,
      getValidationProps,
    } = this.props;
    const { name } = getValidationProps();
    updateField(name, value, pathName);
  }

  render() {
    const {
      options,
      localePath,
      data,
    } = this.props;

    return (
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          {options.map((option) => (
            <ErgoMultipleSelectOption
              key={option.key}
              option={option}
              localePath={localePath}
              onClick={this.handleClick}
              data={data}
            />
          ))}
        </div>
      </div>
    );
  }
}

ErgoMultipleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  localePath: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  pathName: PropTypes.string,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

ErgoMultipleSelect.defaultProps = {
  options: [],
  localePath: '',
  data: [],
  pathName: '',
  updateField: () => {},
  getValidationProps: () => {},
};

ErgoMultipleSelect.contextTypes = {
  t: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE_MULTI,
    fieldName: name,
    pathName,
    payload,
  }),
});
export { ErgoMultipleSelect as ErgoMultipleSelectPlain };
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withSingleFieldValidation,
)(ErgoMultipleSelect);
