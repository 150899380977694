import React from 'react';
import PropTypes from 'prop-types';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ErgoMaskedText from '../ErgoMaskedText';

const PostalCode = ({
  localePath,
  getValidationProps,
}, { t }) => (
  <div className="esc_grid">
    <div className="esc_grid__wrapper">
      <div className="esc_col esc_col-12 esc_col-s-6">
        <ErgoMaskedText
          mask="11111"
          {...getValidationProps()}
          placeholder={t(`${localePath}.placeholder`)}
        />
      </div>
    </div>
  </div>
);

PostalCode.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
};

PostalCode.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
};

PostalCode.contextTypes = {
  t: PropTypes.func,
};

export { PostalCode as PostalCodePlain };
export default withSingleFieldValidation(PostalCode);
