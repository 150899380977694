const styles = () => ({
  wrapper: {
    textAlign: 'left',
  },
  option: {
    borderWidth: '0px 12px !important',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 12,
    flexShrink: 0,
  },
});

export default styles;
