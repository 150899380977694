const vehicleFlows = {
  ANIMAL: 'damage-caused-by-an-animal',
  WEATHER: 'damage-caused-by-weather',
  FIRE: 'fire-or-explosion',
  GLASS: 'window-damage',
  VANDALISM: 'vandalism',
  THEFT: 'theft',
  COLLISION: 'collision',
};

export default vehicleFlows;
