import React from 'react';
import injectSheet from 'react-jss';
import { topBarProps } from '../../models/layouts';
import styles from './styles.jss';
import Logo from '../../components/Logo';

const TopBar = ({
  classes,
}) => (
  <div className={classes.topBarBox}>
    <div className={`esc_box esc_box--around ${classes.logoContainer}`}>
      <Logo />
    </div>
  </div>
);
TopBar.propTypes = topBarProps.props;
TopBar.defaultProps = topBarProps.defaults;
TopBar.contextTypes = topBarProps.contexts;

export { TopBar as TopBarPlain };
export default injectSheet(styles)(TopBar);
