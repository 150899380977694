import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { waterOptions } from '../../../helpers/formData/propertyFormData';

export default {
  water_circumstances_options: [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'pipe-burst': waterOptions.PIPE_BURST,
      'pipe-burst-frost': waterOptions.PIPE_BURST_FROST,
      defective: waterOptions.DEFECTIVE,
      taps: waterOptions.TAPS,
      'burst-hoses': waterOptions.BURST_HOSES,
      different: waterOptions.OTHER,
    }),
  ],
  'water_have-you-damage-walls-or-floors': [claimsKeys.property.FLOOR_WALL_COVERING, getYesNoValue],
};
