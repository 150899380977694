import claimCommon from './claimCommon';
import claimTraffic from './claimTraffic';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData, getPageType, getWhatHappened } from '../../../helpers/formData/accidentFormData';

const accidentTransform = formData => {
  const claimData = {
    ...claimCommon,
    ...claimTraffic,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  // We cannot remove this key because has been used in summary
  // delete data[claimsKeys.injured.IS_REPORTER];

  return {
    data: { ...data, ...getPageType(), ...getWhatHappened() },
    serviceType: moduleNames.ACCIDENT,
    ...additionalData,
  };
};

export default accidentTransform;
