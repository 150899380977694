import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgVorfall } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentData } from '../../../helpers/formData/vehicleFormData';
import { polyglot } from '../../../helpers/polyglot';


const getCircumstance = (localePath, circumstance) => (
  circumstance
    ? polyglot.t(`${localePath}.incident.circumstances`, {
      circumstance,
    })
    : ''
);

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isCollisionFlow,
    kindOfDamage,
    policyNumber,
    licenseNumber,
    date,
    circumstance,
    damageDescription,
    location,
    countryName,
  } = getIncidentData(formData);

  return (
    <Details iconLeft={SvgVorfall} summary={t(`${localePath}.incident.title`)} className={className}>
      {t(`${localePath}.incident.content`, {
        licenseNumber: !policyNumber
          ? t(`${localePath}.incident.license-number`, { licenseNumber }) : '',
        date,
        damage: isLiabilityFlow || isCollisionFlow
          ? t(`${localePath}.incident.damage`, { kindOfDamage, circumstance })
          : getCircumstance(localePath, damageDescription),
        location,
        countryName,
      })}
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
