import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigateToPath } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../../layout/HeadingForm';
import Toggle from '../../components/Toggle';
import { NAV_DELAY } from '../../helpers/navigation';
import { getSign } from '../../helpers/sections';
import Card from './Card';

let ts;

const SelectWantToRepair = ({
  localePath,
  yesNoQuestion = true,
  options,
  onChange,
  goForward,
  values,
  name,
}, { t }) => {
  const handleClick = value => () => {
    const target = { name, type: 'text', value };
    onChange({ target });
    clearTimeout(ts);
    ts = setTimeout(goForward, NAV_DELAY);
  };

  const currentValue = values[name];

  return (
    <ContentWrapper>
      <HeadingForm
        value={t(`${localePath}.heading`)}
        commentValue={t(`${localePath}.comment`)}
        tooltipTitle={t(`${localePath}.tooltip.title`)}
        tooltipContent={t(`${localePath}.tooltip.content`)}
      />

      <Card localePath={localePath} />

      {options.map(({ key, sign }, index) => (
        <Toggle
          key={key}
          index={index}
          option={key}
          onClick={handleClick(key)}
          selected={currentValue === key}
          sign={sign || getSign(index, yesNoQuestion, t)}
          toggleText={t(`${localePath}.options.${key}`)}
          complementaryText={t(`${localePath}.options.${key}-comment`)}
        />
      ))}
    </ContentWrapper>
  );
};

SelectWantToRepair.propTypes = {
  localePath: PropTypes.string,
  name: PropTypes.string,
  yesNoQuestion: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
};

SelectWantToRepair.defaultProps = {
  localePath: '',
  name: '',
  values: {},
  options: [],
  yesNoQuestion: true,
  onChange: () => {},
  goForward: () => {},
};

SelectWantToRepair.contextTypes = {
  t: PropTypes.func,
};

export { SelectWantToRepair as SelectWantToRepairPlain };

/* istanbul ignore next */
const mapStateToProps = ({ formData }) => ({ formData });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectWantToRepair);
