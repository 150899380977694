import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { waterOptions } from '../../../helpers/formData/propertyFormData';

export default {
  'water-business_circumstances_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'pipe-burst': waterOptions.PIPE_BURST,
      'pipe-burst-frost': waterOptions.PIPE_BURST_FROST,
      defective: waterOptions.DEFECTIVE,
      taps: waterOptions.TAPS,
      'burst-hoses': waterOptions.BURST_HOSES,
      different: waterOptions.OTHER,
    }),
  ],
};
