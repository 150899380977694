import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgAuszahlung } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { claimsKeys, getIban } from '../../../helpers/formData/formData';
import { hasAnotherBankAccount } from '../../../helpers/formData/accidentFormData';

const PayingOutDetails = ({ formData, localePath, className }, { t }) => (
  <Details iconLeft={SvgAuszahlung} summary={t(`${localePath}.paying-out.title`)} className={className}>
    <p>
      {t(`${localePath}.paying-out.content`, {
        account: formData[claimsKeys.BANK_ACCOUNT],
      })}
    </p>
    { hasAnotherBankAccount(formData) && (
      <>
        <p>
          {t(`${localePath}.paying-out.content-name`, {
            name: formData[claimsKeys.IBAN_NAME],
          })}
        </p>
        <p>
          {t(`${localePath}.paying-out.content-iban`, {
            iban: getIban(formData[claimsKeys.IBAN]),
          })}
        </p>
      </>
    )}
  </Details>
);

PayingOutDetails.propTypes = legalConsentProps.sectionProps;
PayingOutDetails.defaultProps = legalConsentProps.sectionDefaults;

PayingOutDetails.contextTypes = {
  t: PropTypes.func,
};

export default PayingOutDetails;
