import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import styles from './SelectWantToUseCalculationApp.style.jss';
import { navigateToPath } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Dialog from './Dialog';
import Card from './Card';
import HeadingForm from '../../layout/HeadingForm';
import Toggle from '../../components/Toggle';
import { NAV_DELAY } from '../../helpers/navigation';
import { getSign, getNumber } from '../../helpers/sections';

let ts;

const SelectWantToUseCalculationApp = ({
  localePath,
  yesNoQuestion,
  isNumberQuestion,
  options,
  onChange,
  goForward,
  values,
  name,
  cardChecklist,
  dialogChecklist,
}, { t }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = value => () => {
    const target = { name, type: 'text', value };
    onChange({ target });
    clearTimeout(ts);
    ts = setTimeout(goForward, NAV_DELAY);
  };

  const cardColumns = Object.keys(cardChecklist).map(top => Object.keys(cardChecklist[top]).map(val => ({
    label: t(`${localePath}.card.checklist.${top}.${val}`),
    icon: cardChecklist[top][val],
  })));

  const dialogColumns = Object.keys(dialogChecklist).map(top => Object.keys(dialogChecklist[top]).map(val => ({
    label: t(`${localePath}.dialog.checklist.${top}.${val}`),
    icon: dialogChecklist[top][val],
  })));

  const currentValue = values[name];
  const optionsLength = options.length;

  return (
    <ContentWrapper>
      <HeadingForm
        value={t(`${localePath}.heading`)}
        commentValue={t(`${localePath}.comment`)}
        tooltipTitle={t(`${localePath}.tooltip.title`)}
        tooltipContent={t(`${localePath}.tooltip.content`)}
      />

      <Card
        localePath={localePath}
        columns={cardColumns}
        onOpenDialog={() => setIsDialogOpen(true)}
      />

      <Dialog
        headingText={t(`${localePath}.dialog.heading`)}
        commentText={t(`${localePath}.dialog.comment`)}
        checklistHeadingText={t(`${localePath}.dialog.checklist.heading`)}
        columns={dialogColumns}
        localePath={localePath}
        isDialogOpen={isDialogOpen}
        onCloseDialog={() => setIsDialogOpen(false)}
      />

      {options.map(({ key, sign }, index) => (
        <Toggle
          key={key}
          index={index}
          option={key}
          onClick={handleSubmit(key)}
          selected={currentValue === key}
          sign={sign || (isNumberQuestion ? getNumber(index, optionsLength) : getSign(index, yesNoQuestion, t))}
          toggleText={t(`${localePath}.options.${key}`)}
          complementaryText={t(`${localePath}.options.${key}-comment`)}
        />
      ))}
    </ContentWrapper>
  );
};

SelectWantToUseCalculationApp.propTypes = {
  localePath: PropTypes.string,
  name: PropTypes.string,
  yesNoQuestion: PropTypes.bool,
  isNumberQuestion: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
  cardChecklist: PropTypes.objectOf(PropTypes.shape()),
  dialogChecklist: PropTypes.objectOf(PropTypes.shape()),
};

SelectWantToUseCalculationApp.defaultProps = {
  localePath: '',
  name: '',
  values: {},
  options: [],
  yesNoQuestion: true,
  isNumberQuestion: false,
  onChange: () => {},
  goForward: () => {},
  cardChecklist: {},
  dialogChecklist: {},
};

SelectWantToUseCalculationApp.contextTypes = {
  t: PropTypes.func,
};

export { SelectWantToUseCalculationApp as SelectWantToUseCalculationAppPlain };

/* istanbul ignore next */
const mapStateToProps = ({ formData }) => ({ formData });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
});

export default compose(
  withRouter,
  injectSheet(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectWantToUseCalculationApp);
