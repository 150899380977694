const styles = () => ({
  container: {
    textAlign: 'left',
  },
  addButton: {
    marginTop: '0px',
  },
  containerInput: {
    marginTop: '50px',
  },
  error: {
    paddingTop: 10,
  },
  preview: {
    width: '100%',
    height: '75px',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '25px 16px',
    borderRadius: '9px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  closeButton: {
    position: 'relative',
    top: 2,
    right: 2,
    width: '15%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
  },
  fileWrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export default styles;
