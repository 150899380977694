import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { isEqual } from 'lodash';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import UploadList from '../../components/UploadList';
import Input from '../../components/Upload/Input';
import FormMessage from '../../components/FormMessage';
import { addFileAction, removeFileAction } from '../../actions/uploadFile';
import { fileUploadProps } from '../../models/panels';
import styles from './styles.jss';

class FileUpload extends React.Component {
  constructor() {
    super();

    this.state = {
      error: '',
    };
  }

  componentDidUpdate({ data: previousData }) {
    const { data: currentData, getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();

    if (!isEqual(previousData, currentData)) {
      const target = {
        name,
        value: currentData,
      };
      onChange({ target });
    }
  }

  validFileSize = size => {
    const fileSize = (size / 1024 / 1024).toFixed(fileUploadProps.validationTypes.ACCEPTED_FILE_SIZE);
    return fileSize <= fileUploadProps.validationTypes.ACCEPTED_FILE_SIZE;
  };

  validFileName = name => (
    !fileUploadProps.validationTypes.DISALLOWED_FILENAME_CHARS.test(name)
    && name.length < fileUploadProps.validationTypes.MAXIMUM_FILENAME_LENGTH
  )

  validMaxFiles = lengthFiles => lengthFiles < fileUploadProps.validationTypes.UPLOADED_FILES_NUMBER_LIMIT;

  catchError = path => {
    const { localePath } = this.props;
    const message = path ? `${localePath}.${path}` : '';
    this.setState({ error: message });
  }

  async deleteFile(fileId) {
    const {
      getValidationProps, removeFile, pathName,
    } = this.props;
    const { name } = getValidationProps();
    const message = await removeFile(fileId, name, pathName);
    this.catchError(message);
  }

  async addFile(file) {
    const {
      pathName, getValidationProps, addFile,
    } = this.props;

    if (!this.validFileName(file.name)) {
      this.catchError('error-invalid-filename');
      return false;
    }

    if (!this.validFileSize(file.size)) {
      this.catchError('error-file-size');
      return false;
    }

    const { name } = getValidationProps();
    const formData = new FormData();
    formData.append('attachment', file);
    const message = await addFile(formData, name, pathName);
    this.catchError(message);
    return true;
  }

  render() {
    const {
      name, data, accept, localePath, classes,
    } = this.props;
    const { t } = this.context;
    const { error } = this.state;

    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <UploadList
            files={data}
            accept={accept}
            namespace={name}
            onRemove={(fileId) => this.deleteFile(fileId)}
          />
          {this.validMaxFiles(data.length) && (
            <div className="esc_col-12 esc_col-s-6 esc_col-m-4 esc_col-l-4">
              <Input
                name={name}
                accept={accept}
                text={t(`${localePath}.upload-label`)}
                onChange={e => this.addFile(e.target.files[0])}
              />
              {error && (
                <div className={classes.error}>
                  <FormMessage error={error} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = fileUploadProps.props;

FileUpload.defaultProps = fileUploadProps.defaults;

FileUpload.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({
  data: formData[ownProps.pathName][ownProps.name],
});
const mapDispatchToProps = dispatch => ({
  addFile: (data, name, pathName) => dispatch(addFileAction(data, name, pathName)),
  removeFile: (fileId, name, pathName) => dispatch(removeFileAction(fileId, name, pathName)),
});

export { FileUpload as FileUploadPlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withSingleFieldValidation,
  injectSheet(styles),
)(FileUpload);
