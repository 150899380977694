import { breakpoints } from '../../styles/constants';
import { mediaQuery } from '../../styles/helpers';

const styles = theme => ({
  icon: {
    width: '50px',
    height: '50px',
    color: theme.colors.expandedIconBlack,
  },

  toggleLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    color: theme.colors.themeDarkestGray,
    padding: '20px 0',
  },

  labelText: {
    display: 'inline',
    border: `1px solid ${theme.colors.themeDarkGray}`,
    color: theme.colors.themeDarkestGray,
    padding: '5px 10px',
    fontWeight: 700,
    borderRadius: 30,
    ...mediaQuery.max(breakpoints.mediumMobile, {
      display: 'block',
    }),
  },

  labelWrapper: {
    marginTop: 16,
    marginBottom: 16,
  },

  wrapper: {
    padding: '20px 8px 0',
  },
});

export default styles;
