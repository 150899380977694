const styles = ({ breakpoints }) => ({
  container: {
    textAlign: 'left',
  },
  dateContainer: {
    display: 'flex',
  },
  datePickerContainer: {
    width: '60%',
    minWidth: '200px',
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      width: '80%',
    },
  },
});

export default styles;
