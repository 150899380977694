import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import {
  userTypes,
  claimsKeys,
  compareValues,
  getCarMapInfo,
  getParticularDataIfExist,
  NO_VALUE,
} from '../../../helpers/formData/formData';
import {
  isAnimalDamageFlow,
  isPersonDamageFlow,
  isBusinessFlow,
} from '../../../helpers/formData/privateLiabilityFormData';

import { SvgVorfallLiability } from '../../../helpers/IconLoader';

const objectDetails = (formData, t, localePath) => {
  if (compareValues(formData[claimsKeys.thirdParty.ITEM_DAMAGE], NO_VALUE)) {
    return '';
  }

  if (isPersonDamageFlow(formData) && compareValues(formData[claimsKeys.thirdParty.OBJECTS_LENDING])) {
    return t(`${localePath}.damage-of-accident.objectDetails-person-flow`);
  }

  if (!isBusinessFlow(formData)) {
    return '';
  }

  const getTextByKey = (key) => (compareValues(formData[key], NO_VALUE)
    ? 'nicht ' : '');

  return t(`${localePath}.damage-of-accident.objectDetails-business-flow`,
    {
      borrowed: getTextByKey(claimsKeys.thirdParty.OBJECTS_LENDING),
      transported: getTextByKey(claimsKeys.thirdParty.DELIVER_DAMAGED_ITEM),
      manufactured: getTextByKey(claimsKeys.thirdParty.ITEM_DELIVERED),
    });
};

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => (
  <>
    { (isAnimalDamageFlow(formData) || isPersonDamageFlow(formData) || isBusinessFlow(formData)) && (
      <Details
        iconLeft={SvgVorfallLiability}
        summary={t(`${localePath}.damage-of-accident.title`)}
        className={className}
      >
        {t(`${localePath}.damage-of-accident.content`, {
          ...getParticularDataIfExist(
            localePath,
            'damage-of-accident.personal-injuries',
            'personalInjuries',
            formData[claimsKeys.thirdParty.PERSONAL_INJURIES],
          ),
          ...getParticularDataIfExist(
            localePath,
            'damage-of-accident.item-damage',
            'itemDamage',
            formData[claimsKeys.thirdParty.ITEM_INJURIES],
          ),
          ...getParticularDataIfExist(
            localePath,
            'damage-of-accident.animal-injuries',
            'animalInjuries',
            formData[claimsKeys.thirdParty.ANIMAL_INJURIES],
          ),
          vehicleDamage: compareValues(formData[claimsKeys.thirdParty.VEHICLE_DAMAGE])
            ? t(`${localePath}.damage-of-accident.vehicle-damage`, {
              vehicleType: formData[claimsKeys.thirdParty.VEHICLE_TYPE],
              licenseNumber: formData[claimsKeys.thirdParty.LICENSE_NUMBER],
              carMap: getCarMapInfo(formData, userTypes.THIRD_PARTY),
              ...getParticularDataIfExist(
                localePath,
                'damage-of-accident.describe-damage',
                'describeDamage',
                formData[claimsKeys.thirdParty.DESCRIBE_DAMAGE],
              ),
            })
            : '',
          settlement: t(`${localePath}.damage-of-accident.settlement`, {
            settlementNo: !compareValues(formData[claimsKeys.SETTLEMENT])
              ? t(`${localePath}.damage-of-accident.settlement-no`)
              : '',
          }),
          objectDetails: objectDetails(formData, t, localePath),
        })}
      </Details>
    )}
  </>
);

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
