import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getContactData } from '../../../helpers/formData/legalProtectionFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ContactDetails = ({ formData, localePath, className }, { t }) => {
  const data = getContactData(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={t(`${localePath}.contact.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact.content-name`, {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact.content-phone`, {
          phone: data.phone,
        })}
      </p>
      <p>
        {t(`${localePath}.contact.content-email`, {
          email: data.email,
        })}
      </p>
      <p>
        {t(`${localePath}.contact.content-insurance-number`, {
          insuranceNumber: data.insuranceNumber,
        })}
      </p>
    </Details>
  );
};

ContactDetails.propTypes = legalConsentProps.sectionProps;
ContactDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetails;
