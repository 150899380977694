import claimCommon from './claimCommon';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData, getPageType, getWhatHappened } from '../../../helpers/formData/assetLiabilityFormData';

const assetLiabilityTransform = formData => {
  const claimData = {
    ...claimCommon,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data: { ...data, ...getPageType(), ...getWhatHappened() },
    serviceType: moduleNames.ASSET_LIABILITY,
    ...additionalData,
  };
};

export default assetLiabilityTransform;
