import { combineReducers } from 'redux';
import formData from './formData/formData';
import visibilityFilter from './visibilityFilter/visibilityFilter';
import navigation from './navigation/navigation';
import workshops from './workshops/workshops';
import loader from './loader/loader';

export default combineReducers({
  formData,
  visibilityFilter,
  navigation,
  workshops,
  loader,
});
