import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDokument } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getCarMapDamages, getVehicleDamageData } from '../../../helpers/formData/vehicleFormData';

const VehicleDamageDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isTheftFlow,
    licenseNumber,
    damageDescription,
    isRepaired,
    isThirdPartyPrivateVehicle,
    isPrivateVehicle,
  } = getVehicleDamageData(formData);
  const carDamages = getCarMapDamages(formData);

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.company-car`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.company-car`)
      : t(`${localePath}.incident.private-car`);
  };


  const getContent = () => (isLiabilityFlow ? `${localePath}.vehicle-damage.content-liability` : `${localePath}.vehicle-damage.content`);

  return (
    <>
      {!isLiabilityFlow && !isTheftFlow && (
        <Details
          iconLeft={SvgDokument}
          summary={
            isLiabilityFlow
              ? t(`${localePath}.vehicle-damage.liability-title`)
              : t(`${localePath}.vehicle-damage.title`)
          }
          className={className}
        >
          {t(getContent(), {
            licenseNumber,
            privateCompanyCar: getPrivateCompanyCarText(),
            carDamages,
          })}
          {damageDescription && (
            t(`${localePath}.vehicle-damage.describe-damage`, { damageDescription })
          )}
          {isRepaired && (
            t(`${localePath}.vehicle-damage.is-repaired`)
          )}
        </Details>
      )}
    </>
  );
};

VehicleDamageDetails.propTypes = legalConsentProps.sectionProps;
VehicleDamageDetails.defaultProps = legalConsentProps.sectionDefaults;

VehicleDamageDetails.contextTypes = {
  t: PropTypes.func,
};

export default VehicleDamageDetails;
