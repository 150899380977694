import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './Checklist.styles.jss';
import * as IconLoader from '../../helpers/IconLoader';

const NumberedCircleIconName = 'NumberedCircle';

const Checklist = ({ columns, classes, headingText }) => {
  if (!columns.length) {
    return null;
  }

  const generateColumn = (column) => (
    column.map(({ icon, label }, index) => {
      let Icon;
      const isNumberedCircle = icon === NumberedCircleIconName;
      if (isNumberedCircle) {
        Icon = <span className={classes.listItemNumberedCircle}>{index + 1}</span>;
      } else {
        const BasicIcon = IconLoader[icon];
        Icon = <BasicIcon className={classes.listItemIcon} />;
      }

      return (
        <div
          key={label}
          className={classes.listItem}
          style={isNumberedCircle ? { paddingBottom: '24px' } : undefined}
        >
          {Icon}
          <span className={classes.listItemText}>{label}</span>
        </div>
      );
    })
  );

  return (
    <div className={classes.container}>
      <p className={classes.heading}>
        {headingText}
      </p>
      {generateColumn(columns[0])}
    </div>
  );
};

Checklist.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.array),
  headingText: PropTypes.string,
};

Checklist.defaultProps = {
  columns: [],
  classes: {},
  headingText: '',
};

export { Checklist as ChecklistPlain };
export default injectSheet(styles)(Checklist);
