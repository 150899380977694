const styles = theme => ({
  image: {
    objectFit: 'contain',
    width: '100%',
    maxHeight: '300px',
  },
  infoPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      minHeight: '200px',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      fontSize: '12px',
    },
  },
  message: {
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      fontSize: '12px',
    },
  },
  button: {
    width: '44%',
  },
});

export default styles;
