import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getYourMessageDetails } from '../../../helpers/formData/propertyFormData';
import { ManBubbleIcon } from '../../../helpers/IconLoader';

const YourMessageDetails = ({ formData, localePath, className }, { t }) => {
  const data = getYourMessageDetails(formData);
  if (!data) return false;

  return (
    <Details
      iconLeft={ManBubbleIcon}
      isInitiallyOpen
      summary={t(`${localePath}.message.title`)}
      className={className}
    >
      <p>{data}</p>
    </Details>
  );
};

YourMessageDetails.propTypes = legalConsentProps.sectionProps;
YourMessageDetails.defaultProps = legalConsentProps.sectionDefaults;

YourMessageDetails.contextTypes = {
  t: PropTypes.func,
};

export default YourMessageDetails;
