import React from 'react';
import PropTypes from 'prop-types';
import MarkerIcon from '@eg/elements/Icons/LocationIcon';
import { Card } from '@eg/elements/Card';
import injectSheet from 'react-jss';
import CloseIcon from '@eg/elements/Icons/CloseIcon';
import { colors } from '../../../styles/theme.jss';
import styles from './styles.jss';

const Marker = ({
  workshop,
  isSelected,
  selectedWorkshopId,
  onCloseClick,
  handleOnMarkerClick,
  classes,
}) => (
  <div className={classes.markerWrapper}>
    {
      selectedWorkshopId === workshop.repairerid && (
      <Card
        padding="small"
        className={`${classes.infoBox} ${isSelected ? classes.selectedInfoBox : ''}`}
      >
        <section className={classes.cardHeader}>
          <div className={classes.distance}>
            {`${workshop.distance} km`}
          </div>
          <button
            type="button"
            onClick={onCloseClick}
            className={classes.closeButton}
          >
            <CloseIcon fontSize={14} />
          </button>
        </section>
        <section className={classes.cardName}>
          {workshop.name}
        </section>
        <section className={classes.cardFullAddress}>
          <div className={classes.cardAddress}>
            {`${workshop.address},`}
          </div>
          <div>
            {`${workshop.postalcode} ${workshop.city}`}
          </div>
        </section>
      </Card>
      )}
    <div>
      <MarkerIcon
        onClick={() => handleOnMarkerClick(workshop.repairerid)}
        className={classes.marker}
        fontSize={50}
        width={50}
        height={50}
        color={isSelected ? colors.markerGreyIcon : colors.themePrimary}
      />
    </div>
  </div>
);

Marker.defaultProps = {
  workshop: {},
  selectedWorkshopId: null,
  classes: {},
  isSelected: false,
};

Marker.propTypes = {
  workshop: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
    repairerid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    postalcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }),
  isSelected: PropTypes.bool,
  handleOnMarkerClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  selectedWorkshopId: PropTypes.string,
};

export default injectSheet(styles)(Marker);
