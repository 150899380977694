import {
  getDateValueProxy,
  getOneFromMultiProxy,
  getYesNoValueProxy,
  POLICYHOLDER,
  getYesNoValue,
  padPolicyNumber,
  getSalutationValue, getPhoneValue, getEmailValue, getCountryCode, getUploadedFilesProxy, getUploadValues,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  accusationsAreCorrect, causerOfDamage,
  jobDescriptions,
  ownOrThirdParty,
} from '../../../helpers/formData/assetLiabilityFormData';
import {
  getTypeOfAccusationProxy,
} from './claimData.helpers';

export default {
  'claimant-role': [claimsKeys.WHO_FILLS, getYesNoValueProxy(POLICYHOLDER)],
  'job-description': [
    claimsKeys.assetLiability.JOB_DESCRIPTION,
    getOneFromMultiProxy({
      lawyer: jobDescriptions.LAWYER,
      'tax-advisor': jobDescriptions.TAX,
      'investment-agent': jobDescriptions.INVESTMENT,
      'property-manager': jobDescriptions.PROPERTY,
      association: jobDescriptions.ASSOCIATION,
      'it-software': jobDescriptions.SOFTWARE,
      other: jobDescriptions.OTHER,
    }),
  ],
  'own-or-third-party-damage': [
    claimsKeys.assetLiability.OWN_OR_THIRD_PARTY,
    getOneFromMultiProxy({
      own: ownOrThirdParty.OWN,
      'third-party': ownOrThirdParty.THIRD_PARTY,
    }),
  ],
  'details-of-accusation-made': claimsKeys.FURTHER_INFORMATION,
  'your-message': claimsKeys.YOUR_MESSAGE,
  'breach-of-duty-date': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'dunning-notice-received': [claimsKeys.assetLiability.DUNNING_NOTICE, getYesNoValue],
  'dunning-notice-date': {
    date: [claimsKeys.assetLiability.DUNNING_NOTICE_DATE, getDateValueProxy],
  },
  'asserted-in-writing': [claimsKeys.assetLiability.ASSERTED_IN_WRITING, getYesNoValue],
  'amount-of-damage': claimsKeys.assetLiability.AMOUNT_OF_DAMAGE,
  'accusation-is-applicable': [
    claimsKeys.assetLiability.ACCUSATIONS_ARE_CORRECT,
    getOneFromMultiProxy({
      yes: accusationsAreCorrect.YES,
      no: accusationsAreCorrect.NO,
      partly: accusationsAreCorrect.PARTLY,
      'not-specified': accusationsAreCorrect.NOT_SPECIFIED,
    }),
  ],
  'statement-on-accusations': claimsKeys.assetLiability.STATEMENT_ON_ACCUSATION,
  'id-policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'contact-data': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: claimsKeys.owner.FIRST_NAME,
    lastName: claimsKeys.owner.LAST_NAME,
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
  },
  'contact-data-damaged-party': {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: claimsKeys.thirdParty.FIRST_NAME,
    lastName: claimsKeys.thirdParty.LAST_NAME,
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
  },
  'damaged-party-location': {
    address: claimsKeys.thirdParty.ADDRESS,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY_CODE, getCountryCode],
  },
  'who-did-breach-of-duty': [
    claimsKeys.CAUSER_OF_DAMAGE,
    getOneFromMultiProxy({
      policyholder: causerOfDamage.POLICYHOLDER,
      'third-party': causerOfDamage.THIRD_PARTY,
      'not-specified': causerOfDamage.NOT_SPECIFIED,
    }),
  ],
  'statement-on-accusations-can-be-provided': [claimsKeys.assetLiability.STATEMENT_ON_ACCUSATION_KZ, getYesNoValue],
  'contact-data-broker': {
    who: [claimsKeys.customer.SALUTATION, getSalutationValue],
    firstName: claimsKeys.customer.FIRST_NAME,
    lastName: claimsKeys.customer.LAST_NAME,
    phoneNumber: [claimsKeys.customer.PHONE, getPhoneValue],
    email: [claimsKeys.customer.EMAIL, getEmailValue],
  },
  'has-files-with-opinion': [claimsKeys.HAS_FILES, getYesNoValue],
  'has-files-with-compensation-claimed': [claimsKeys.HAS_FILES, getYesNoValue],
  'has-files-with-dunning-notice': [claimsKeys.HAS_FILES, getYesNoValue],
  'has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  files: [claimsKeys.FILES, getUploadedFilesProxy],
  'type-of-accusation_own-damage': [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-different': [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-lawyer': [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-tax-advisor':
    [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-investment-agent':
    [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-association':
    [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-it-software':
    [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'type-of-accusation_third-party-property-manager':
    [claimsKeys.CIRCUMSTANCES, getTypeOfAccusationProxy],
  'what-documents_investment-manager': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_lawyer': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_tax-advisor': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_property-manager': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_association': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_it-software': [claimsKeys.TYPE_FILES, getUploadValues],
  'what-documents_other': [claimsKeys.TYPE_FILES, getUploadValues],
};
