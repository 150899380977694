import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import StarRating from '@eg/elements/StarRating';
import styles from './Rating.styles.jss';

const Rating = ({ classes, localePath }, { t }) => (
  <div className={classes.container}>
    <h4 className={`${classes.heading}`}>{t(`${localePath}.rating.heading`)}</h4>
    <div className={classes.starsContainer}>
      <StarRating value={4.78} className={classes.stars} />
      <span className={classes.starsLabel}>{t(`${localePath}.rating.starsLabel`)}</span>
    </div>
    <p className={classes.description}>
      {t(`${localePath}.rating.description_part_one`)}
      <b>
        {t(`${localePath}.rating.description_part_two`)}
      </b>
    </p>
  </div>
);

Rating.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
};

Rating.defaultProps = {
  classes: {},
  localePath: '',
};

Rating.contextTypes = {
  t: PropTypes.func,
};

export default injectSheet(styles)(Rating);
