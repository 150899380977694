import { mediaQuery } from '../../styles/helpers';

const styles = theme => ({
  container: {
    textAlign: 'left',
    width: '100%',
  },
  listItem: {
    display: 'flex',
  },
  listItemIcon: {
    flexShrink: '0',
    marginRight: 8,
    ...mediaQuery.ie11({
      maxWidth: '20px',
    }),
  },
  listItemNumberedCircle: {
    flexShrink: '0',
    marginRight: 8,
    border: `1px solid ${theme.colors.themePrimary}`,
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.colors.themePrimary,
  },
  listItemText: {
    flexShrink: '1',
    color: theme.colors.themeDarkestGray,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 8,
  },
  heading: {
    marginBottom: 16,
    fontSize: '16px',
    fontWeight: 'bold',
    [`@media (max-width: ${theme.breakpoints.breakpointM})`]: {
      marginTop: 16,
    },
  },
});
export default styles;
