import PropTypes from 'prop-types';
import { SectionResource } from '../section';

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  currentPath: PropTypes.string,
  sections: PropTypes.arrayOf(SectionResource),
  onSectionClick: PropTypes.func,
};

const defaults = {
  classes: {},
  currentPath: '',
  sections: [],
  onSectionClick: () => {},
};

const contexts = {
  t: PropTypes.func,
};

export default { props, defaults, contexts };
