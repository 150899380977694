import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import injectJss from 'react-jss';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import paths from '../../helpers/paths';
import styles from './styles.jss';

const renderHeader = (classes) => () => (
  <ReactCSSTransitionGroup
    transitionName={{
      enter: classes.headerEnter,
      enterActive: classes.headerEnterActive,
      leave: classes.headerLeave,
      leaveActive: classes.headerLeaveActive,
    }}
    transitionEnterTimeout={500}
    transitionLeaveTimeout={500}
  />
);

const Layout = ({ children, classes }) => (
  <div className={classes.layout}>
    <Switch>
      <Route exact path={paths.HOME} render={renderHeader(classes)} />
      <Route path={paths.CLAIM_END} render={renderHeader(classes)} />
      <Route path={paths.CLAIM} render={renderHeader(classes)} />
    </Switch>
    {children}
  </div>
);

Layout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  classes: {},
};

export default injectJss(styles)(Layout);
