import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentDetails } from '../../../helpers/formData/accidentFormData';
import { SvgPflaster } from '../../../helpers/IconLoader';

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    date,
    firstElement,
    secondElement,
    event,
    location,
    country,
    description,
    alcohol,
    department,
    caseNumber,
    hasPolice,
    isTrafficFlow,
    icdCodes,
  } = getIncidentDetails(formData);

  const icdCodesList = icdCodes.filter(Boolean).join(', ');

  return (
    <Details
      iconLeft={SvgPflaster}
      summary={t(`${localePath}.incident.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.incident.content-date`, { date })}
      </p>
      <p>
        {t(`${localePath}.incident.content-incident`, { firstElement, secondElement })}
      </p>
      <p>
        {t(`${localePath}.incident.event-of-accident`, { event })}
      </p>
      <p>
        {t(`${localePath}.incident.content-place`, { location, country })}
      </p>
      {icdCodesList && (
        <p>
          {t(`${localePath}.incident.icd-codes`, { description, icdCodesList })}
        </p>
      )}
      {description && (
        <p>
          {t(`${localePath}.incident.content-desc`, { description })}
        </p>
      )}
      {isTrafficFlow && hasPolice && (
        <p>
          {t(`${localePath}.incident.content-police`, { department, caseNumber })}
        </p>
      )}
      {isTrafficFlow && (
        <p>
          {t(`${localePath}.incident.content-alcohol`, { alcohol })}
        </p>
      )}
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
