import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgPolizeiUndZeugen } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPoliceDetails } from '../../../helpers/formData/propertyFormData';

const PoliceDetails = ({ formData, localePath, className }, { t }) => {
  const {
    police,
    department,
    caseNumber,
    stolenGoods,
    hasStolenGoods,
  } = getPoliceDetails(formData);
  if (!police) return false;

  return (
    <Details
      iconLeft={SvgPolizeiUndZeugen}
      summary={t(`${localePath}.police.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.police.content-report-${police}`)}
      </p>
      { department && (
        <p>
          {t(`${localePath}.police.content-department`, { department })}
        </p>
      )}
      { caseNumber && (
        <p>
          {t(`${localePath}.police.content-case-number`, { caseNumber })}
        </p>
      )}
      { hasStolenGoods && (
        <p>
          {t(`${localePath}.police.content-stolen-items-${stolenGoods}`)}
        </p>
      )}
    </Details>
  );
};

PoliceDetails.propTypes = legalConsentProps.sectionProps;
PoliceDetails.defaultProps = legalConsentProps.sectionDefaults;

PoliceDetails.contextTypes = {
  t: PropTypes.func,
};

export default PoliceDetails;
