import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import DatePickerInput from '../DatePickerInput';
import TimeInput from '../TimeInput';
import { accidentDateSelector } from '../../helpers/selectors';
import Card from './Card';

const DateTimeWithInfoBoxAndIcon = (props, { t }) => {
  const {
    hideTime,
    localePath,
    getValidationProps,
    accidentDate,
    afterAccidentDate,
    shrinkOnMobile,
  } = props;

  const [date, setDate] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!afterAccidentDate) return;
    if (date === undefined) return;
    if (moment(date).isBefore(accidentDate, 'day')) {
      const { onChange, name } = getValidationProps('date');
      onChange(name)('');
      setErrorMessage('validation-rules.invalid-date-before-accident');
      return;
    }
    setErrorMessage('');
  }, [date]);

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <Card localePath={localePath} />
        <div className="esc_col esc_col-12 esc_col-s-4">
          <DatePickerInput
            description={t(`${localePath}.labels.dateDescription`)}
            type="text"
            placeholder={t(`${localePath}.labels.dateLabel`)}
            {...getValidationProps('date')}
            {...(errorMessage !== '' && { error: errorMessage })}
            onChangeOptional={setDate}
            shrinkOnMobile={shrinkOnMobile}
          />
        </div>
        {!hideTime && (
          <>
            <TimeInput
              description={t(`${localePath}.labels.time-hh-description`)}
              placeholder={t(`${localePath}.labels.time-hh`)}
              maxValue={23}
              minValue={0}
              {...getValidationProps('hour')}
            />
            <TimeInput
              description={t(`${localePath}.labels.time-mm-description`)}
              placeholder={t(`${localePath}.labels.time-mm`)}
              maxValue={59}
              minValue={0}
              {...getValidationProps('min')}
            />
          </>
        )}
      </div>
    </div>
  );
};

DateTimeWithInfoBoxAndIcon.propTypes = {
  localePath: PropTypes.string,
  hideTime: PropTypes.bool,
  getValidationProps: PropTypes.func,
  accidentDate: PropTypes.objectOf(Date),
  afterAccidentDate: PropTypes.bool,
  shrinkOnMobile: PropTypes.bool,
};

DateTimeWithInfoBoxAndIcon.defaultProps = {
  localePath: '',
  hideTime: true,
  getValidationProps: () => {},
  accidentDate: undefined,
  afterAccidentDate: false,
  shrinkOnMobile: false,
};

DateTimeWithInfoBoxAndIcon.contextTypes = {
  t: PropTypes.func,
};

export { DateTimeWithInfoBoxAndIcon as DateTimeWithInfoBoxAndIconPlain };
const mapStateToProps = ({ formData }) => ({ accidentDate: accidentDateSelector(formData) });

export default compose(
  connect(
    mapStateToProps,
  ),
  withMultiFieldValidation,
)(DateTimeWithInfoBoxAndIcon);
