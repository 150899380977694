import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

class CarArea extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      hovered: true,
    };

    this.mouseLeave = this.mouseLeave.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  mouseLeave() {
    this.setState({ hovered: true });
  }

  toggle() {
    this.setState({ hovered: false });
    const { onClick } = this.props;
    onClick();
  }

  render() {
    const {
      localePath, classes, name, option, selected, tabIndex,
    } = this.props;

    const { hovered } = this.state;

    const { t } = this.context;

    const areaClasses = [classes.area, selected && classes.areaSelected, hovered && classes.areaHovered].join(' ');

    return (
      <button
        className={areaClasses}
        onClick={this.toggle}
        name={name}
        tabIndex={tabIndex}
        onMouseLeave={this.mouseLeave}
        type="button"
      >
        {t(`${localePath}.options.${option}`)}
      </button>
    );
  }
}

CarArea.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  name: PropTypes.string,
  option: PropTypes.string,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
};

CarArea.defaultProps = {
  classes: {},
  localePath: '',
  name: '',
  option: '',
  selected: true,
  tabIndex: 0,
  onClick: () => {},
};

CarArea.contextTypes = {
  t: PropTypes.func,
};

export { CarArea as CarAreaPlain };
export default injectSheet(styles)(CarArea);
