import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import {
  claimsKeys,
  compareValues,
  getParticularDataIfExist,
} from '../../../helpers/formData/formData';
import { isKeyLossFlow } from '../../../helpers/formData/privateLiabilityFormData';

import { SvgPolizeiUndZeugen } from '../../../helpers/IconLoader';

const PoliceDetails = ({ formData, localePath, className }, { t }) => (
  <>
    {isKeyLossFlow(formData) && compareValues(formData[claimsKeys.POLICE]) && (
      <Details
        iconLeft={SvgPolizeiUndZeugen}
        summary={t(`${localePath}.police.title`)}
        className={className}
      >
        {t(`${localePath}.police.content`, {
          department: formData[claimsKeys.DEPARTMENT],
          ...getParticularDataIfExist(
            localePath,
            'police.case-number',
            'caseNumber',
            formData[claimsKeys.CASE_NUMBER],
          ),
        })}
      </Details>
    )}
  </>
);

PoliceDetails.propTypes = legalConsentProps.sectionProps;
PoliceDetails.defaultProps = legalConsentProps.sectionDefaults;

PoliceDetails.contextTypes = {
  t: PropTypes.func,
};

export default PoliceDetails;
