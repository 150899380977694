import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';

import DatePicker from '@eg/elements/DatePicker';

import FormMessage from '../../components/FormMessage';

import styles from './styles.jss';

import { convertToString, convertBack } from './convertFunctions';
import { INVALID_DATE } from '../../services/claimData/claimData.helpers';

const isIPhone = navigator.platform === 'iPhone';

const DatePickerInput = props => {
  const {
    error,
    description,
    placeholder,
    value,
    onChange,
    name,
    classes,
    disabled,
    style,
    onChangeOptional,
    resetKey,
    shrinkOnMobile,
  } = props;

  const [calendar, setCalendar] = useState(
    (value && value !== INVALID_DATE) ? convertBack(value) : '',
  );

  useEffect(() => {
    if (resetKey !== 0) {
      setCalendar('');
    }
  }, [resetKey]);

  const setNewDate = (e, newDate) => {
    const formatted = convertToString(newDate);
    setCalendar(newDate);
    onChange(name)(formatted);
    if (onChangeOptional) onChangeOptional(newDate);
  };
  const datepickerStyle = Object.keys(style).length ? style : { width: '100%', marginTop: isIPhone && 200 };
  const containerClassNames = shrinkOnMobile ? [classes.overrideIconStyle, classes.mobileContainer].join(' ')
    : [classes.overrideIconStyle];

  const hideKeyboard = () => {
    document.activeElement.blur();
  };

  return (
    <div className={containerClassNames}>
      <DatePicker
        maxDate={new Date()}
        placeholder={placeholder}
        onChange={setNewDate}
        valueDate={calendar}
        style={datepickerStyle}
        error={!!error}
        disabled={disabled}
        onClick={hideKeyboard}
        onFocus="blur();"
      />
      <FormMessage
        description={description}
        error={error}
      />
    </div>
  );
};

DatePickerInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.objectOf(Object),
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(Object),
  onChangeOptional: PropTypes.func,
  resetKey: PropTypes.number,
  shrinkOnMobile: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  name: 'button',
  onChange: () => {},
  value: '',
  error: '',
  description: '',
  placeholder: '',
  classes: {},
  disabled: false,
  style: {},
  onChangeOptional: null,
  resetKey: 0,
  shrinkOnMobile: false,
};

export { DatePickerInput as DatePickerInputPlain };
export default injectJss(styles)(DatePickerInput);
