import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const Location = props => <ErgoDataForm {...props} formType={formTypes.LOCATION} />;

Location.propTypes = {
  localePath: PropTypes.string,
};

Location.defaultProps = {
  localePath: '',
};

export { Location as LocationPlain };
export default Location;
