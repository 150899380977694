import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  glassOptions,
  glassCircumstances,
} from '../../../helpers/formData/propertyFormData';
import { getGlassWhatDamagedValueProxy } from './claimData.helpers';

export default {
  'glass-breakage_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      break: glassOptions.BREAK,
      blindness: glassOptions.BLINDESS,
      different: glassOptions.OTHER,
    }),
  ],
  'glass-breakage_description': claimsKeys.property.CASE,
  'glass-breakage_circumstances': [
    claimsKeys.property.RESULT_STRUCTURE,
    getOneFromMultiProxy({
      wind: glassCircumstances.WIND,
      'personal-negligence': glassCircumstances.PERSONAL_NEGLIGENCE,
      'external-negligence': glassCircumstances.EXTERNAL_NEGLIGENCE,
      different: glassCircumstances.OTHER,
    }),
  ],
  'glass-breakage_what-damaged': [
    claimsKeys.property.CAUSE_NOTE,
    getGlassWhatDamagedValueProxy,
  ],
};
