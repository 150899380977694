import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const Footer = ({ classes }, { t }) => (
  <footer className={`esc_container esc_box esc_box--around ${classes.footer}`}>
    <div className="esc_grid">
      <div className={`esc_grid__wrapper ${classes.wrapper}`}>
        <div className={`esc_col esc_col-12 esc_col-s-7 ${classes.leftCol}`}>
          {t('footer.legal', {
            year: new Date().getFullYear(),
          })}
        </div>
        <div className={`esc_col esc_col-12 esc_col-s-5 ${classes.rightCol}`}>
          <a
            href="https://www.ergo.de/de/Service/Datenschutz"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {t('footer.privacy-policy')}
          </a>
          <span>|</span>
          <a
            href="https://www.ergo.de/de/Service/Anbieter"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {t('footer.imprint')}
          </a>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Footer.defaultProps = {
  classes: {},
};

Footer.contextTypes = {
  t: PropTypes.func,
};

export { Footer as FooterPlain };
export default injectSheet(styles)(Footer);
