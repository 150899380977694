import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import FormMessage from '../../components/FormMessage';

/**
 *
 * Date container responsible for fetching data and dispatching actions to make a change to application state.
 *
 * @param title
 * @param onChange
 * @param data
 * @param t {function} - Polyglot `t()` function retrieved via context API
 * @returns {ReactElement}
 * @constructor
 */
class ErgoMaskedText extends React.PureComponent {
  constructor() {
    super();
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  setSanitizedValue = value => {
    const {
      onChange,
      name,
    } = this.props;
    onChange(name)(value);
  }

  handleFocus(e) {
    const { onFocus } = this.props;
    onFocus(e);
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    onBlur(e);
  }

  handleChange(e) {
    const { onChange } = this.props;
    onChange(e);
  }

  render() {
    const {
      value,
      name,
      placeholder,
      mask,
      placeholderChar,
      formatCharacters,
      error,
      description,
      sanitizeValue,
    } = this.props;

    const inputValue = sanitizeValue ? value.split(placeholderChar).join('') : value;
    if (sanitizeValue) {
      this.setSanitizedValue(value);
    }

    return (
      <>
        <span className="ee_input-wrapper">
          <MaskedInput
            value={inputValue}
            className="ee_input"
            name={name}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            type="text"
            mask={mask}
            placeholder={placeholder}
            placeholderChar={placeholderChar}
            formatCharacters={formatCharacters}
          />
        </span>
        <FormMessage error={error} description={description} />
      </>
    );
  }
}

ErgoMaskedText.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderChar: PropTypes.string,
  formatCharacters: PropTypes.objectOf(PropTypes.object),
  mask: PropTypes.string.isRequired,
  error: PropTypes.string,
  description: PropTypes.string,
  sanitizeValue: PropTypes.bool,
};

ErgoMaskedText.defaultProps = {
  name: 'button',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: '',
  placeholder: '',
  error: '',
  description: '',
  placeholderChar: ' ',
  formatCharacters: {},
  sanitizeValue: false,
};

export { ErgoMaskedText as ErgoMaskedTextPlain };
export default ErgoMaskedText;
