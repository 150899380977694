import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getClaimantData } from '../../../helpers/formData/accidentFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ClaimantContact = ({ formData, localePath, className }, { t }) => {
  const data = getClaimantData(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      summary={t(`${localePath}.contact-of-claimant.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact-of-claimant.content-name`, {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-claimant.content-phone`, {
          phone: data.phone,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-claimant.content-email`, {
          email: data.email,
        })}
      </p>
    </Details>
  );
};

ClaimantContact.propTypes = legalConsentProps.sectionProps;
ClaimantContact.defaultProps = legalConsentProps.sectionDefaults;

ClaimantContact.contextTypes = {
  t: PropTypes.func,
};

export default ClaimantContact;
