import PropTypes from 'prop-types';

const optionTypes = {
  INVADE: 'invade',
  ENTRY: 'entry',
  DISREGARD: 'disregard',
  TURN: 'turn',
  MANEUVERING: 'maneuvering',
  OVERTAKING: 'overtaking',
  LANE_CHANGE: 'laneChange',
  DIFFERENT: 'different',
  LOST: 'lost',
  STOLEN: 'stolen',
  DRIVING: 'driving',
  TEAR_DOWN: 'tearDown',
  SPILLAGE_LIQUIDS: 'spillageLiquids',
  OPERATING_ERRORS: 'operatingErrors',
  FIRE: 'fire',
  DRIVE_UP: 'driveUp',
  PARKING: 'parking',
  BENDING: 'bending',
  SHUNTING: 'shunting',
};

const dataType = {
  CIRCUMSTANCE: 'circumstance',
  DESCRIPTION: 'describeCircumstance',
};

const CircumstanceResource = PropTypes.shape({
  circumstance: PropTypes.string,
  describeCircumstance: PropTypes.string,
});

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOf(Object.values(optionTypes)),
  })),
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  name: PropTypes.string,
  data: CircumstanceResource,
  yesNoQuestion: PropTypes.bool,
  onChange: PropTypes.func,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

const defaults = {
  classes: {},
  options: [],
  localePath: '',
  pathName: '',
  name: '',
  data: {
    circumstance: '',
    describeCircumstance: '',
  },
  yesNoQuestion: false,
  onChange: () => {},
  updateField: () => {},
  getValidationProps: () => {},
};

export default {
  optionTypes,
  dataType,
  props,
  defaults,
  CircumstanceResource,
};
