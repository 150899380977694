import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDokument } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getVehicleDamageDetails, getCarMapInfo } from '../../../helpers/formData/collisionAbroadFormData';

const VehicleDamageDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isVehicleDamage,
    licenseNumber,
    isPkwVehicleType,
    describeDamage,
    isRepaired,
    isThirdPartyPrivateVehicle,
    isPrivateVehicle,
  } = getVehicleDamageDetails(formData);
  if (!isVehicleDamage) return false;

  let carMapInfo;
  if (isPkwVehicleType) {
    carMapInfo = getCarMapInfo(formData);
  }

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.private-vehicle-n`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.private-vehicle-j`)
      : t(`${localePath}.incident.private-vehicle-n`);
  };

  return (
    <Details
      iconLeft={SvgDokument}
      summary={t(`${localePath}.vehicle-damage.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.vehicle-damage.content`, {
          licenseNumber,
          privateCompanyCar: getPrivateCompanyCarText(),
          describeDamage: describeDamage
            ? t(`${localePath}.vehicle-damage.describe-damage`, { describeDamage }) : '',
          carDamage: isPkwVehicleType ? t(`${localePath}.vehicle-damage.car-damage`, { carMapInfo }) : '',
          isRepaired: isRepaired ? t(`${localePath}.vehicle-damage.is-repaired`) : '',
        })}
      </p>
    </Details>
  );
};

VehicleDamageDetails.propTypes = legalConsentProps.sectionProps;
VehicleDamageDetails.defaultProps = legalConsentProps.sectionDefaults;

VehicleDamageDetails.contextTypes = {
  t: PropTypes.func,
};

export default VehicleDamageDetails;
