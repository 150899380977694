import moment from 'moment';
import { padStart } from 'lodash';
import { getFlowHistory } from '../../helpers/navigation';
import { getTranslationArray } from '../../helpers/polyglot';
import globals from '../../globals';

export const YES = 'yes';
export const NO = 'no';
export const MY_ACCOUNT = 'my-account';
export const CAR = 'car';
export const POLICYHOLDER = 'policyholder';
export const PARTY = 'party';
export const INVALID_DATE = '00.00.0000';

export const uploadDocumentMap = {
  photo: 6,
  'photo-upload': 681,
  'invoice-other': 934,
  'invoice-vehicle-glass-breakage': 168,
  'invoice-vehicle-repair': 389,
  'vehicle-appraisal': 388,
  'estimate-vehicle': 390,
  'estimate-other': 380,
  'cost-assumption-declaration': 938,
  'official-documents': 173,
  'layer-mail': 813,
  'expert-opinion': 378,
  'emergency-protocol': 171,
  'diagnostic-documents': 171,
  'work-certificate': 171,
  'operation-report': 171,
  'discharge-report': 170,
  'discharge-report-upload': 171,
  'accident-expert-opinion': 172,
  'hospital-certification': 263,
  'invoice-mountain-rescue': 379,
  'invoice-property-upload': 934,
  'list-of-damaged-and-stolen-items': 175,
  'other-correspondence': 175,
  'cost-note': 674,
  'cost-note-upload': 685,
  'fixing-of-cost': 673,
  'fixing-of-cost-upload': 686,
  'legal-expenses-accounting': 673,
  'legal-expenses-accounting-upload': 686,
  'fines-notice': 672,
  'fines-notice-upload': 681,
  'official-notice': 672,
  'official-notice-upload': 681,
  correspondence: 672,
  'correspondence-upload': 681,
  'legal-protection-expert-opinion': 672,
  'legal-protection-expert-opinion-upload': 681,
  'legal-protection-miscellaneous': 681,
  different: 9,
  miscellaneous: 175,
  'invoice-bike-accessoires': 934,
  'invoice-bike-parts': 934,
  'invoice-bike': 934,
  'bike-pass': 175,
  'regulatory-documents': 173,
  'relevant-correspondence': 175,
  'ducoments-on-specific-facts': 175,
  'evidence-of-damage': 934,
  'claimant-demand': 175,
  'registrars-letterhead': 175,
  'letter-of-claim': 175,
  'contractual-documents': 175,
  'evidence-of-default': 175,
  'evidence-of-amount-of-damage': 934,
  'tax-assessments': 175,
  appeals: 175,
  'correspondence-with-tax-office': 175,
  'correspondence-mandates': 175,
  'audit-report': 175,
  contract: 999,
  consultation: 175,
  other: 175,
  'insurance-application': 175,
  policy1: 175,
  policy2: 175,
  'risk-profile': 175,
  brochure: 175,
  'claim-letter': 175,
  'refusal-of-insurer': 175,
  'evidence-of-damage-insurer': 175,
  'purchase-contract': 175,
  'court-correspondence': 175,
  'agent-agreement': 175,
  weg: 175,
  expose: 175,
  'correspondence-seller': 175,
  'contract-award': 175,
  'cost-accounts': 934,
  notes: 175,
  'opinion-responsible-person': 175,
  'rejection-letter': 175,
  'articles-of-association': 175,
};

export const getFormattedTypeOfUploadedDocuments = data => {
  const filesType = data.split(';');
  const result = [];

  Object.keys(uploadDocumentMap).forEach(documentKey => {
    if (filesType.includes(uploadDocumentMap[documentKey].toString())) {
      result.push(documentKey);
    }
  });
  return result;
};

export const getUploadValues = whatDocuments => {
  const result = [];

  if (!whatDocuments) {
    return '';
  }

  Object.keys(uploadDocumentMap).forEach(documentKey => {
    if (whatDocuments.includes(documentKey)) {
      const documentValue = uploadDocumentMap[documentKey];
      if (!result.includes(documentValue)) {
        result.push(documentValue);
      }
    }
  });

  return result.join(';');
};

export const getUploadedFiles = formValue => {
  if (!formValue) {
    return [];
  }
  return formValue.map(file => file.fileReference);
};

export const getYesNoValue = (formValue, yesValue = YES) => (formValue === yesValue ? 'j' : 'n');

export const getYesNoValueUnknown = (formValue, yesValue = YES, noValue = NO) => (
  // eslint-disable-next-line no-nested-ternary
  formValue === yesValue ? 'ja'
    : formValue === noValue ? 'nein' : 'unbekannt'
);

export const getYesNoValueFromBool = formValue => (formValue ? 'j' : 'n');

export const getTrackerAgreement = formValue => ((formValue && formValue.agreement) ? '1' : '0');

export const stripPlaceholderChar = (str, plcStr) => str.split(plcStr).join('');

export const padPolicyNumber = policy => (
  `${policy.substring(0, 2)}${padStart(policy.substring(2, policy.length), 9, 0)}`
);

export const padClaimNumber = claimNumber => {
  const motorcycleRegex = /(KS)\d{3}[A-Z]{3}\d{2}-\d{1,4}/;
  const endsWithChar = /[a-z]$/gi.test(claimNumber);
  const claimNumberParts = claimNumber.split('-');
  const initialCharacters = claimNumberParts[0].substring(0, 2);

  const secondDigitsGroup = endsWithChar ? padStart(claimNumberParts[1], 5, 0) : padStart(claimNumberParts[1], 4, 0);
  const firstDigitsGroupGeneric = padStart(claimNumberParts[0].substring(2), 9, 0);
  const firstDigitsGroupForMotorcycle = claimNumberParts[0];

  if (motorcycleRegex.test(claimNumber)) {
    return `${firstDigitsGroupForMotorcycle}-${secondDigitsGroup}`;
  }

  return `${initialCharacters}${firstDigitsGroupGeneric}-${secondDigitsGroup}`;
};

export const podPolicyNumberValueProxy = field => formValue => (
  formValue && formValue[field] && padPolicyNumber(formValue[field])
);

export const getIbanValue = formValue => {
  if (!formValue || (!formValue.countryCode && !formValue.accountNumber)) {
    return undefined;
  }
  const { countryCode, accountNumber } = formValue;
  return `${countryCode ? countryCode.trim() : ''}${accountNumber ? accountNumber.trim() : ''}`;
};

export const getPhoneValue = formValue => {
  if (!formValue || !formValue.phoneNumber) {
    return undefined;
  }
  const { phoneNumber } = formValue;
  return `${phoneNumber ? phoneNumber.trim() : ''}`.trim();
};

export const getDateTimeValue = formValue => {
  if (!formValue) {
    return {};
  }
  const { date, time } = formValue;
  return {
    date: date ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : undefined,
    time: time ? moment(time, 'hh:mm').format('HHmm') : undefined,
  };
};

export const getDateValue = date => (!date ? undefined : moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));

export const getTimeValue = (hours, minutes) => (
  !hours && !minutes ? '' : moment(`${hours}:${minutes}`, 'hh:mm').format('HHmm')
);

export const getFormattedTimeValue = data => moment(data, 'HHmm').format('hh:mm');

export const getFormattedDateValue = date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

export const getCountryCode = formValue => {
  if (!formValue && !formValue.country) {
    return 'D';
  }
  const countryList = getTranslationArray('dropdowns.countries');
  return countryList.find(obj => obj.name === formValue.country).value;
};

export const getIdentityValue = formValue => {
  const identityList = getTranslationArray('dropdowns.identities');
  return identityList.find(obj => obj.name === formValue.identity).value;
};

export const stripSpacesFromIban = formValue => formValue.iban.replace(/\s+/g, '');

export const getLicenseNumber = formValue => {
  const licenseNumber = Object.values(formValue);
  licenseNumber[2] = licenseNumber[2].trim();
  return licenseNumber.join('-');
};

export const getSpecialLicenseNumber = ({
  part1, part2, part3, claimNumber,
}) => {
  if (part1 && part2 && part3 && !claimNumber) {
    return `${part1}-${part2}-${part3.trim()}`;
  }
  return undefined;
};

export const getCountryNameByCode = countryCode => {
  if (!countryCode) return '';
  const countryList = getTranslationArray('dropdowns.countries');
  return countryList.find(obj => obj.value === countryCode).name;
};

export const getIdentityCommentValue = (formValues) => (
  `Meldung erfolgt durch ${getIdentityValue(formValues)}:\n${formValues.description}`
);

export const getAddressValue = formValue => {
  if (!formValue) {
    return {};
  }
  const {
    street, city, postCode, propertyNumber, country,
  } = formValue;
  return {
    street: `${street || ''} ${propertyNumber || ''}`.trim(),
    postCode,
    country: country || 'Deutschland',
    city,
  };
};

export const getPolicyNumberValue = formValue => (formValue) || undefined;

export const getPolicyNumberProxy = value => padPolicyNumber(
  stripPlaceholderChar(getPolicyNumberValue(value), '_'),
);

export const getSalutationValue = formValue => {
  const labels = getTranslationArray('common.salutation');
  const salutation = labels[formValue.who];

  if (salutation === 'neutral (Divers)') {
    return 'Divers';
  }
  return salutation;
};

export const getVehicleTypeValue = key => {
  const labels = getTranslationArray('common.vehicle-type');
  return labels[key];
};

export const getCarMapValues = key => {
  const labels = getTranslationArray('common.car-map');
  return labels[key];
};

export const getEmailValue = formValue => (formValue && formValue.email) || undefined;

export const getFirstNameValue = formValue => (formValue && formValue.firstName) || undefined;

export const getLastNameValue = formValue => (formValue && formValue.lastName) || undefined;

export const getOneFromMulti = (formValue, options) => options[formValue];

export const getCountryValue = formValue => (formValue && formValue.country) || 'D';

export const getUploadedFilesProxy = formValue => getUploadedFiles(formValue);

export const getYesNoValueProxy = yesValue => formValue => getYesNoValue(formValue, yesValue);

export const getOneFromMultiProxy = options => formValue => getOneFromMulti(formValue, options);

export const getOneFromMultiProxyByKey = (key, options) => formValue => getOneFromMulti(formValue[key], options);

export const getOneCircumstanceFromMultiProxy = options => formValue => (
  getOneFromMulti(formValue.circumstance, options)
);

export const getDateTimeValueProxy = field => formValue => getDateTimeValue(formValue)[field];

export const getDateValueProxy = formValue => getDateValue(formValue.date);

export const getDateValueOrUndefined = date => (
  date !== INVALID_DATE ? getDateValue(date) : undefined
);

export const getDateValueByKey = field => formValue => {
  const date = formValue[field];
  return getDateValueOrUndefined(date);
};

export const getTimeValueProxy = formValue => getTimeValue(formValue.hour, formValue.min);

export const getAddressValueProxy = field => formValue => getAddressValue(formValue)[field];

export const arrayIncludesValue = (formValue, value) => (formValue || []).includes(value);

export const arrayIncludesValueProxy = value => formValue => getYesNoValue(arrayIncludesValue(formValue, value), true);

export const arrayIncludesAnyValueProxy = values => formValue => getYesNoValue(
  values.reduce((acc, val) => {
    if (acc) {
      return acc;
    }
    return arrayIncludesValue(formValue, val);
  }, false),
  true,
);

export const arrayIncludesKeyAndValueProxy = (key, value) => formValue => (
  getYesNoValue(arrayIncludesValue(formValue[key], value), true)
);

export const getDescribeCircumstance = formValue => {
  const { describeCircumstance } = formValue;
  return describeCircumstance !== ' ' ? describeCircumstance : undefined;
};

export const claimDataTransform = (definition, formData) => {
  const params = globals.window.location.pathname.split('/');
  const currentStep = params.pop();
  const pathName = params.pop();
  const flowHistory = getFlowHistory(currentStep, pathName, formData);
  const claimData = flowHistory.reduce((acc, cur) => {
    if (definition[cur] && formData[cur]) {
      if (typeof definition[cur] === 'string') {
        acc[definition[cur]] = formData[cur];
      } else if (definition[cur][0] === 'manual-values-from-function'
        && typeof definition[cur][1] === 'function' && Array.isArray(formData[cur])) {
        const mappedObject = definition[cur][1](formData[cur]);
        Object.keys(mappedObject).forEach((key) => {
          acc[key] = mappedObject[key];
        });
      } else if (definition[cur] instanceof Array) {
        const key = definition[cur][0];
        const value = typeof definition[cur][1] === 'function' ? definition[cur][1](formData[cur]) : definition[cur][1];
        acc[key] = value;
      } else {
        Object.keys(definition[cur]).forEach(vName => {
          const v = definition[cur][vName];
          if (v[1] && typeof v[1] === 'function') {
            acc[v[0]] = v[1](formData[cur]);
          } else if (typeof v === 'string') {
            acc[v] = formData[cur][vName];
          }
        });
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.log('missing mapper key', cur); // eslint-disable-line no-console
    }
    return acc;
  }, {});

  return claimData;
};

export const getConditionalData = (form, conditions) => {
  let reducedData = {};

  const realizedConditions = conditions.filter((el) => {
    if (el.conditionValue !== 'text_input') {
      return form[el.conditionTarget] === el.conditionValue;
    }
    return true;
  });

  realizedConditions.forEach(({ resultValue, resultTarget }) => {
    if (Array.isArray(resultValue)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of resultValue) {
        if (form[item]) {
          reducedData = {
            ...reducedData,
            [resultTarget]: form[item],
          };
          return;
        }
      }
    }
  });
  return reducedData;
};
