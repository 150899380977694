import React from 'react';
import PropTypes from 'prop-types';

import Select from '../Select/Select';

const SelectBool = (props) => (
  <Select {...props} yesNoQuestion />
);

SelectBool.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
};

SelectBool.defaultProps = {
  localePath: '',
  data: '',
  options: [],
  onChange: () => {},
  goForward: () => {},
};

export default SelectBool;
