const styles = (theme) => ({
  picker: {
    width: '100% !important',
  },
  inputContainer: {
    display: 'flex !important',
  },
  arrows: {
    marginTop: 7,
    marginBottom: 7,
    marginLeft: -34,
    zIndex: 10,
    backgroundColor: theme.colors.themeWhite,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    paddingTop: 4,
    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.15), inset 0 -1px 2px 0 rgba(0,0,0,0.5)',
  },
  iconUp: {
    fill: `${theme.colors.themeDarkerGray} !important`,
    cursor: 'pointer',
  },
  iconDown: {
    paddingTop: 5,
    fill: `${theme.colors.themeDarkerGray} !important`,
    cursor: 'pointer',
  },
});

export default styles;
