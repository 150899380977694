const styles = (theme) => ({
  wrapper: {
    margin: '0 !important',
  },
  card: {
    border: `1px solid ${theme.colors.themeLighterGray}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

export default styles;
