import {
  NO,
  getPhoneValue,
  getYesNoValue,
  getTrackerAgreement,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getDateValueProxy,
  getCountryCode,
  getSpecialLicenseNumber,
  stripSpacesFromIban,
  getSalutationValue,
} from '../claimData.helpers';
import { getDescribeDamageValueProxy } from './claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';

export default {
  'glass-damage_circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'glass-damage_circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  // ToDo: update mapping after create a new view. We don't have mapping keys for blocks.
  'glass-damage_circumstances_describe-damage': [claimsKeys.owner.DESCRIBE_DAMAGE, getDescribeDamageValueProxy],
  // describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
  // 'front-window': ['', X_CHANGE_THIS_TO_PROPER_FUNCTION_X],
  // 'back-window': ['', X_CHANGE_THIS_TO_PROPER_FUNCTION_X],
  // 'side-window': ['', X_CHANGE_THIS_TO_PROPER_FUNCTION_X],
  // 'headlights': ['', X_CHANGE_THIS_TO_PROPER_FUNCTION_X],
  // 'side-mirror': ['', X_CHANGE_THIS_TO_PROPER_FUNCTION_X],
  // },
  // !Special case after prefilling data
  'glass-damage_vehicle-id_license-number': {
    part1: [claimsKeys.owner.LICENSE_NUMBER, getSpecialLicenseNumber],
    claimNumber: claimsKeys.POLICY_NUMBER,
  },
  // ToDo: We need to add a key for checkbox data.
  'glass-damage_contact-data': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
    agreement: [claimsKeys.owner.TRACKER_AGREEMENT, getTrackerAgreement],
  },
  'glass-damage_repair_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'glass-damage_repair_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'glass-damage_repair_beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'glass-damage_repair_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'glass-damage_repair_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },

  'glass-damage_repair_beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
};
