import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getDamageOfAccidentDetails } from '../../../helpers/formData/collisionAbroadFormData';
import { userTypes } from '../../../helpers/formData/formData';
import { getCarMapDamages } from '../../../helpers/formData/vehicleFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isPersonalInjury,
    personalInjuryDesc,
    isPrivateVehicle,
    isThirdPartyPrivateVehicle,
    isItemDamage,
    itemDamageDesc,
    vehicleType,
    licenseNumber,
    country,
  } = getDamageOfAccidentDetails(formData);

  const getPrivateCompanyCarText = () => {
    if (isThirdPartyPrivateVehicle) {
      return t(`${localePath}.incident.private-vehicle-n`);
    }

    return isPrivateVehicle
      ? t(`${localePath}.incident.private-vehicle-j`)
      : t(`${localePath}.incident.private-vehicle-n`);
  };

  return (
    <Details
      iconLeft={SvgDerSchadenDesUnfallbeteiligten}
      summary={t(`${localePath}.damage-of-accident.title`)}
      className={className}
    >
      {t(`${localePath}.damage-of-accident.content`, {
        contentDamage: isPersonalInjury || isItemDamage
          ? t(`${localePath}.damage-of-accident.content-damage`, {
            personDamage: isPersonalInjury ? t(`${localePath}.damage-of-accident.person-damage`) : '',
            itemDamage: isItemDamage ? t(`${localePath}.damage-of-accident.item-damage`) : '',
            collectDamage: isPersonalInjury && isItemDamage ? t(`${localePath}.damage-of-accident.collect-damage`) : '',
          }) : '',
        personalInjury: isPersonalInjury
          ? t(`${localePath}.damage-of-accident.personal-injury-desc`, { personalInjuryDesc }) : '',
        itemDamage: isItemDamage
          ? t(`${localePath}.damage-of-accident.item-damage-desc`, { itemDamageDesc }) : '',
        carDamage: vehicleType
          ? t(`${localePath}.damage-of-accident.content-vehicle`, {
            licenseNumber,
            carDamages: getCarMapDamages(formData, userTypes.THIRD_PARTY),
            vehicleType,
            liabilityVehicleType: getPrivateCompanyCarText(),
          })
          : '',
        carLicenseNumber: t(`${localePath}.damage-of-accident.car-license-number`, { licenseNumber, country }),
      })}
    </Details>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
