import {
  getCountryCode,
  getYesNoValue,
  getDateValueProxy,
  getDateValueByKey,
  getDateValueOrUndefined,
  getUploadValues,
  getUploadedFilesProxy,
  padPolicyNumber,
  getEmailValue,
  getSalutationValue,
  getPhoneValue,
  getOneFromMultiProxy,
  getOneFromMultiProxyByKey,
  stripSpacesFromIban,
  getYesNoValueUnknown,

} from '../claimData.helpers';
import { claimsKeys, getIcdCode } from '../../../helpers/formData/formData';
import {
  userFlows,
  locationTypes,
  accidentSituations,
  sportEventTypes,
  otherEventTypes,
} from '../../../helpers/formData/accidentFormData';

export default {
  'type-of-accident': [
    claimsKeys.accident.TYPE,
    getOneFromMultiProxy({
      household: userFlows.HOUSEHOLD,
      occupational: userFlows.OCCUPATIONAL,
      onTheWay: userFlows.ON_THE_WAY,
      other: userFlows.OTHER,
    }),
  ],
  'event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'event-circumstances_location': {
    type: [
      claimsKeys.accident.LOCATION_TYPE,
      getOneFromMultiProxyByKey('type', {
        inner: locationTypes.INNER,
        outer: locationTypes.OUTER,
      }),
    ],
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
    location: claimsKeys.LOCATION,
  },
  situation: [
    claimsKeys.accident.SITUATION,
    getOneFromMultiProxy({
      sport: accidentSituations.SPORT,
      traffic: accidentSituations.TRAFFIC,
      other: accidentSituations.OTHER,
    }),
  ],
  'on-the-way-situation': [
    claimsKeys.accident.SITUATION,
    getOneFromMultiProxy({
      traffic: accidentSituations.TRAFFIC,
      other: accidentSituations.OTHER,
    }),
  ],
  'event-sport_sport-type': [
    claimsKeys.accident.TYPE_DETAILS,
    getOneFromMultiProxy({
      football: sportEventTypes.FOOTBALL,
      ski: sportEventTypes.SKI,
      bike: sportEventTypes.BIKE,
      horse: sportEventTypes.HORSE,
      'other-ball-related': sportEventTypes.OTHER_BALL_RELATED,
      other: sportEventTypes.OTHER,
    }),
  ],
  'event-other_circumstances': [
    claimsKeys.accident.SITUATION,
    getOneFromMultiProxy({
      fall: otherEventTypes.FALL,
      bump: otherEventTypes.BUMP,
      animals: otherEventTypes.ANIMALS,
      machines: otherEventTypes.MACHINES,
      tools: otherEventTypes.TOOLS,
      hazards: otherEventTypes.HAZARDS,
      other: otherEventTypes.OTHER,
    }),
  ],
  'on-the-way-event-other_circumstances': [
    claimsKeys.accident.SITUATION,
    getOneFromMultiProxy({
      fall: otherEventTypes.FALL,
      bump: otherEventTypes.BUMP,
      animals: otherEventTypes.ANIMALS,
      machines: otherEventTypes.MACHINES,
      tools: otherEventTypes.TOOLS,
      hazards: otherEventTypes.HAZARDS,
      other: otherEventTypes.OTHER,
    }),
  ],
  'event-description': claimsKeys.CIRCUMSTANCES,
  'icd-codes_event-circumstances-injury': claimsKeys.accident.INJURY_DESCRIPTION,
  'icd-codes_additional-information': claimsKeys.accident.INJURY_DESCRIPTION,
  hospital_hospitalization: [claimsKeys.hospital.TREATMENT, getYesNoValueUnknown],
  'hospital_hospitalization-date': {
    date: [claimsKeys.hospital.INITIAL_THERAPY_DATE, getDateValueProxy],
    to: [claimsKeys.hospital.INITIAL_THERAPY_DATE_END, getDateValueByKey('to')],
  },
  'hospital_hospitalization-hospital-address': {
    country: [claimsKeys.hospital.COUNTRY, getCountryCode],
    hospitalName: claimsKeys.hospital.NAME,
    address: claimsKeys.hospital.ADDRESS,
    postCode: claimsKeys.hospital.POST_CODE,
    city: claimsKeys.hospital.CITY,
  },
  'hospital_first-contact': [claimsKeys.hospital.INITIAL_TREATMENT, getYesNoValue],
  'first-aid-circumstances-date': [claimsKeys.injured.FIRST_AID_DATE, getDateValueOrUndefined],
  'icd-codes_can-provide-icd-codes': [claimsKeys.accident.GOT_ICD_CODE, getYesNoValue],
  'icd-codes_get-icd-codes': ['manual-values-from-function', getIcdCode],
  'id-policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'injured_contact-data': {
    who: [claimsKeys.injured.SALUTATION, getSalutationValue],
    firstName: claimsKeys.injured.FIRST_NAME,
    lastName: claimsKeys.injured.LAST_NAME,
    phoneNumber: [claimsKeys.injured.PHONE, getPhoneValue],
    date: [claimsKeys.injured.BIRTH_DATE, getDateValueProxy],
  },
  injured_location: {
    address: claimsKeys.injured.ADDRESS,
    postCode: claimsKeys.injured.POST_CODE,
    city: claimsKeys.injured.CITY,
    country: [claimsKeys.injured.COUNTRY, getCountryCode],
  },
  'injured_is-reporter': [claimsKeys.injured.IS_REPORTER, getYesNoValue],
  'injured_release-confidentiality': [claimsKeys.injured.CONSENT, getYesNoValue],
  'injured_policyholder-contact-data': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: claimsKeys.owner.FIRST_NAME,
    lastName: claimsKeys.owner.LAST_NAME,
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
  },
  injured_household: [claimsKeys.injured.HOUSE_COMMUNITY, getYesNoValue],
  iban_payment: [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  iban_name: {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'your-message': claimsKeys.YOUR_MESSAGE,
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
