import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import Marker from './Marker';

const average = (array) => array.reduce((a, b) => a + b, 0) / array.length;
const ZOOM_IN = 15;
const ZOOM_OUT = 10;
const Map = ({
  workshopList = [], onWorkshopSelectionChange, selectedWorkshopId, center,
}) => {
  const defaultCenter = {
    lat: average(workshopList.map(workshop => workshop.lat)),
    lng: average(workshopList.map(workshop => workshop.lon)),
  };

  const handleOnMarkerClick = (id) => {
    onWorkshopSelectionChange(id);
  };

  const onCloseMarkerClick = () => {
    onWorkshopSelectionChange(null);
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        defaultCenter={defaultCenter}
        center={selectedWorkshopId ? center : defaultCenter}
        bootstrapURLKeys={{ key: process.env.MAPS_API_KEY }}
        defaultZoom={ZOOM_OUT}
        zoom={selectedWorkshopId ? ZOOM_IN : ZOOM_OUT}
      >
        {workshopList.map((workshop) => (
          <Marker
            key={workshop.repairerid}
            lat={workshop.lat}
            lng={workshop.lon}
            workshop={workshop}
            isSelected={workshop.repairerid === selectedWorkshopId}
            selectedWorkshopId={selectedWorkshopId}
            onCloseClick={onCloseMarkerClick}
            handleOnMarkerClick={handleOnMarkerClick}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

Map.defaultProps = {
  workshopList: [],
  selectedWorkshopId: null,
  center: null,
};

Map.propTypes = {
  onWorkshopSelectionChange: PropTypes.func.isRequired,
  selectedWorkshopId: PropTypes.string,
  workshopList: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
    repairerid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    distance: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    postalcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  })),
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default Map;
