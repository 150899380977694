import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ErgoInputText from '../../components/ErgoInputText';
import ErgoCountryAutocomplete from '../../components/ErgoCountryAutocomplete';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';

const LocationGeneral = (props, { t }) => {
  const {
    localePath, getValidationProps,
  } = props;

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12">
          <ErgoInputText
            {...getValidationProps('location')}
            description={t(`${localePath}.fields.location.description`)}
            placeholder={t(`${localePath}.fields.location.placeholder`)}
          />
        </div>
        <div className="esc_col esc_col-12 esc_col-l-6">
          <ErgoCountryAutocomplete
            {...getValidationProps('country')}
            placeholder={t(`${localePath}.fields.country.placeholder`)}
          />
        </div>
      </div>
    </div>
  );
};

LocationGeneral.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.shape({
    location: PropTypes.string,
    country: PropTypes.string,
  }),
  isValid: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  getValidationProps: PropTypes.func,
};

LocationGeneral.defaultProps = {
  localePath: '',
  data: {
    location: '',
    country: '',
  },
  isValid: true,
  classes: {},
  onChange: () => {},
  getValidationProps: () => {},
};

LocationGeneral.contextTypes = {
  t: PropTypes.func,
};

export { LocationGeneral as LocationGeneralPlain };
export default compose(
  withMultiFieldValidation,
)(LocationGeneral);
