import { uuid as uuidv4 } from 'uuid';
import trimStart from 'lodash/trimStart';
import { types } from '../../actions/constants';

export const setSessionKey = (key) => {
  try {
    const sessionKey = key || uuidv4();

    sessionStorage.setItem('sessionKey', sessionKey);

    return sessionKey;
  } catch (err) {
    return undefined;
  }
};

export const getSessionKey = () => {
  try {
    const sessionKey = sessionStorage.getItem('sessionKey');

    if (sessionKey === null) {
      return setSessionKey();
    }

    return sessionKey;
  } catch (err) {
    return setSessionKey();
  }
};

export const getSessionItem = (key) => {
  try {
    const item = sessionStorage.getItem(key);

    return item || null;
  } catch (err) {
    return undefined;
  }
};

export const createSessionURL = () => {
  const sessionKey = getSessionKey();
  const token = window.btoa(`location=${window.location.pathname}&sessionKey=${sessionKey}`);

  return `${window.location.origin}/share/${token}`;
};

export const retrieveSessionFromURL = () => {
  try {
    const decodedUrl = window.atob(trimStart(window.location.pathname, '/share/'));

    if (!decodedUrl) {
      return false;
    }

    const urlParams = new URLSearchParams(`?${decodedUrl}`);
    const sessionKey = urlParams.get('sessionKey');

    if (sessionKey) {
      setSessionKey(sessionKey);

      window.location = urlParams.get('location');

      return true;
    }

    return false;
  } catch (err) {
    // Ignore write errors.
    return false;
  }
};

export const loadSessionState = () => {
  try {
    const sessionKey = getSessionKey();
    const serializedState = sessionStorage.getItem(sessionKey);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveSessionState = (state) => {
  try {
    const sessionKey = getSessionKey();
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem(sessionKey, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const resetSession = (store) => {
  try {
    const sessionKey = getSessionKey();
    store.dispatch({
      type: types.RESET_FORM_DATA,
    });

    sessionStorage.removeItem('sessionKey');
    sessionStorage.removeItem(sessionKey);
    sessionStorage.removeItem('lastVisitedLocation');
    sessionStorage.removeItem('source');
  } catch (err) {
    // Ignore write errors.
  }
};

export const setLastVisitedLocation = (params) => {
  try {
    sessionStorage.setItem('lastVisitedLocation', JSON.stringify(params));
  } catch (err) {
    // Ignore write errors.
  }
};

export const getLastVisitedLocation = () => {
  try {
    const lastVisitedLocation = sessionStorage.getItem('lastVisitedLocation');
    return JSON.parse(lastVisitedLocation);
  } catch (err) {
    return undefined;
  }
};

export const removeLastVisitedLocation = () => {
  try {
    sessionStorage.removeItem('lastVisitedLocation');
  } catch (err) {
    // Ignore write errors
  }
};

export const setTracking = (trackingValue) => {
  try {
    sessionStorage.setItem('source', trackingValue.toString());
  } catch (e) {
    // Ignore write errors
  }
  return undefined;
};

export const setSummary = (data) => {
  try {
    const summary = sessionStorage.setItem('summary', JSON.stringify(data));
    return JSON.parse(summary);
  } catch (e) {
    // Ignore write errors
    return undefined;
  }
};

export const getSummary = () => {
  try {
    const summary = sessionStorage.getItem('summary');
    return JSON.parse(summary);
  } catch (err) {
    return undefined;
  }
};

export const getSourceFromSession = () => {
  try {
    const item = sessionStorage.getItem('source');
    return item || null;
  } catch (err) {
    return undefined;
  }
};
