import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentDetails } from '../../../helpers/formData/collisionAbroadFormData';
import { SvgPflaster } from '../../../helpers/IconLoader';

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    date,
    circumstance,
    location,
    country,
    isVehicleDamage,
    licenseNumber,
    privateVehicle,
    isLeasedFinanced,
  } = getIncidentDetails(formData);

  return (
    <Details
      iconLeft={SvgPflaster}
      summary={t(`${localePath}.incident.title`)}
      className={className}
    >
      <p>
        {isVehicleDamage && (
          t(`${localePath}.incident.content-vehicle`, {
            leasingFinanced: isLeasedFinanced ? t(`${localePath}.incident.leasing-financed`) : '',
            privateVehicle: t(`${localePath}.incident.private-vehicle-${privateVehicle}`),
            licenseNumber,
          })
        )}
        {t(`${localePath}.incident.content-accident`, {
          circumstance,
          date,
          location,
          country,
        })}
      </p>
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
