import {
  POLICYHOLDER,
  getUploadValues,
  getYesNoValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getUploadedFilesProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { userFlows } from '../../../helpers/formData/vehicleFormData';
import vehicleFlows from '../../../models/dataMappings/vehicleFlows';

export default {
  'who-fills': [claimsKeys.WHO_FILLS, getYesNoValueProxy(POLICYHOLDER)],
  'what-happened': [
    claimsKeys.WHAT_HAPPENED,
    getOneFromMultiProxy({
      [vehicleFlows.ANIMAL]: userFlows.ANIMAL,
      [vehicleFlows.COLLISION]: userFlows.COLLISION,
      [vehicleFlows.FIRE]: userFlows.FIRE_OR_EXPLOSION,
      [vehicleFlows.GLASS]: userFlows.GLASS,
      [vehicleFlows.THEFT]: userFlows.THEFT,
      [vehicleFlows.WEATHER]: userFlows.WEATHER,
      [vehicleFlows.VANDALISM]: userFlows.VANDALISM,
    }),
  ],
  'upload-documents_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'upload-documents_what-documents': [claimsKeys.TYPE_FILES, getUploadValues],
  'upload-documents_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
