import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoInputText from '../../components/ErgoInputText';

const PoliceReport = (props, { t }) => {
  const {
    localePath,
    getValidationProps,
  } = props;

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-s-6">
          <ErgoInputText
            {...getValidationProps('department')}
            description={t(`${localePath}.fields.department.description`)}
            placeholder={t(`${localePath}.fields.department.placeholder`)}
          />
        </div>
        <div className="esc_col esc_col-12 esc_col-s-6">
          <ErgoInputText
            {...getValidationProps('caseNumber')}
            placeholder={t(`${localePath}.fields.case-number.placeholder`)}
          />
        </div>
      </div>
    </div>
  );
};
PoliceReport.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
};

PoliceReport.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => {},
};

PoliceReport.contextTypes = {
  t: PropTypes.func,
};

export { PoliceReport as PoliceReportPlain };
export default compose(
  withMultiFieldValidation,
)(PoliceReport);
