import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getHospitalDetails } from '../../../helpers/formData/accidentFormData';
import { getDate } from '../../../helpers/formData/formData';
import { SvgKrankenhaus1 } from '../../../helpers/IconLoader';

const HospitalDetails = ({ formData, localePath, className }, { t }) => {
  const {
    hospitalized,
    hasTreatment,
    hospital,
    initialTreatment,
    therapyStart,
    therapyEnd,
    therapyInit,
  } = getHospitalDetails(formData);

  return (
    <Details
      iconLeft={SvgKrankenhaus1}
      summary={t(`${localePath}.hospital.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.hospital.content-hospitalized`, {
          treatment: hospitalized,
        })}
      </p>
      { hasTreatment && (
        <>
          <p>
            {t(`${localePath}.hospital.content-hospital-address`, {
              ...hospital,
            })}
          </p>
          <p>
            {t(`${localePath}.hospital.content-first-contact`, {
              treatment: t(`${localePath}.hospital.answered-${initialTreatment}`),
            })}
          </p>
          <p>
            {t(`${localePath}.hospital.content-therapy-start`, {
              date: getDate(therapyStart),
            })}
          </p>
          <p>
            {t(`${localePath}.hospital.content-therapy-end`, {
              date: therapyEnd
                ? getDate(therapyEnd)
                : t(`${localePath}.hospital.content-therapy-end-noch`),
            })}
          </p>
        </>
      )}
      <p>
        {t(`${localePath}.hospital.content-initial-therapy`, {
          date: therapyInit
            ? getDate(therapyInit)
            : t(`${localePath}.hospital.content-initial-therapy-noch`),
        })}
      </p>
    </Details>
  );
};

HospitalDetails.propTypes = legalConsentProps.sectionProps;
HospitalDetails.defaultProps = legalConsentProps.sectionDefaults;

HospitalDetails.contextTypes = {
  t: PropTypes.func,
};

export default HospitalDetails;
