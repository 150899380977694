const list = {
  position: 'relative',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
};
const listAfter = (color, breakpoint) => ({
  position: 'absolute',
  content: '""',
  width: 2,
  left: 29,
  height: 220,
  top: 1,
  zIndex: 9,
  background: color,
  [`@media (max-width: ${breakpoint})`]: {
    height: '88%',
  },
});


const styles = ({ colors, breakpoints }) => ({
  root: {
    marginTop: 16,
  },
  listLong: {
    ...list,
    '&:after': {
      ...listAfter(colors.themeDarkerGray, breakpoints.breakpointS),
    },
  },
  listShort: {
    ...list,
    '&:after': {
      ...listAfter(colors.themeDarkerGray, breakpoints.breakpointS),
      height: '99%',
    },
  },
  item: {
    paddingBottom: 30,
    position: 'relative',
    zIndex: 70,
    display: 'list-item',
    textAlign: 'left',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: colors.themeWhite,
    border: `5px solid ${colors.themeWhite}`,
    fontWeight: 'bold',
  },
  icon: {
    fill: `${colors.extendedIconBlack} !important`,
    color: `${colors.extendedIconBlack} !important`,
    padding: 10,
    border: `3px solid ${colors.themePrimary}`,
    borderRadius: '50%',
    marginRight: 20,
    margin: '3px 0',
  },
  text: {
    width: '80%',
  },
  linkContainer: {
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    marginTop: 80,
    width: 'auto',
    minWidth: '130px !important',
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      width: '100%',
      marginTop: 50,
    },
  },
});

export default styles;
