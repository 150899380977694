const styles = (theme) => ({
  topBarBox: {
    backgroundColor: theme.colors.themeWhite,
    zIndex: 99,
    position: 'fixed',
    width: '100vw',
  },
  logoContainer: {
    boxShadow: `0 1px 1px 0 ${theme.colors.themeLightGray}`,
  },
});

export default styles;
