import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import injectJss from 'react-jss';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import ErgoInputText from '../../components/ErgoInputText';
import ErgoCountryAutocomplete from '../../components/ErgoCountryAutocomplete';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';

import styles from './styles.jss';

const LocationAccident = (props, { t }) => {
  const {
    localePath, getValidationProps, classes, options,
  } = props;

  const {
    key,
    name,
    value,
    error,
    onChange,
  } = getValidationProps('type');

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12">
          <RadioGroupRow
            label=""
            key={key}
            name={name}
            value={value}
            onChange={onChange}
            error={error ? t(error) : false}
            className={classes.removeDescription}
          >
            {options.map(({ key: optionKey }, index) => (
              <Radio
                key={optionKey}
                index={index}
                value={optionKey}
                label={t(`${localePath}.fields.type.${optionKey}`)}
              />
            ))}
          </RadioGroupRow>

          <ErgoInputText
            {...getValidationProps('location')}
            description={t(`${localePath}.fields.location.description`)}
            placeholder={t(`${localePath}.fields.location.placeholder`)}
          />
        </div>
        <div className="esc_col esc_col-12 esc_col-l-6">
          <ErgoCountryAutocomplete
            {...getValidationProps('country')}
            placeholder={t(`${localePath}.fields.country.placeholder`)}
          />
        </div>
      </div>
    </div>

  );
};

LocationAccident.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.shape({
    location: PropTypes.string,
    country: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })),
  isValid: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  getValidationProps: PropTypes.func,
};

LocationAccident.defaultProps = {
  localePath: '',
  data: {
    location: '',
    country: '',
  },
  options: [],
  isValid: true,
  classes: {},
  onChange: () => {},
  getValidationProps: () => { },
};

LocationAccident.contextTypes = {
  t: PropTypes.func,
};

export { LocationAccident as LocationAccidentPlain };
export default compose(
  injectJss(styles),
  withMultiFieldValidation,
)(LocationAccident);
