import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import Radio from '@eg/elements/Radio';
import { injuredContactDataProps } from '../../models/panels';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoMaskedText from '../ErgoMaskedText';
import ErgoInputText from '../../components/ErgoInputText';
import DatePickerInput from '../DatePickerInput';
import { maskTypes, formatCharactersToMask } from '../../helpers/masks';
import styles from './styles.jss';


class InjuredContactData extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      who: props.data.who,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    const { getValidationProps } = this.props;
    const { onChange, name } = getValidationProps('who');
    const { value } = e.target;
    const target = {
      name,
      value,
    };
    onChange({ target });
    this.setState({ who: value });
  };

  render() {
    const {
      localePath, getValidationProps, options, classes,
    } = this.props;
    const { t } = this.context;
    const { who } = this.state;
    const { name } = getValidationProps('who');
    const inputs = {
      firstName: { size: 6 },
      lastName: { size: 6 },
      phoneNumber: {
        size: 6,
        mask: maskTypes.PHONE_NUMBER,
        formatCharacters: formatCharactersToMask.PHONE_NUMBER,
      },
      date: { size: 6, date: true },
    };

    return (
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className={`esc_col esc_col-12 ${classes.options}`}>
            <div>
              {t(`${localePath}.labels.head`)}
            </div>
            {options.map(option => (
              <Radio
                className={classes.radioButton}
                onChange={this.handleChange}
                name={name}
                key={option}
                value={option}
                label={t(`${localePath}.labels.${option}`)}
                checked={who === option}
              />
            ))}
          </div>
          {Object.keys(inputs).map(inputName => {
            const input = inputs[inputName];
            const inputPlaceholder = t(`${localePath}.placeholders.${inputName}`);
            const inputDescription = t(`${localePath}.descriptions.${inputName}`);
            const validationProps = getValidationProps(inputName);

            if (input.mask) {
              return (
                <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                  <ErgoMaskedText
                    type="text"
                    placeholder={inputPlaceholder}
                    mask={input.mask}
                    formatCharacters={input.formatCharacters}
                    inputSize={input.size}
                    {...validationProps}
                  />
                </div>
              );
            }

            if (input.date) {
              return (
                <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                  <DatePickerInput
                    type="text"
                    {...validationProps}
                    placeholder={inputPlaceholder}
                    description={inputDescription}
                  />
                </div>
              );
            }

            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <ErgoInputText
                  {...validationProps}
                  placeholder={inputPlaceholder}
                  description={inputDescription}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

InjuredContactData.propTypes = injuredContactDataProps.props;
InjuredContactData.contextTypes = {
  t: PropTypes.func,
};
InjuredContactData.defaultProps = injuredContactDataProps.defaults;

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

export { InjuredContactData as InjuredContactDataPlain };
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
)(InjuredContactData);
