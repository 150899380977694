import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import MaskedInput from 'react-maskedinput';
import ArrowUpIcon from '@eg/elements/Icons/ArrowUpIcon';
import ArrowDownIcon from '@eg/elements/Icons/ArrowDownIcon';
import styles from './styles.jss';
import FormMessage from '../../components/FormMessage';

/**
 *
 * Date container responsible for fetching data and dispatching actions to make a change to application state.
 *
 * @param title
 * @param onChange
 * @param data
 * @param t {function} - Polyglot `t()` function retrieved via context API
 * @returns {ReactElement}
 * @constructor
 */
class TimeInput extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      counter: props.value || '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOneUp = this.handleOneUp.bind(this);
    this.handleOneDown = this.handleOneDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { counter } = this.state;
    if (prevState.counter !== counter) {
      const { name } = this.props;
      const newValue = {
        target: {
          value: counter.toString(),
          name,
        },
      };
      this.handleChange(newValue);
    }
  }

  handleChange(e) {
    const { onChange } = this.props;
    this.setState({ counter: e.target.value });
    onChange(e);
  }

  deleteUnderscoreFromCounter() {
    const { counter } = this.state;
    return counter.includes('_') ? counter.slice(0, counter.indexOf('_')) : counter;
  }

  updateCounterState(val) {
    this.setState({ counter: (`0${val}`).slice(-2) });
  }

  compareCounterToValue(a, b) {
    const counterNoUnderscore = this.deleteUnderscoreFromCounter();
    if (Number(counterNoUnderscore) === a) {
      this.updateCounterState(Number(b));
    }
  }

  handleOneUp() {
    const { minValue, maxValue } = this.props;
    const counterNoUnderscore = this.deleteUnderscoreFromCounter();
    this.compareCounterToValue(maxValue, minValue);
    if (counterNoUnderscore < maxValue) {
      this.updateCounterState(Number(counterNoUnderscore) + 1);
    }
  }

  handleOneDown() {
    const { minValue, maxValue } = this.props;
    const counterNoUnderscore = this.deleteUnderscoreFromCounter();
    this.compareCounterToValue(minValue, maxValue);
    if (counterNoUnderscore > minValue) {
      this.updateCounterState(Number(counterNoUnderscore) - 1);
    }
  }

  render() {
    const {
      value,
      name,
      classes,
      error,
      description,
      placeholder,
    } = this.props;
    const formettedValue = value ? (`0${value}`).slice(-2) : '';

    return (
      <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-2">
        <div>
          <span className={`ee_input-wrapper ${classes.inputContainer}`}>
            <MaskedInput
              value={formettedValue}
              className="ee_input"
              name={name}
              onChange={this.handleChange}
              type="text"
              mask="11"
              placeholder={placeholder}
              error={error}
            />
            <span className={classes.arrows}>
              <ArrowUpIcon width={10} height={10} onClick={this.handleOneUp} className={classes.iconUp} />
              <ArrowDownIcon width={10} height={15} onClick={this.handleOneDown} className={classes.iconDown} />
            </span>
          </span>
        </div>
        <FormMessage error={error} description={description} />
      </div>
    );
  }
}

TimeInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
};

TimeInput.defaultProps = {
  name: 'button',
  onChange: () => {},
  value: '',
  error: '',
  classes: {},
  description: '',
  placeholder: '',
  maxValue: 60,
  minValue: 0,
};

export { TimeInput as TimeInputPlain };
export default injectSheet(styles)(TimeInput);
