import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const ErgoContactDataRepairShop = props => (
  <ErgoDataForm {...props} formType={formTypes.CONTACT_REPAIR_SHOP} />
);

ErgoContactDataRepairShop.propTypes = {
  localePath: PropTypes.string,
  buttonText: PropTypes.string,
  isValid: PropTypes.bool,
};

ErgoContactDataRepairShop.defaultProps = {
  localePath: '',
  buttonText: '',
  isValid: true,
};

export default ErgoContactDataRepairShop;
