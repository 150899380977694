import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { fireOptions } from '../../../helpers/formData/propertyFormData';

export default {
  fire_circumstances_options: [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      lightning: fireOptions.LIGHTNING,
      overvoltage: fireOptions.OVERVOLTAGE,
      'open-fire': fireOptions.OPEN_FIRE,
      'singeing-damage': fireOptions.SINGEING_DAMAGE,
      arson: fireOptions.ARSON,
      explosion: fireOptions.EXPLOSION,
      different: fireOptions.OTHER,
    }),
  ],
  'fire_circumstances_police-report_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'fire_circumstances_police-report_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
};
