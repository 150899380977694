import { has, merge } from 'lodash';
import { getTranslationArray } from '../../helpers/polyglot';

function getStaticData() {
  // we need to add additional data to issueDate
  const temp = getTranslationArray('analytics-static');
  if (has(temp, 'page.pageInfo.issueDate')) {
    temp.page.pageInfo.issueDate = `${temp.page.pageInfo.issueDate}${process.env.VERSION}`;
  }
  return temp;
}

const staticInfo = getStaticData();

const issueTypes = {
  vehicle: ' SchadenMelden_Mobil',
  broker: ' SchadenMelden_Mobil',
  accident: 'SchadenMelden_PrivateAccident',
  'private-liability': 'SchadenMelden_PrivateLiability',
  'legal-protection': 'SchadenMelden_LegalProtection',
  property: 'SchadenMelden_PropertyConsumer',
  upload: 'SchadenMelden_UploadDocument',
  'collision-abroad': 'SchadenMelden_CollisionAbroad',
  'asset-liability': 'SchadenMelden_AssetLiability',
};

function enhanceWithAdditionalData(pageName, whatHappened) {
  const serviceType = getTranslationArray('analytics-service-type');

  let info = {
    page: {
      pageInfo: {
        pageName: `${staticInfo.page.pageInfo.pageName}${pageName}`,
      },
    },
  };

  if (has(serviceType.pageMapping, pageName)) {
    const name = serviceType.pageMapping[pageName];
    info = merge(info, {
      miscellaneous: {
        onlineService: {
          serviceStart: true,
          selfServiceType: serviceType.selfServiceType[name],
        },
      },
    });
  }

  if (pageName === 'thank-you') {
    info = merge(info, {
      miscellaneous: {
        onlineService: {
          serviceEnd: true,
          selfServiceType: serviceType.selfServiceType[whatHappened],
        },
      },
    });
  }

  return info;
}

function specifyPayloadPerModule(path, payload) {
  const payloadToUpdate = payload;
  const { 2: moduleName, 3: pageName } = path.split('/');

  if (!Object.keys(issueTypes).includes(moduleName)) {
    return {};
  }

  payloadToUpdate.page.attributes.brokerModus = moduleName === 'broker' ? 'On' : 'Off';
  payloadToUpdate.page.pageInfo.issueDate = `${issueTypes[moduleName]}|${process.env.BUILD_TIME}`;
  payloadToUpdate.page.pageInfo.pageName = `${issueTypes[moduleName]}:${pageName}`;
  payloadToUpdate.page.pageInfo.sysEnv = window.location.origin;

  return payloadToUpdate;
}

function pushPageLoadEvent(path, whatHappened = '') {
  const eventObject = {
    event: 'PageLoaded',
  };

  const pageName = path.split('/').pop();

  const info = enhanceWithAdditionalData(pageName, whatHappened);


  const analyticsPayload = merge(eventObject, staticInfo, info);
  const analyticsByModule = specifyPayloadPerModule(path, analyticsPayload);


  if (window && Object.keys(analyticsByModule).length) {
    window.appEventData.push(analyticsByModule);
  }
}

export default {
  pushPageLoadEvent,
};
