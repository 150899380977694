import React from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { ErgoElementsContext } from '@eg/elements/Provider';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import { types } from '../../actions/constants';
import { multipleSelectWithColumnsProps } from '../../models/panels';
import ErgoCheckbox from '../../components/ErgoCheckbox';
import styles from './styles.jss';

class MultipleSelectWithColumns extends React.PureComponent {
  componentDidUpdate(nextProps) {
    const { data, getValidationProps } = this.props;
    const { name, onChange } = getValidationProps();

    if (!isEqual(data, nextProps.data)) {
      const target = {
        name,
        value: data,
      };
      onChange({ target });
    }
  }

  handleClick = value => e => {
    const { updateField, pathName, getValidationProps } = this.props;
    const { name } = getValidationProps();
    updateField(name, value, pathName);
  }

  renderLabel = option => {
    const { classes, localePath } = this.props;
    const { t } = this.context;
    let iconDisabled = false;
    const Icon = multipleSelectWithColumnsProps.icons[option.icon] || InfoIcon;
    if (option.icon === 'disabled') {
      iconDisabled = true;
    }

    return (
      <div className={classes.labelWrapper}>
        { !iconDisabled
          && (
            <span className={classes.icon}>
              <ErgoElementsContext.Consumer>
                {({ idGenerator }) => (
                  <Icon
                    idGenerator={idGenerator}
                    width={32}
                    height={32}
                  />
                )}
              </ErgoElementsContext.Consumer>
            </span>
          )
        }
        {t(`${localePath}.options.${option.key}`)}
      </div>
    );
  };

  renderOption = (option) => {
    const { data, getValidationProps } = this.props;
    const { onChange } = getValidationProps();

    return (
      <div key={option.key}>
        <ErgoCheckbox
          key={option.key}
          onClick={this.handleClick(option.key)}
          onChange={onChange}
          label={this.renderLabel(option)}
          checked={data.includes(option.key)}
          displayError={false}
        />
      </div>
    );
  };

  render() {
    const { classes, firstCol, lastCol } = this.props;
    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className={`esc_col esc_col-12 esc_col-s-6 esc_col-m-6 ${classes.option}`}>
            {firstCol.map(option => this.renderOption(option))}
          </div>
          <div className={`esc_col esc_col-12 esc_col-s-6 esc_col-m-6 ${classes.option}`}>
            {lastCol.map(option => this.renderOption(option))}
          </div>
        </div>
      </div>
    );
  }
}

MultipleSelectWithColumns.propTypes = multipleSelectWithColumnsProps.props;
MultipleSelectWithColumns.defaultProps = multipleSelectWithColumnsProps.defaults;
MultipleSelectWithColumns.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE_MULTI,
    fieldName: name,
    pathName,
    payload,
  }),
});

export { MultipleSelectWithColumns as MultipleSelectWithColumnsPlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectJss(styles),
  withSingleFieldValidation,
)(MultipleSelectWithColumns);
