const styles = ({ breakpoints }) => ({
  wrapper: {
    textAlign: 'left',
  },
  option: {
    borderWidth: '0px 12px !important',
  },
  lastOption: {
    order: 4,
  },
  order: {
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      order: 3,
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 12,
    flexShrink: 0,
  },
});

export default styles;
