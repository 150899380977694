/* eslint-disable quote-props */
import {
  getVehicleTypeValue,
  getPhoneValue,
  getYesNoValue,
  getSalutationValue,
  getLastNameValue,
  getFirstNameValue,
  padPolicyNumber,
  getDateValueProxy,
  arrayIncludesKeyAndValueProxy,
  getCountryCode,
  getLicenseNumber,
  arrayIncludesValueProxy,
} from '../claimData.helpers';
import {
  getPersonDamageCircumstancesValueProxy,
  getPersonDamageGuiltValueProxy,
  getPersonDamageItemDamagesValueProxy,
} from './claimData.helpers';
import {
  claimsKeys, getCarMapKey, userTypes,
} from '../../../helpers/formData/formData';

export default {
  'event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'event-circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'business_circumstances': [claimsKeys.CIRCUMSTANCES, getPersonDamageCircumstancesValueProxy],
  'business_further-comments': claimsKeys.NEW_FURTHER_INFORMATION,
  'business_working-group': [claimsKeys.thirdParty.BUSINESS_PARTNER, getYesNoValue],
  'business_guilt': [claimsKeys.CAUSER_OF_DAMAGE, getPersonDamageGuiltValueProxy],
  'business_who-what-has-suffered': {
    'personal-injury': [claimsKeys.thirdParty.PERSONAL_INJURY, arrayIncludesValueProxy('personal-injury')],
    'furniture-damage': [claimsKeys.thirdParty.ITEM_DAMAGE, arrayIncludesValueProxy('furniture-damage')],
    'vehicle-damage': [claimsKeys.thirdParty.VEHICLE_DAMAGE, arrayIncludesValueProxy('vehicle-damage')],
  },
  // Personal
  'business_specify-personal-injury': claimsKeys.thirdParty.PERSONAL_INJURIES,
  'business_contact-details-coinsured_contact': {
    who: [claimsKeys.thirdParty.coinsured_contact_data.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.coinsured_contact_data.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.coinsured_contact_data.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.coinsured_contact_data.PHONE, getPhoneValue],
    email: claimsKeys.thirdParty.coinsured_contact_data.EMAIL,
  },
  'business_contact-details-coinsured_location': {
    address: claimsKeys.thirdParty.coinsured_contact_data.ADDRESS,
    postCode: claimsKeys.thirdParty.coinsured_contact_data.POST_CODE,
    city: claimsKeys.thirdParty.coinsured_contact_data.CITY,
    country: claimsKeys.thirdParty.coinsured_contact_data.COUNTRY_CODE,
  },
  'injured-party-work-for-you': [claimsKeys.thirdParty.EMPLOYED_AT, getYesNoValue],
  // Item
  'business_borrowed': [claimsKeys.thirdParty.OBJECTS_LENDING, getYesNoValue],
  'business_deliver-damaged-item': [claimsKeys.thirdParty.DELIVER_DAMAGED_ITEM, getYesNoValue],
  'business_receive-damaged-item': [claimsKeys.thirdParty.ITEM_DELIVERED, getYesNoValue],
  'business_item-damage': [
    claimsKeys.thirdParty.ITEM_INJURIES, getPersonDamageItemDamagesValueProxy,
  ],
  // Vehicle
  'business_specify-vehicle-damage_vehicle-type': [claimsKeys.thirdParty.VEHICLE_TYPE, getVehicleTypeValue],
  'business_specify-vehicle-damage_license-number': [
    claimsKeys.thirdParty.LICENSE_NUMBER,
    getLicenseNumber,
  ],
  'business_specify-vehicle-damage_car-map': {
    describeDamage: claimsKeys.thirdParty.DESCRIBE_DAMAGE,
    'front-left': [
      getCarMapKey('front-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-left'),
    ],
    'front-middle': [
      getCarMapKey('front-middle', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-middle'),
    ],
    'front-right': [
      getCarMapKey('front-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'front-right'),
    ],
    'middle-left': [
      getCarMapKey('middle-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-left'),
    ],
    'middle-part': [
      getCarMapKey('middle-part', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-part'),
    ],
    'middle-right': [
      getCarMapKey('middle-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'middle-right'),
    ],
    'back-left': [
      getCarMapKey('back-left', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-left'),
    ],
    'back-middle': [
      getCarMapKey('back-middle', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-middle'),
    ],
    'back-right': [
      getCarMapKey('back-right', userTypes.THIRD_PARTY),
      arrayIncludesKeyAndValueProxy('carMap', 'back-right'),
    ],
  },
  'business_specify-vehicle-damage_bike-other': claimsKeys.thirdParty.DESCRIBE_DAMAGE,
  'business_policy-holder_policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'business_policy-holder_contact': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
  },
  'third-person_contact': {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
  },
  'third-person_location': {
    address: claimsKeys.thirdParty.ADDRESS,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY_CODE, getCountryCode],
  },
  'settlement': [claimsKeys.SETTLEMENT, getYesNoValue],
  'your-message': claimsKeys.YOUR_MESSAGE,
};
