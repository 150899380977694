import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Card from '@eg/elements/Card';
import injectSheet from 'react-jss';
import Button from '@eg/elements/Button';
import styles from './styles.jss';
import call from '../../models/panels/call';
import callImage from './assets/call.jpg';
import HeadingForm from '../../layout/HeadingForm';

const Call = ({
  localePath,
  classes,
  phoneNumber,
}, { t }) => {
  const openCallApp = () => {
    window.location.href = `tel:${t(`${phoneNumber}`)}`;
  };

  return (
    <>
      <HeadingForm
        value={t(`${localePath}.heading`)}
      />
      <Card padding="small">
        <div className="esc_grid__wrapper">
          <div className="esc_col esc_col-12 esc_col-m-5">
            <img src={callImage} alt="telephone consultation" className={classes.image} />
          </div>
          <div className={`esc_col esc_col-12 esc_col-m-7 ${classes.infoPanel}`}>
            <div className={classes.title}>
              {t(`${localePath}.title`)}
            </div>
            <div className={classes.message}>
              {t(`${localePath}.message_first`)}
            </div>
            <div className={classes.message}>
              {t(`${localePath}.message_second`)}
            </div>
            <Button
              className={classes.button}
              variant="primary"
              onClick={() => openCallApp()}
            >
              {t(`${localePath}.button_label`)}
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

Call.propTypes = call.props;
Call.defaultProps = call.defaults;
Call.contextTypes = {
  t: PropTypes.func,
};

export { Call as CallPlain };
export default compose(
  withRouter,
  injectSheet(styles),
)(Call);
