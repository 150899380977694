import { getOneFromMultiProxy } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { stormOptions } from '../../../helpers/formData/propertyFormData';

export default {
  'storm-hail_circumstances_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      storm: stormOptions.STORM,
      hail: stormOptions.HAIL,
    }),
  ],
};
