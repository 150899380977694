import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ErgoTextArea from '../../components/ErgoTextArea';
import IdentityAutocomplete from '../../components/IdentityAutocomplete';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import styles from './styles.jss';

const IdentityWithDescription = ({
  localePath,
  getValidationProps,
  classes,
}, { t }) => (
  <div>
    <div className="esc_col esc_col-12">
      <div className={classes.youAreLabel}>
        {t(`${localePath}.fields.youAre`)}
      </div>
    </div>
    <IdentityAutocomplete
      {...getValidationProps('identity')}
      placeholder={t(`${localePath}.fields.identity.placeholder`)}
    />

    <ErgoTextArea
      {...getValidationProps('description')}
      minRows={10}
      placeholder={t(`${localePath}.fields.textArea.placeholder`)}
      description={t(`${localePath}.fields.textArea.description`)}
    />
  </div>
);


IdentityWithDescription.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
};

IdentityWithDescription.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => {},
};

IdentityWithDescription.contextTypes = {
  t: PropTypes.func,
};

export { IdentityWithDescription as LongTextPlain };
export default compose(withMultiFieldValidation, injectSheet(styles))(IdentityWithDescription);
