import React from 'react';
import PropTypes from 'prop-types';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ErgoTextArea from '../../components/ErgoTextArea';

const LongText = ({
  localePath,
  getValidationProps,
}, { t }) => (
  <ErgoTextArea
    {...getValidationProps()}
    minRows={10}
    placeholder={t(`${localePath}.placeholder`)}
    description={t(`${localePath}.description`)}
  />
);

LongText.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
};

LongText.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
};

LongText.contextTypes = {
  t: PropTypes.func,
};

export { LongText as LongTextPlain };
export default withSingleFieldValidation(LongText);
