import React from 'react';
import PropTypes from 'prop-types';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import InputText from '../../components/InputText/InputText';

const PoliceCase = ({
  localePath,
  classes,
  getValidationProps,
}, { t }) => (
  <InputText
    type="text"
    className={classes.inputBox}
    label={t(`${localePath}.label`)}
    {...getValidationProps()}
  />
);

PoliceCase.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
};

PoliceCase.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => {},
};

PoliceCase.contextTypes = {
  t: PropTypes.func,
};

export { PoliceCase as PoliceCasePlain };
export default withSingleFieldValidation(PoliceCase);
