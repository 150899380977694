import PropTypes from 'prop-types';


import {
  SvgWindschutzscheibe,
  SvgHeckscheibe,
  SvgSeitenscheiben,
  SvgScheinweifer,
  SvgSeitenspiegel,
} from '../../helpers/IconLoader';


export const icons = {
  frontwindow: SvgWindschutzscheibe,
  backwindow: SvgHeckscheibe,
  sidewindow: SvgSeitenscheiben,
  headlights: SvgScheinweifer,
  sidemirror: SvgSeitenspiegel,
};

export const MultipleSelectWithCommentsData = PropTypes.shape({
  describeDamage: PropTypes.string,
  typesOfGlassDamage: PropTypes.arrayOf(PropTypes.string),
});

export const props = {
  localePath: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  getValidationProps: PropTypes.func,
  isValid: PropTypes.bool,
  name: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.object),
  data: MultipleSelectWithCommentsData,
};

export const defaults = {
  localePath: '',
  options: [],
  classes: {},
  onChange: () => {},
  getValidationProps: () => {},
  isValid: true,
  name: '',
  values: {},
  data: {
    describeDamage: '',
    typesOfGlassDamage: [],
  },
};

export default {
  icons,
  props,
  defaults,
  MultipleSelectWithCommentsData,
};
