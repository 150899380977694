import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ToggleButton from '../../components/ToggleButton';
import { setNavigationState, navigateToPath } from '../../actions/navigation';
import { NAV_DIRECTIONS, types } from '../../actions/constants';
import { buttonImageSwitchProps } from '../../models/panels';
import { NAV_DELAY } from '../../helpers/navigation';
import styles from './styles.jss';

const ButtonImageSwitch = ({
  localePath,
  data,
  options,
  classes,
  onChange,
  goForward,
  updateField,
  pathName,
  getValidationProps,
}, { t }) => {
  const onClick = (value) => () => {
    const { name } = getValidationProps();
    const target = { name, type: 'text', value };
    updateField(name, value, pathName);
    onChange({ target });
    goForward();
  };

  const getBodyTranslation = (key) => {
    const text = t(`${localePath}.options.${key}`);
    const textBody = t(`${localePath}.options.${key}.body`);

    return text || textBody;
  };

  const getLabelTranslation = (key) => t(`${localePath}.options.${key}.label`);

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        {options.map(({ key, icon }, index) => {
          const IconComponent = buttonImageSwitchProps.icons[icon] || buttonImageSwitchProps.checkIcon;
          const labelText = getLabelTranslation(key);
          return (
            <div key={key} className="esc_col-6 esc_col-s-6 esc_col-m-4 esc_col-l-3">
              <ToggleButton
                index={index}
                option={key}
                onClick={onClick(key)}
                selected={data === key}
              >
                {labelText && (
                  <div className={classes.labelWrapper}>
                    <div className={classes.labelText}>
                      {labelText}
                    </div>
                  </div>
                )}
                <div className={classes.wrapper}>
                  <IconComponent className={classes.icon} />
                  <div className={classes.toggleLabel}>{getBodyTranslation(key)}</div>
                </div>
              </ToggleButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ButtonImageSwitch.propTypes = buttonImageSwitchProps.props;
ButtonImageSwitch.defaultProps = buttonImageSwitchProps.defaults;
ButtonImageSwitch.contextTypes = {
  t: PropTypes.func,
};

export { ButtonImageSwitch as ButtonImageSwitchPlain };

const mapStateToProps = ({ formData }, ownProps) => {
  let data = {};
  if (formData[ownProps.pathName] && formData[ownProps.pathName][ownProps.name]) {
    data = formData[ownProps.pathName][ownProps.name];
  }
  return { data };
};

const mapDispatchToProps = (dispatch) => ({
  goForward: () => {
    dispatch(setNavigationState(true, true));
    dispatch(navigateToPath(
      NAV_DIRECTIONS.NEXT,
      NAV_DELAY,
    ));
  },
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE,
    fieldName: name,
    pathName,
    payload,
  }),
});

export default compose(
  withRouter,
  injectSheet(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withSingleFieldValidation,
)(ButtonImageSwitch);
