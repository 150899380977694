import { breakpoints } from '../constants';

/**
 * Helper for returning a breakpoint as an integer.
 * @function
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * If a string is passed, `constants.breakpoints` will be checked for
 * a corresponding number value to return.
 *
 * Otherwise the val arg (number) is returned, or 0.
 *
 * @returns {Number} - unitless integer
 */
/* eslint-disable max-len */
const getBreakpoint = val => breakpoints[val] || (!((typeof (val) === 'number') || (typeof (val) === 'string')) || val < 0 ? 0 : val);
/* eslint-enable max-len */

/**
 * Helper returning breakpoint for (min-width) media query.
 * @function
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * @returns {String} - pixel value
 */
const getMinBreakpoint = val => `${getBreakpoint(val)}px`;

/**
 * Helper returning breakpoint for (max-width) media query.
 * @function
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * Due to the nature of max-width media queries, 1 is subtracted from
 * the value, to stop min/max width CSS overlapping.
 *
 * @returns {String} - pixel value
 */
const getMaxBreakpoint = (val) => {
  const breakpoint = parseInt(getBreakpoint(val), 10);
  return `${(breakpoint > 0 ? breakpoint : 1) - 1}px`;
};

/**
 * Helper for creating a (min-width) media query.
 * @function
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * @returns {String} - media query expression
 */
const createMinWidthQuery = val => `(min-width: ${getMinBreakpoint(val)})`;

/**
 * Helper for creating a (max-width) media query.
 * @function
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * @returns {String} - media query expression
 */
const createMaxWidthQuery = val => `(max-width: ${getMaxBreakpoint(val)})`;

/**
 * Method for creating a media query (min-width) block.
 * @method
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * @param {Object} styles - styles to be applied to the component at this media query
 *
 * @returns {Object} - media query as key with style properties as value
 */
const min = (val, styles) => ({
  [`@media ${createMinWidthQuery(val)}`]: styles,
});

/**
 * Method for creating a media query (max-width) block.
 * @method
 * @param {String|Number} val - value of breakpoint.
 *
 * Either a string (breakpoint name) e.g. 'desktopSize', or a number in pixels e.g. 700.
 *
 * @param {Object} styles - styles to be applied to the component at this media query
 *
 * @returns {Object} - media query as key with style properties as value
 */
const max = (val, styles) => ({
  [`@media ${createMaxWidthQuery(val)}`]: styles,
});

/**
 * Method for creating a media query (min-width) and (max-width) block.
 * @method
 * @param {String|Number} valMin - value of breakpoint for (min-width) query.
 * @param {String|Number} valMax - value of breakpoint for (max-width) query.
 *
 * Either as strings (breakpoint name) e.g. 'desktopSize', or numbers in pixels e.g. 700.
 *
 * @param {Object} styles - styles to be applied to the component at this media query
 *
 * @returns {Object} - media query as key with style properties as value
 */
const minMax = (valMin, valMax, styles) => ({
  [`@media ${createMinWidthQuery(valMin)} and ${createMaxWidthQuery(valMax)}`]: styles,
});


/**
 * Method for creating a media query for IE11
 *
 * @param {Object} styles - styles to be applied to the component at this media query
 *
 * @returns {Object} - media query as key with style properties as value
 */
const ie11 = (styles) => ({
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': styles,
});

/**
 * Media Queries Style Helper
 *
 * Create object with methods for use as a helper within styled components (via Styletron)
 *
 * NOTE: at present, only breakpoints in pixels are supported
 *
 * - Used as a shortcut to output a media query block within component styles.
 * - Allows breakpoints declared by name e.g. 'desktopLarge',
 * referencing shared breakpoints given in /assets/styles/consants.js
 * - Tweakpoints can also be generated in components by passing Number arguments
 * - Concept follows the common Sass media query library include-media:
 * http://include-media.com/
 * - Object spread function across styles to include properties
 *
 * Example:
 *
 * export const List = styled('ul', {
 *   color: colors.secondary,
 *
 *   // Breakpoint
 *   ...mediaQuery.min('mediumScreen', {
 *     display: 'flex'
 *   })
 *
 *   // Tweakpoint
 *   ...mediaQuery.min(500, {
 *     fontSize: '1.25em'
 *   })
 * });
 *
 */
const mediaQueries = {
  min,
  max,
  minMax,
  ie11,
};

export default mediaQueries;
