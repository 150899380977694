import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './CardBar.styles.jss';

const CardBar = ({ localePath, classes, onOpenDialog }, { t }) => (
  <>
    <p className={classes.heading}>
      <b>{t(`${localePath}.card.bar.heading-part1`)}</b>
      {t(`${localePath}.card.bar.heading-part2`)}
    </p>
    <button
      type="button"
      className={classes.button}
      onClick={onOpenDialog}
    >
      {t(`${localePath}.card.bar.heading-part3`)}
    </button>
  </>
);

CardBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  onOpenDialog: PropTypes.func,
};

CardBar.defaultProps = {
  classes: {},
  localePath: '',
  onOpenDialog: () => {},
};

CardBar.contextTypes = {
  t: PropTypes.func,
};

export { CardBar as CardBarPlain };
export default injectSheet(styles)(CardBar);
