import React from 'react';
import PropTypes from 'prop-types';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ErgoTextArea from '../../components/ErgoTextArea';
import Card from './Card';

const LongTextWithInfoBox = ({
  localePath,
  getValidationProps,
}, { t }) => (
  <ContentWrapper>
    <Card localePath={localePath} />
    <ErgoTextArea
      {...getValidationProps()}
      minRows={5}
      placeholder={t(`${localePath}.placeholder`)}
      description={t(`${localePath}.description`)}
    />
  </ContentWrapper>
);

LongTextWithInfoBox.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
};

LongTextWithInfoBox.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
};

LongTextWithInfoBox.contextTypes = {
  t: PropTypes.func,
};

export { LongTextWithInfoBox as LongTextWithInfoBoxPlain };
export default withSingleFieldValidation(LongTextWithInfoBox);
