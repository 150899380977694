const styles = theme => ({
  container: {
    border: `1px solid ${theme.colors.themeLighterGray}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${theme.breakpoints.breakpointM})`]: {
      maxWidth: '329px',
    },
  },
  line: {
    height: '1px',
    backgroundColor: theme.colors.themeLighterGray,
  },
});

export default styles;
