import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';

import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { getDamageDetails, getYesNo } from '../../../helpers/formData/assetLiabilityFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    kindOfDamage,
    damageValue,
    dunningNotice,
    dunningNoticeDate,
    assertedInWriting,
  } = getDamageDetails(formData);

  const assertedInWritingMapped = getYesNo(assertedInWriting);
  const dunningNoticeMapped = getYesNo(dunningNotice);

  return (
    <>
      <Details
        iconLeft={SvgDerSchadenDesUnfallbeteiligten}
        summary={t(`${localePath}.damage-of-accident.title`)}
        className={className}
      >
        {kindOfDamage && (
        <p>
          {t(`${localePath}.damage-of-accident.content-kind-of-damage`, {
            kindOfDamage,
          })}
        </p>
        )}
        {damageValue && (
          <p>
            {t(`${localePath}.damage-of-accident.content-damage-value`, {
              damageValue,
            })}
          </p>
        )}
        {dunningNotice && (
          <p>
            {t(`${localePath}.damage-of-accident.content-dunning-notice`, {
              dunningNoticeMapped,
            })}
          </p>
        )}
        {dunningNoticeDate && (
          <p>
            {t(`${localePath}.damage-of-accident.content-dunning-notice-date`, {
              dunningNoticeDate,
            })}
          </p>
        )}
        {assertedInWriting && (
          <p>
            {t(`${localePath}.damage-of-accident.content-asserted-in-writing`, {
              assertedInWritingMapped,
            })}
          </p>
        )}

      </Details>
    </>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
