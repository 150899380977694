const styles = ({ colors }) => ({
  messageBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0px !important',
  },
  infoIcon: {
    paddingRight: 8,
    fill: `${colors.themeDarkerGray} !important`,
  },
  messageBoxContent: {
    width: '100%',
    paddingTop: 5,
    paddingLeft: 5,
  },
  mobileAccordion: {
    marginBottom: 40,
  },
  mobileAccordionTitle: {
    color: colors.themePrimary,
  },
  link: {
    color: colors.themePrimary,
    textDecoration: 'none',
  },
  headingPosition: {
    textAlign: 'center',
    margin: 30,
  },
  card: {
    border: '0 !important',
    padding: '0 !important',
  },
  cardHeader: {
    paddingTop: 8,
    color: colors.themeWhite,
    backgroundColor: colors.themePrimary,
    height: 38,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  cardContent: {
    color: colors.themeDarkerGray,
    padding: '24px !important',
  },
  cardIcon: {
    color: colors.themeBlack,
  },
  autocomplete: {
    textAlign: 'left',
  },
});

export default styles;
