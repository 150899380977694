const propertyFlows = {
  FIRE: 'fire',
  THEFT: 'theft',
  WATER: 'water',
  STORM: 'storm',
  HAZARDS: 'hazards',
  GLASS: 'glass',
};

export default propertyFlows;
