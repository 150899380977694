import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const ContactDataNonpersonal = props => (
  <ErgoDataForm {...props} formType={formTypes.CONTACT_NON_PERSONAL} />
);

ContactDataNonpersonal.propTypes = {
  localePath: PropTypes.string,
  isValid: PropTypes.bool,
  buttonText: PropTypes.string,
};

ContactDataNonpersonal.defaultProps = {
  localePath: '',
  isValid: true,
  buttonText: '',
};

ContactDataNonpersonal.contextTypes = {
  t: PropTypes.func,
};

export default ContactDataNonpersonal;
