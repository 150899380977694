import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgAuszahlung } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPayingOutDetails } from '../../../helpers/formData/propertyFormData';

const PayingOutDetails = ({ formData, localePath, className }, { t }) => {
  const {
    account,
    name,
    iban,
    hasAnotherBankAccount,
    taxForDamage,
    isBusinessFlow,
  } = getPayingOutDetails(formData);

  return (
    <Details iconLeft={SvgAuszahlung} summary={t(`${localePath}.paying-out.title`)} className={className}>
      <p>
        {t(`${localePath}.paying-out.content`, { account })}
      </p>
      { hasAnotherBankAccount && (
        <>
          <p>
            {t(`${localePath}.paying-out.content-name`, { name })}
          </p>
          <p>
            {t(`${localePath}.paying-out.content-iban`, { iban })}
          </p>
        </>
      )}
      { (taxForDamage && isBusinessFlow) && (
      <p>
        {t(`${localePath}.paying-out.tax-for-damage`, { taxForDamage })}
      </p>
      )}
    </Details>
  );
};

PayingOutDetails.propTypes = legalConsentProps.sectionProps;
PayingOutDetails.defaultProps = legalConsentProps.sectionDefaults;

PayingOutDetails.contextTypes = {
  t: PropTypes.func,
};

export default PayingOutDetails;
