const styles = ({ colors }) => ({
  wrapper: {
    textAlign: 'left',
  },
  option: {
    borderWidth: '0px 12px !important',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: colors.extendedIconBlack,
    marginRight: '12px',
    '& svg': {
      width: '48px !important',
      height: '48px !important',
    },
  },
});

export default styles;
