import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import Button from '@eg/elements/Button';
import styles from './styles.jss';

import TopBar from '../../layout/TopBar/TopBar';
import Footer from '../../layout/Footer/Footer';

function goToHomePage() {
  global.location = 'https://www.ergo.de/';
}

function goToDamageReport() {
  global.location = 'https://www.ergo.de/de/Service/Kontakt/Schaden-online-melden';
}

const NotFound = ({ classes, localePath }, { t }) => (
  <>
    <TopBar />
    <div className={classes.spacer} />
    <div className="esc_container esc_container--l esc_box esc_box--around">
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className="esc_col esc_col-12">
            <div>
              <strong>
                {t(`${localePath}.heading`)}
              </strong>
            </div>
          </div>
          <div className={`esc_col esc_col-12 ${classes.firstHeader}`}>
            <h2>
              {t(`${localePath}.second-heading`)}
            </h2>
          </div>
          <div className="esc_col esc_col-12">
            {t(`${localePath}.third-line`)}
          </div>
          <div className="esc_col esc_col-12">
            <ul className={classes.list}>
              <li>{t(`${localePath}.fourth-line`)}</li>
            </ul>
          </div>
          <div className="esc_col esc_col-12">
            <strong>Tipp: </strong>
            {t(`${localePath}.fifth-line-first`)}
            {' '}
            <Button variant="text-link" onClick={goToHomePage}>
              {t(`${localePath}.fifth-line-button`)}
            </Button>
            {' '}
            {t(`${localePath}.fifth-line-second`)}
            {' '}
            <Button variant="text-link" onClick={goToDamageReport}>
              {t(`${localePath}.fifth-line-second-button`)}
            </Button>
          </div>
          <div className="esc_col esc_col-12">
            <ul className={classes.list}>
              <li>{t(`${localePath}.sixth-line`)}</li>
            </ul>
          </div>
          <div className="esc_col esc_col-12">
            <strong>Tipp: </strong>
            {t(`${localePath}.seventh-line`)}
          </div>
          <div className="esc_col esc_col-12">
            <ul className={classes.list}>
              <li>
                {t(`${localePath}.eight-line`)}
              </li>
            </ul>
          </div>
          <div className="esc_col esc_col-12">
            <strong>Tipp: </strong>
            {t(`${localePath}.ninth-line`)}
          </div>
          <div className="esc_col esc_col-12">
            <ul className={classes.list}>
              <li>
                {t(`${localePath}.tenth-line`)}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className={classes.spacer} />
    <div className={classes.footerFixer}>
      <Footer />
    </div>
  </>
);

NotFound.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
};

NotFound.defaultProps = {
  classes: {},
  localePath: 'not-found',
};

NotFound.contextTypes = {
  t: PropTypes.func,
};

export { NotFound as NotFoundPlain };
export default injectSheet(styles)(NotFound);
