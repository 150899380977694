import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { bicycleTheftOptions, bicycleTheftBikeOptions } from '../../../helpers/formData/propertyFormData';
import { getStolenPartsValueProxy, getStolenItemsValueProxy } from './claimData.helpers';


const mappingPoliceReportDetails = {
  department: claimsKeys.DEPARTMENT,
  caseNumber: claimsKeys.CASE_NUMBER,
};

export default {
  'bicycle-theft_was-locked': [claimsKeys.property.WAS_LOCKED, getYesNoValue],
  'bicycle-theft_is-ebike': [
    claimsKeys.property.IS_EBIKE,
    getOneFromMultiProxy({
      ebike: bicycleTheftBikeOptions.EBIKE,
      bike: bicycleTheftBikeOptions.BIKE,
      other: bicycleTheftBikeOptions.OTHER,
    }),
  ],
  'bicycle-theft_what-was-stolen': [
    claimsKeys.property.WHAT_WAS_STOLEN,
    getOneFromMultiProxy({
      bike: bicycleTheftOptions.BIKE,
      'bike-parts': bicycleTheftOptions.PARTS,
    }),
  ],
  'bicycle-theft_police-report_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'bicycle-theft_accessoires-stolen': [claimsKeys.property.LIST_OF_STOLEN_GOODS, getYesNoValue],
  'bicycle-theft_invoice-available': [claimsKeys.property.INVOICE_AVAILABLE, getYesNoValue],
  'bicycle-theft_police-report_report-details': mappingPoliceReportDetails,
  'bicycle-theft_which-parts-were-stolen': [claimsKeys.property.OBJECT_DESCRIPTION, getStolenPartsValueProxy],
  'bicycle-theft_stolen-accessoires-list': [claimsKeys.property.OBJECT_DESCRIPTION, getStolenItemsValueProxy],
  'bicycle-theft_stolen-parts-rateable': [claimsKeys.property.LIST_OF_STOLEN_GOODS, getYesNoValue],
  'bicycle-theft_stolen-items-values': claimsKeys.property.listOfStolenGoods.GOODS_1,
  'bicycle-theft_stolen-accessoires-value': claimsKeys.property.listOfStolenGoods.GOODS_1,
  'bicycle-theft_stolen-ebike-value': claimsKeys.property.PRICE,
  'bicycle-theft_bike-description': claimsKeys.property.MODEL_DESCRIPTION,
};
