import React from 'react';
import PropTypes from 'prop-types';

import Select from '../Select/Select';

const SelectNumber = (props) => (
  <Select {...props} isNumberQuestion />
);

SelectNumber.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  goForward: PropTypes.func,
};

SelectNumber.defaultProps = {
  localePath: '',
  data: '',
  options: [],
  onChange: () => {},
  goForward: () => {},
};

export default SelectNumber;
