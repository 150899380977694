import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Rating from './Rating';
import styles from './Card.styles.jss';

const Card = ({ classes, localePath }) => (
  <div className={classes.container}>
    <Rating localePath={localePath} />
  </div>
);
Card.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
};

Card.defaultProps = {
  classes: {},
  localePath: '',
};

export default injectSheet(styles)(Card);
