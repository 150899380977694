import claimCommon from './claimCommon';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData, getPageType, getWhatHappened } from '../../../helpers/formData/collisionAbroadFormData';

const collisionAbroadTransform = formData => {
  const claimData = {
    ...claimCommon,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data: {
      ...data,
      ...getPageType(data),
      ...getWhatHappened(data),
    },
    serviceType: moduleNames.COLLISION_ABROAD,
    ...additionalData,
  };
};

export default collisionAbroadTransform;
