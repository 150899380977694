import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getContactData, getSMelder } from '../../../helpers/formData/assetLiabilityFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ContactDetails = ({ formData, localePath, className }, { t }) => {
  const data = getContactData(formData);

  const isSMelder = getSMelder(formData);
  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={
      isSMelder ? t(`${localePath}.contact.title`)
        : t(`${localePath}.contact.title-makler`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact.content-name`, {
          firstName: data.firstName,
          lastName: data.lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact.content-phone`, {
          phone: data.phone,
        })}
      </p>
      <p>
        {t(`${localePath}.contact.content-email`, {
          email: data.email,
        })}
      </p>
    </Details>
  );
};

ContactDetails.propTypes = legalConsentProps.sectionProps;
ContactDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetails;
