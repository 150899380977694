const styles = () => ({
  spacer: {
    height: '60px',
    width: '100%',
  },
  firstHeader: {
    paddingTop: '60px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'block',
  },
  list: {
    marginLeft: '20px',
  },
  footerFixer: {
    position: 'fixed',
    bottom: '0px',
  },
});

export default styles;
