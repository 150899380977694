import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const PersonContactData = props => (
  <ErgoDataForm {...props} formType={formTypes.CONTACT_PERSON} />
);

PersonContactData.propTypes = {
  localePath: PropTypes.string,
  isValid: PropTypes.bool,
  buttonText: PropTypes.string,
};

PersonContactData.defaultProps = {
  localePath: '',
  isValid: true,
  buttonText: '',
};

PersonContactData.contextTypes = {
  t: PropTypes.func,
};

export default PersonContactData;
