import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ToggleButton from '../../../components/ToggleButton';
import styles from './styles.jss';

const RepairShopCard = ({
  name, addressStreet, addressCity, distance, selected, onSelected, classes,
}) => (
  <div className={`esc_col esc_col-12 ${classes.container}`}>
    <ToggleButton
      onClick={onSelected}
      selected={selected}
    >
      <div className={classes.wrapper}>
        <div className={classes.topLabelContainer}>
          <div className={classes.name}>{name}</div>
          <div className={classes.distance}>{distance}</div>
        </div>
        <div className={classes.street}>{addressStreet}</div>
        <div className={classes.street}>{addressCity}</div>
      </div>
    </ToggleButton>
  </div>
);
RepairShopCard.propTypes = {
  name: PropTypes.string,
  addressStreet: PropTypes.string,
  addressCity: PropTypes.string,
  distance: PropTypes.string,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
};
RepairShopCard.defaultProps = {
  name: '',
  addressStreet: '',
  addressCity: '',
  distance: '',
  selected: false,
  onSelected: () => {},
  classes: {},
};

export { RepairShopCard as RepairShopCardPlain };
export default injectSheet(styles)(RepairShopCard);
