const styles = theme => ({
  container: {
    '& .ee_radio-tile': {
      padding: '4px 10px !important',
    },
    '& .ee_radio-tile__content': {
      minHeight: 'auto !important',
    },
    borderWidth: '2px !important',
  },

  wrapper: {
    textAlign: 'left',
    padding: '10px 20px',
  },

  topLabelContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },

  distance: {
    minWidth: '60px',
    color: theme.colors.themeAdditional1,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },

  name: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.themeDarkestGray,
    lineHeight: '23px',
  },

  street: {
    color: theme.colors.themeDarkerGray,
    fontSize: '14px',
    lineHeight: '21px',
  },

});

export default styles;
