import React from 'react';
import PropTypes from 'prop-types';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import DatePickerInput from '../DatePickerInput';
import ErgoTextArea from '../../components/ErgoTextArea';

const DateWithLongText = (props, { t }) => {
  const {
    localePath,
    getValidationProps,
  } = props;
  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-s-5 esc_col-m-4">
          <DatePickerInput
            {...getValidationProps('date')}
            type="text"
            description={t(`${localePath}.date.description`)}
            placeholder={t(`${localePath}.date.placeholder`)}
          />
        </div>
        <div className="esc_col esc_col-12 esc_col-s-7 esc_col-m-8">
          <ErgoTextArea
            {...getValidationProps('describeConcern')}
            minRows={13}
            placeholder={t(`${localePath}.describe-concern.placeholder`)}
            description={t(`${localePath}.describe-concern.description`)}
          />
        </div>
      </div>
    </div>
  );
};


DateWithLongText.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
};

DateWithLongText.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
};

DateWithLongText.contextTypes = {
  t: PropTypes.func,
};

export { DateWithLongText as DateWithLongTextPlain };
export default withMultiFieldValidation(DateWithLongText);
