import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SvgIcon from '@eg/elements/SvgIcon';
import { ErgoElementsContext } from '@eg/elements/Provider';
import MessageBox from '@eg/elements/MessageBox';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import { moduleNames } from '../../services/claimData/claimData';
import { setNavigationState, navigateToPath } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';
import ErgoMaskedText from '../ErgoMaskedText';
import { maskTypes } from '../../helpers/masks';
import { NAV_DELAY } from '../../helpers/navigation';
import { customerDataUrl } from '../../helpers/customerData';
import styles from './styles.jss';
import Stars from '../../assets/icons/Stars';

const getLastUrlPath = url => url.split('/').slice(-1).pop();

const ErgoLicenseNumberVehicle = ({
  localePath,
  classes,
  getValidationProps,
  data,
  goForward,
  goBack,
  history,
}, { t }) => {
  const { location: { state: { from } } } = history;
  const path = getLastUrlPath(from);
  const vehicleData = customerDataUrl[moduleNames.VEHICLE];

  if (data.claimNumber && vehicleData.beforeSpecialClaimNumber.includes(path)) {
    goForward();
  }

  if (data.claimNumber && vehicleData.afterSpecialClaimNumber.includes(path)) {
    goBack();
  }

  const formatObject = {
    L: {
      validate: (char) => /[a-zA-ZäöüÄÖÜß]/.test(char),
      transform: (char) => char.toUpperCase(),
    },
  };
  const mockedConfigs = [
    {
      arrayKey: 0,
      mask: maskTypes.LICENSE_NUMBER_PART_1,
      className: classes.part1,
      formatCharacters: formatObject,
      placeholder: t(`${localePath}.placeholders.label1`),
      validationProps: 'part1',
    },
    {
      arrayKey: 1,
      mask: maskTypes.LICENSE_NUMBER_PART_2,
      className: classes.part2,
      formatCharacters: formatObject,
      placeholder: t(`${localePath}.placeholders.label2`),
      validationProps: 'part2',
    },
    {
      arrayKey: 2,
      mask: maskTypes.LICENSE_NUMBER_PART_3,
      className: classes.part3,
      placeholder: t(`${localePath}.placeholders.label3`),
      validationProps: 'part3',
    },
  ];
  return (
    <div className="esc_grid">
      <div className={`esc_grid__wrapper ${classes.wrapper}`}>
        {data.claimNumber ? (
          <MessageBox className={classes.messageBox}>
            <div className={classes.iconWrapper}>
              <InfoIcon width={24} height={24} />
            </div>
            <span>
              {t(`${localePath}.explanation-box.title`)}
              <br />
              <b>
                {t(`${localePath}.explanation-box.body`)}
              </b>
            </span>
          </MessageBox>
        ) : (
          <>
            <div className={`esc_col esc_col-12 ${classes.headLabel}`}>
              {t(`${localePath}.placeholders.headLabel`)}
            </div>
            <div className={`${classes.rectangle}`}>
              <ErgoElementsContext.Consumer>
                {({ idGenerator }) => (
                  <SvgIcon
                    idGenerator={idGenerator}
                    viewBox="0 0 15 14"
                    title={t('content.main-title')}
                    width={15}
                    height={14}
                    className={classes.icon}
                  >
                    <Stars />
                  </SvgIcon>
                )}
              </ErgoElementsContext.Consumer>
              <span className={classes.letter}>D</span>
            </div>
            {mockedConfigs.map(configInfo => (
              <div key={configInfo.arrayKey} className="esc_col-3 esc_col-s-2">
                <ErgoMaskedText {...configInfo} {...getValidationProps(configInfo.validationProps)} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

ErgoLicenseNumberVehicle.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  getValidationProps: PropTypes.func,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  data: PropTypes.shape({
    part1: PropTypes.string,
    part2: PropTypes.string,
    part3: PropTypes.string,
    claimNumber: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
  }),
};

ErgoLicenseNumberVehicle.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => { },
  goForward: () => { },
  goBack: () => { },
  data: { },
  history: {
    location: {
      state: {
        from: '',
      },
    },
  },
};

ErgoLicenseNumberVehicle.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

const NAV_DELAY_LONG = NAV_DELAY * 8;

const mapDispatchToProps = (dispatch) => ({
  goForward: () => {
    dispatch(setNavigationState(true, true));
    dispatch(navigateToPath(
      NAV_DIRECTIONS.NEXT,
      NAV_DELAY_LONG,
    ));
  },
  goBack: () => {
    dispatch(setNavigationState(true, true));
    dispatch(navigateToPath(
      NAV_DIRECTIONS.PREVIOUS,
      NAV_DELAY_LONG,
    ));
  },
});

export { ErgoLicenseNumberVehicle as ErgoLicenseNumberVehiclePlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
  withRouter,
)(ErgoLicenseNumberVehicle);
