import React from 'react';
import PropTypes from 'prop-types';
import ErgoDataForm from '../../components/ErgoDataForm';
import { formTypes } from '../../models/form';

const ErgoIban = props => (
  <ErgoDataForm {...props} formType={formTypes.IBAN} />
);


ErgoIban.propTypes = {
  localePath: PropTypes.string,
  buttonText: PropTypes.string,
  isValid: PropTypes.bool,
};

ErgoIban.defaultProps = {
  localePath: '',
  buttonText: '',
  isValid: true,
};

export default ErgoIban;
