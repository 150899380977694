import React from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ErgoElementsContext } from '@eg/elements/Provider';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import { multipleSelectCircumstanceProps } from '../../models/panels';
import { types } from '../../actions/constants';
import ErgoInputText from '../../components/ErgoInputText';
import ErgoCheckbox from '../../components/ErgoCheckbox';
import styles from './styles.jss';

class MultipleSelectCircumstance extends React.PureComponent {
  constructor(props) {
    super();
    const { describeDamage } = props.data;
    this.state = {
      describeDamage: describeDamage || ' ',
    };
  }

  componentDidUpdate(prevProps) {
    const { data, onChange, name } = this.props;
    const target = {
      name,
      value: data,
    };

    if (
      prevProps.data.typesOfItemDamage !== data.typesOfItemDamage
      || prevProps.data.describeDamage !== data.describeDamage
    ) {
      onChange({ target });
    }
  }

  handleClick = value => {
    const {
      pathName, name, updateField, data,
    } = this.props;
    const { describeDamage } = this.state;

    const isDifferent = value === multipleSelectCircumstanceProps.optionTypes.DIFFERENT;
    const isDifferentIncluded = data.typesOfItemDamage.includes(multipleSelectCircumstanceProps.optionTypes.DIFFERENT);
    const setDefaultValue = !isDifferent ? describeDamage : '';
    const description = {
      describeDamage: (isDifferent && isDifferentIncluded) ? ' ' : setDefaultValue,
    };
    this.setState({ ...description });

    updateField(name, description.describeDamage, pathName, multipleSelectCircumstanceProps.dataType.DESCRIPTION, true);
    updateField(name, value, pathName, multipleSelectCircumstanceProps.dataType.TYPES_OF_ITEM_DAMAGE);
  };

  handleDescriptionChange = e => {
    const { name, pathName, updateField } = this.props;
    const { value } = e.target;
    this.setState({ describeDamage: value });
    updateField(name, value, pathName, multipleSelectCircumstanceProps.dataType.DESCRIPTION, true);
  };

  renderLabel = option => {
    const { classes, localePath } = this.props;
    const { t } = this.context;
    let iconDisabled = false;
    if (option.icon === 'disabled') {
      iconDisabled = true;
    }

    const Icon = multipleSelectCircumstanceProps.icons[option.icon];

    return (
      <div className={classes.labelWrapper}>
        { !iconDisabled && (
        <span className={classes.icon}>
          <ErgoElementsContext.Consumer>
            {({ idGenerator }) => (
              <Icon
                idGenerator={idGenerator}
                width={32}
                height={32}
              />
            )}
          </ErgoElementsContext.Consumer>
        </span>
        )}
        {t(`${localePath}.options.${option.key}`)}
      </div>
    );
  };

  renderOption = (option) => {
    const { data, getValidationProps } = this.props;
    const { onChange } = getValidationProps(multipleSelectCircumstanceProps.dataType.TYPES_OF_ITEM_DAMAGE);

    return (
      <div>
        <ErgoCheckbox
          key={option.key}
          onClick={() => this.handleClick(option.key)}
          onChange={onChange}
          label={this.renderLabel(option)}
          checked={data.typesOfItemDamage.includes(option.key)}
          displayError={false}
        />
      </div>
    );
  };

  render() {
    const {
      localePath, firstCol, middleCol, lastCol, data, getValidationProps, classes,
    } = this.props;
    const { t } = this.context;
    const { describeDamage } = this.state;
    const { error } = getValidationProps(multipleSelectCircumstanceProps.dataType.DESCRIPTION);
    const hasDifferent = data.typesOfItemDamage.includes(multipleSelectCircumstanceProps.optionTypes.DIFFERENT);

    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className={`esc_col esc_col-12 esc_col-s-6 esc_col-m-4 ${classes.option} ${classes.order}`}>
            {firstCol.map(option => this.renderOption(option))}
          </div>
          <div className={`esc_col esc_col-12 esc_col-s-6 esc_col-m-4 ${classes.option}`}>
            {middleCol.map(option => this.renderOption(option))}
          </div>
          <div className={`esc_col esc_col-12 esc_col-s-6 esc_col-m-4 ${classes.option}`}>
            {lastCol.map(option => this.renderOption(option))}
          </div>
          {hasDifferent && (
            <div className={`esc_col esc_col-12 ${classes.lastOption}`}>
              <ErgoInputText
                onChange={this.handleDescriptionChange}
                value={describeDamage}
                error={error}
                placeholder={t(`${localePath}.describe-damage.placeholder`)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

MultipleSelectCircumstance.propTypes = multipleSelectCircumstanceProps.props;
MultipleSelectCircumstance.defaultProps = multipleSelectCircumstanceProps.defaults;
MultipleSelectCircumstance.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName, subName, isString) => {
    dispatch({
      type: types.FIELD_UPDATE_MULTI,
      fieldName: name,
      pathName,
      payload,
      subName,
      isString,
    });
  },
});

export { MultipleSelectCircumstance as MultipleSelectCircumstancePlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectJss(styles),
  withMultiFieldValidation,
)(MultipleSelectCircumstance);
