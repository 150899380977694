const styles = ({ breakpoints }) => ({
  overrideIconStyle: {
    '& .ee_date-input-field__calendar-icon-wrapper': {
      padding: '13px 0 0 !important',
      margin: '0 6px 0 !important',
      height: 'initial',
    },
  },
  mobileContainer: {
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      // this margin tweak forces the calendar to open towards the bottom, solves the UI problem on mobile screens
      margin: '0 20px',
    },
  },
});

export default styles;
