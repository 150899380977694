import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Radio from '@eg/elements/Radio';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import withSizes from 'react-sizes';
import { accidentDateSelector } from '../../helpers/selectors';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import DatePickerInput from '../DatePickerInput';
import { INVALID_DATE } from '../../services/claimData/claimData.helpers';
import { convertToString } from '../DatePickerInput/convertFunctions';
import { mapSizesToProps } from '../../helpers/mapSizesToProp';
import useRect from '../../helpers/useRect';
import styles from './styles.jss';

const DateHospital = (props, { t }) => {
  const {
    localePath,
    getValidationProps,
    classes,
    accidentDate,
    isMobile,
  } = props;

  const TO = 'to';
  const DATE = 'date';

  const isDateToValueDisabled = () => getValidationProps(TO).value === INVALID_DATE;
  const [resetKeyForDatePickerTo, setResetKeyForDatePickerTo] = useState(0);
  const [disabled, setDisabled] = useState(isDateToValueDisabled());

  const storedDateFrom = getValidationProps(DATE).value;
  const [dateFrom, setDateFrom] = useState(storedDateFrom ? moment(storedDateFrom, 'DD.MM.YYYY').toDate() : null);

  const storedDateTo = getValidationProps(TO).value;
  const [dateTo, setDateTo] = useState(storedDateTo ? moment(storedDateTo, 'DD.MM.YYYY').toDate() : null);
  const [errorFrom, setErrorFrom] = useState('');
  const [errorTo, setErrorTo] = useState('');

  const lock = (type, value) => {
    const { onChange, name } = getValidationProps(type);
    onChange(name)(value);
  };

  useEffect(() => {
    if ((errorTo === '' && dateTo) && (errorFrom === '' && dateFrom)) {
      lock(TO, convertToString(dateTo));
      lock(DATE, convertToString(dateFrom));
    }
  }, [errorTo, errorFrom]);

  useEffect(() => {
    if (disabled) {
      setResetKeyForDatePickerTo(resetKeyForDatePickerTo + 1);
      lock(TO, INVALID_DATE);
      setDateTo(null);
      setErrorTo('');
      if (errorFrom === 'validation-rules.invalid-date-order-begin') {
        setErrorFrom('');
      }
    } else if (isDateToValueDisabled()) {
      lock(TO, '');
    }
  }, [disabled]);

  useEffect(() => {
    if (!dateFrom) return;

    if (dateTo) {
      if (moment(dateFrom).isAfter(dateTo, 'day')) {
        lock(TO, '');
        setErrorFrom('validation-rules.invalid-date-order-begin');
        if (isDateToValueDisabled()) {
          lock(TO, INVALID_DATE);
          setErrorTo('');
          setErrorFrom('');
        }
        return;
      }
    }

    if (moment().diff(dateFrom, 'years') > 10) {
      lock(DATE, '');
      setErrorFrom('validation-rules.invalid-date-ten-years');
      return;
    }

    if (moment(dateFrom).isBefore(accidentDate, 'day')) {
      lock(DATE, '');
      setErrorFrom('validation-rules.invalid-date-before-accident');
      return;
    }

    setErrorFrom('');
    if (errorTo === 'validation-rules.invalid-date-order-end') {
      setErrorTo('');
    }
  }, [dateFrom]);

  useEffect(() => {
    if (!dateTo) return;

    if (moment(dateTo).isBefore(accidentDate, 'day')) {
      lock(TO, '');
      setErrorTo('validation-rules.invalid-date-before-accident');
      return;
    }

    if (dateFrom) {
      if (moment(dateFrom).isAfter(dateTo, 'day')) {
        lock(TO, '');
        setErrorTo('validation-rules.invalid-date-order-end');
        if (isDateToValueDisabled()) {
          lock(TO, INVALID_DATE);
          setErrorTo('');
          setErrorFrom('');
        }
        return;
      }
    }

    setErrorTo('');
    if (errorFrom === 'validation-rules.invalid-date-order-begin') {
      setErrorFrom('');
    }
  }, [dateTo]);

  const [rectDatePickerTo, setRectDatePickerTo] = useRect();
  const bis = (
    <>
      <Radio className={classes.item} label="" value="1" checked={!disabled} onClick={() => setDisabled(false)} />
      <div className={classes.datePickerContainer} ref={setRectDatePickerTo}>
        <DatePickerInput
          description={t(`${localePath}.labels.toDescription`)}
          type="text"
          placeholder={t(`${localePath}.labels.toLabel`)}
          disabled={disabled}
          {...getValidationProps(TO)}
          error={errorTo}
          onChangeOptional={setDateTo}
          resetKey={resetKeyForDatePickerTo}
        />
      </div>
    </>
  );

  return (
    <>
      <div className={classes.fromLabel} />

      <div className={classes.container}>
        <div className={classes.item}>
          {t(`${localePath}.labels.dateName`)}
:
        </div>
        <div className={classes.item} />
        <div
          className={classes.datePickerContainer}
          style={isMobile && rectDatePickerTo
            ? { width: rectDatePickerTo.width, alignSelf: 'flex-end' } : null}
        >
          <DatePickerInput
            label=""
            description={t(`${localePath}.labels.dateDescription`)}
            type="text"
            placeholder={t(`${localePath}.labels.dateLabel`)}
            {...getValidationProps(DATE)}
            onChangeOptional={setDateFrom}
            error={errorFrom}
          />
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.item}>
          {t(`${localePath}.labels.toName`)}
:
        </div>

        {isMobile ? (
          <div className={classes.containerBisMobile}>
            {bis}
          </div>
        ) : (
          <>{bis}</>
        )}

        <Radio
          className={classes.item}
          label={t(`${localePath}.labels.toDisabled`)}
          value="0"
          checked={disabled}
          onClick={() => setDisabled(true)}
        />
      </div>

    </>
  );
};


DateHospital.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
  classes: PropTypes.objectOf(Object),
  accidentDate: PropTypes.objectOf(Date).isRequired,
  isMobile: PropTypes.bool,
};

DateHospital.defaultProps = {
  localePath: '',
  classes: {},
  getValidationProps: () => {},
  isMobile: false,
};

DateHospital.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }) => ({ accidentDate: accidentDateSelector(formData) });

export { DateHospital as DateHospitalPlain };
export default compose(
  withSizes(mapSizesToProps),
  connect(
    mapStateToProps,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
)(DateHospital);
