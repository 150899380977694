import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import { circumstanceProps } from '../../models/panels';
import { types } from '../../actions/constants';
import Toggle from '../../components/Toggle';
import ErgoInputText from '../../components/ErgoInputText';
import { getSign } from '../../helpers/sections';

class Circumstance extends React.PureComponent {
  constructor(props) {
    super();
    const { circumstance, describeCircumstance } = props.data;
    this.state = {
      describeCircumstance,
      toggleDescribeCircumstance: circumstanceProps.optionTypes.DIFFERENT === circumstance,
    };
  }

  componentDidUpdate(prevProps) {
    const { data, onChange, name } = this.props;
    const target = {
      name,
      value: data,
    };

    if (
      prevProps.data.circumstance !== data.circumstance
      || prevProps.data.describeCircumstance !== data.describeCircumstance
    ) {
      onChange({ target });
    }
  }

  handleCircumstanceChange = value => () => {
    const { pathName, name, updateField } = this.props;
    const isDifferent = value === circumstanceProps.optionTypes.DIFFERENT;
    const description = {
      describeCircumstance: isDifferent ? '' : ' ',
      toggleDescribeCircumstance: isDifferent,
    };

    this.setState({ ...description });
    updateField(name, description.describeCircumstance, pathName, circumstanceProps.dataType.DESCRIPTION, true);
    updateField(name, value, pathName, circumstanceProps.dataType.CIRCUMSTANCE, true);
  };

  handleDescriptionChange = e => {
    const { name, pathName, updateField } = this.props;
    const { value } = e.target;
    this.setState({ describeCircumstance: value });
    updateField(name, value, pathName, circumstanceProps.dataType.DESCRIPTION, true);
  };

  render() {
    const {
      localePath, options, data, yesNoQuestion, getValidationProps,
    } = this.props;
    const { t } = this.context;
    const { describeCircumstance, toggleDescribeCircumstance } = this.state;
    const { error } = getValidationProps(circumstanceProps.dataType.DESCRIPTION);

    return (
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className="esc_col esc_col-12">
            {options.map(({ key }, index) => (
              <Toggle
                key={key}
                index={index}
                option={key}
                onClick={this.handleCircumstanceChange(key)}
                selected={data.circumstance === key}
                sign={getSign(index, yesNoQuestion, t)}
                toggleText={t(`${localePath}.options.${key}`)}
              />
            ))}
          </div>
          {toggleDescribeCircumstance && (
            <div className="esc_col esc_col-12">
              <ErgoInputText
                onChange={this.handleDescriptionChange}
                value={describeCircumstance}
                error={error}
                description={t(`${localePath}.describe-circumstance.description`)}
                placeholder={t(`${localePath}.describe-circumstance.placeholder`)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Circumstance.propTypes = circumstanceProps.props;

Circumstance.defaultProps = circumstanceProps.defaults;

Circumstance.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName, subName, isString) => {
    dispatch({
      type: types.FIELD_UPDATE_MULTI,
      fieldName: name,
      pathName,
      payload,
      subName,
      isString,
    });
  },
});

export { Circumstance as CircumstancePlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withMultiFieldValidation,
)(Circumstance);
