import claimPerson from './claimPerson';
import claimBusiness from './claimBusiness';
import claimKeyLoss from './claimKeyLoss';
import claimAnimal from './claimAnimal';
import claimLiability from './claimLiability';
import claimCommon from './claimCommon';

import privateLiabilityFlows from '../../../models/dataMappings/privateLiabilityFlows';
import { getWhatHappened, getPageType, getAdditionalData } from '../../../helpers/formData/privateLiabilityFormData';
import { claimDataTransform, getConditionalData, PARTY } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { claimsKeys, COMPANY_SALUTATION } from '../../../helpers/formData/formData';

const conditionalClaimKeys = [
  {
    conditionTarget: claimsKeys.thirdParty.coinsured_contact_data.LAST_NAME,
    conditionValue: 'text_input',
    resultTarget: claimsKeys.thirdParty.coinsured_contact_data.COMPANY_NAME,
    resultValue: [claimsKeys.thirdParty.coinsured_contact_data.LAST_NAME],
  },
];

const assignClaimData = (whoFills, whatHappened) => {
  if (whoFills === PARTY) {
    return claimLiability;
  }

  switch (whatHappened) {
    case privateLiabilityFlows.ANIMAL_DAMAGE:
      return claimAnimal;
    case privateLiabilityFlows.PERSON_DAMAGE:
      return claimPerson;
    case privateLiabilityFlows.KEY_LOSS:
      return claimKeyLoss;
    case privateLiabilityFlows.BUSINESS:
      return claimBusiness;
    default:
      return {};
  }
};

const isCompanyCoinsuredSelected = (formData) => formData[claimsKeys.thirdParty.coinsured_contact_data.SALUTATION]
=== COMPANY_SALUTATION;

const privateLiabilityTransform = formData => {
  let claimData = assignClaimData(formData['who-fills'], formData['what-happened']);

  claimData = {
    ...claimCommon,
    ...claimData,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data: {
      ...data,
      ...(isCompanyCoinsuredSelected(data) ? getConditionalData(data, conditionalClaimKeys) : {}),
      ...getWhatHappened(data),
      ...getPageType(data),
    },
    serviceType: moduleNames.PRIVATE_LIABILITY,
    ...additionalData,
  };
};

export default privateLiabilityTransform;
