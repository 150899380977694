import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants';

const styles = () => ({
  options: {
    textAlign: 'left',
    ...mediaQuery.max(breakpoints.mediumMobile, {
      alignItems: 'inherit!important',
    }),
  },

  radioButton: {
    marginRight: '20px',
  },

  agreementWrapper: {
    flexBasis: '100%',
  },

  benefitOverWrites: {
    textAlign: 'left',
    ...mediaQuery.max(breakpoints.mediumMobile, {
      width: '90%',
      margin: 'auto',
    }),
  },
  cardOverWrites: {
    boxShadow: 'none!important',
    width: '100%',
  },
});

export default styles;
