import { getOneFromMultiProxy, getYesNoValue } from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { theftOptions } from '../../../helpers/formData/propertyFormData';

const mappingPoliceIsReported = [claimsKeys.POLICE, getYesNoValue];

const mappingPoliceReportDetails = {
  department: claimsKeys.DEPARTMENT,
  caseNumber: claimsKeys.CASE_NUMBER,
};

const mappingHaveYouList = [claimsKeys.property.LIST_OF_STOLEN_GOODS, getYesNoValue];

export default {
  theft_circumstances_options: [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'bicycle-theft': theftOptions.BICYCLE,
      burglary: theftOptions.BURGLARY,
      'attempted-burglary': theftOptions.ATTEMPTED_BURGLARY,
      robbery: theftOptions.ROBBERY,
      'locked-vehicle': theftOptions.VEHICLE,
      graffiti: theftOptions.GRAFFITI,
      different: theftOptions.OTHER,
    }),
  ],
  'theft_circumstances-building_options': [
    claimsKeys.property.CASE,
    getOneFromMultiProxy({
      'bicycle-theft': theftOptions.BICYCLE,
      burglary: theftOptions.BURGLARY,
      graffiti: theftOptions.GRAFFITI,
      different: theftOptions.OTHER,
    }),
  ],
  'theft_circumstances_police-report_is-reported': mappingPoliceIsReported,
  'theft_circumstances-building_police-report_is-reported': mappingPoliceIsReported,
  'theft_circumstances_police-report_report-details': mappingPoliceReportDetails,
  'theft_circumstances-building_police-report_report-details': mappingPoliceReportDetails,
  'theft_have-you-list': mappingHaveYouList,
};
