import {
  getPhoneValue,
  getSalutationValue,
  getLastNameValue,
  getFirstNameValue,
  getDateValueProxy,
  getCountryCode,
  padPolicyNumber,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';

export default {
  'liability_event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'liability_event-circumstances_description': claimsKeys.CIRCUMSTANCES,
  'liability_non-ergo-id_policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'liability_non-ergo-id_customer_contact': {
    who: [claimsKeys.customer.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.customer.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.customer.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.customer.PHONE, getPhoneValue],
  },
  'liability_non-ergo-id_customer_location': {
    address: claimsKeys.customer.ADDRESS,
    postCode: claimsKeys.customer.POST_CODE,
    city: claimsKeys.customer.CITY,
    country: [claimsKeys.customer.COUNTRY_CODE, getCountryCode],
  },
  'liability_contact-details_contact': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
  },
  'liability_contact-details_location': {
    address: claimsKeys.owner.ADDRESS,
    postCode: claimsKeys.owner.POST_CODE,
    city: claimsKeys.owner.CITY,
    country: [claimsKeys.owner.COUNTRY_CODE, getCountryCode],
  },
};
