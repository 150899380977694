const styles = theme => ({
  nowrap: {
    justifyContent: 'space-between',
    paddingTop: '80px',
  },
  [`@media (max-width: ${theme.breakpoints.breakpointM})`]: {
    nowrap: {
      flexWrap: 'nowrap !important',
      paddingTop: '40px !important',
    },
  },


});

export default styles;
