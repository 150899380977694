const animationTime = '0.5s';
const styles = {
  headerEnter: {
    display: 'block',
    position: 'absolute',
    top: '-120px',

    transition: `top ${animationTime} ease-in-out`,
  },

  headerEnterActive: {
    top: 0,
  },

  headerLeave: {
    display: 'block',
    position: 'absolute',
    top: 0,

    transition: `top ${animationTime} ease-in-out`,
  },

  headerLeaveActive: {
    top: '-120px',
  },

  navigationEnter: {
    transition: `opacity ${animationTime} ease-in-out`,
    opacity: 0,
  },

  navigationEnterActive: {
    opacity: 1,
  },

  navigationLeave: {
    transition: `opacity ${animationTime} ease-in-out`,
    opacity: 1,
  },

  navigationLeaveActive: {
    opacity: 0,
  },

  taskManagerEnter: {
    top: '26px',
  },

  taskManagerEnterActive: {
    top: '26px',
  },

  taskManagerLeave: {
    top: '26px',
  },

  taskManagerLeaveActive: {
    top: '26px',
  },
};

export default styles;
