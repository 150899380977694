import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'recompose';

import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';

import { multipleSelectWithCommentProps } from '../../models/panels';

import ErgoTextArea from '../../components/ErgoTextArea';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ToggleButton from '../../components/ToggleButton';

import styles from './styles.jss';


class MultipleSelectWithComment extends Component {
  state = {
    typesList: [],
  };

  componentDidMount() {
    const {
      values,
      name,
      options,
    } = this.props;

    const typesListInitial = this.transformOptionsToTypes(options);

    if (values[name] && values[name].typesOfGlassDamage) {
      const jsonDecoded = JSON.parse(values[name].typesOfGlassDamage);
      const typesList = this.mapValuesToState(jsonDecoded, typesListInitial);
      this.setState({
        typesList,
      });
    }
  }

  mapValuesToState = (values, initial) => {
    const mapped = initial;
    values.forEach(initialValue => {
      if (Object.hasOwnProperty.call(initial, initialValue)) {
        mapped[initialValue] = true;
      }
    });
    return mapped;
  }

  handleClick = value => ev => {
    ev.preventDefault();
    this.setState(prevState => {
      const newValue = {
        typesList: {
          ...prevState.typesList,
        },
      };

      newValue.typesList[value] = !prevState.typesList[value];

      this.setType(value, newValue);
      return newValue;
    });
  }

  /* Object.entries on data.type gets you array of key value pairs of toggled options
  * value[0] is object property name ie. front-window
  * value[1] is boolean value if that index is toggled on or off
  */
  decodeStateTypeToData = data => Object.entries(data.typesList)
    .reduce((aggr, value) => {
      if (value[1] === true) {
        aggr.push(value[0]);
      }
      return aggr;
    }, []);

  setType = (value, typeList) => {
    const {
      getValidationProps,
    } = this.props;
    const {
      onChange,
      name: fieldName,
    } = getValidationProps('typesOfGlassDamage');

    const decoded = this.decodeStateTypeToData(typeList);
    const dataToSet = decoded.length ? JSON.stringify(decoded) : '';

    onChange(fieldName)(dataToSet);
  };

  transformOptionsToTypes = options => options.reduce((aggr, value) => {
    const newValue = {};
    newValue[value.key] = false;
    return Object.assign({}, aggr, newValue);
  }, {});

  render() {
    const {
      localePath,
      options,
      classes,
      getValidationProps,
    } = this.props;

    const {
      typesList,
    } = this.state;

    const { t } = this.context;

    return (
      <ContentWrapper>
        <div className="esc_grid">
          <div className="esc_grid__wrapper">
            {options && options.map(
              ({ key, icon }, index) => {
                const IconComponent = multipleSelectWithCommentProps.icons[icon] || null;
                return (
                  <div key={key} className="esc_col-6 esc_col-s-6 esc_col-m-4 esc_col-l-3">
                    <ToggleButton
                      index={index}
                      option={key}
                      onClick={this.handleClick(key)}
                      selected={typesList[key]}
                    >
                      <div className={classes.wrapper}>
                        <IconComponent className={classes.icon} />
                        <div className={classes.toggleLabel}>{t(`${localePath}.labels.${key}`)}</div>
                      </div>
                    </ToggleButton>
                  </div>
                );
              },
            )}
            <div className="esc_col esc_col-12 esc_col-m-12">
              <ErgoTextArea
                placeholder={t(`${localePath}.placeholders.describeDamage`)}
                minRows={13}
                {...getValidationProps('describeDamage')}
              />
            </div>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}
MultipleSelectWithComment.propTypes = multipleSelectWithCommentProps.props;

MultipleSelectWithComment.defaultProps = multipleSelectWithCommentProps.defaults;

MultipleSelectWithComment.contextTypes = {
  t: PropTypes.func,
};

export { MultipleSelectWithComment as MultipleSelectWithCommentPlain };

export default compose(
  withMultiFieldValidation,
  injectSheet(styles),
)(MultipleSelectWithComment);
