const styles = theme => ({
  container: {
    textAlign: 'left',
  },
  icon: {
    color: theme.colors.extendedIconBlack,
  },
});

export default styles;
