import {
  getOneFromMultiProxy,
  getDateValueProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  occupationWorkOptions,
  occupationEmployeeEmployerOptions,
} from '../../../helpers/formData/legalProtectionFormData';

export default {
  occupation_work: [
    claimsKeys.legalProtection.CATEGORY,
    getOneFromMultiProxy({
      employees: occupationWorkOptions.EMPLOYEES,
      employer: occupationWorkOptions.EMPLOYER,
    }),
  ],
  'occupation_employee-employer': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      dismissal: occupationEmployeeEmployerOptions.DISMISSAL,
      'wage-claim': occupationEmployeeEmployerOptions.WAGE_CLAIM,
      certificate: occupationEmployeeEmployerOptions.CERTIFICATE,
      'warning-letter': occupationEmployeeEmployerOptions.WARNING_LETTER,
      different: occupationEmployeeEmployerOptions.DIFFERENT,
    }),
  ],
  occupation_dismissal: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'occupation_wage-claim': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  occupation_certificate: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  'occupation_warning-letter': {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
  occupation_different: {
    date: [claimsKeys.DATE, getDateValueProxy],
    describeConcern: claimsKeys.CIRCUMSTANCES,
  },
};
