import {
  getPhoneValue,
  getYesNoValue,
  getSalutationValue,
  getLastNameValue,
  getFirstNameValue,
  getDateValueProxy,
  getCountryCode,
  padPolicyNumber,
} from '../claimData.helpers';
import {
  getKeyLossCircumstancesValueProxy,
  getKeyLossGuiltValueProxy,
} from './claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';

export default {
  'event-circumstances_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'event-circumstances_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'key-loss_circumstances': [claimsKeys.CIRCUMSTANCES, getKeyLossCircumstancesValueProxy],
  'key-loss_police-report_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'key-loss_police-report_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'key-loss_further-comments': claimsKeys.NEW_FURTHER_INFORMATION,
  'key-loss_guilt': [claimsKeys.FURTHER_INFORMATION, getKeyLossGuiltValueProxy],
  'policy-holder_policy-number': [claimsKeys.POLICY_NUMBER, padPolicyNumber],
  'policy-holder_contact': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    email: claimsKeys.owner.EMAIL,
  },
  'third-person_contact': {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
  },
  'third-person_location': {
    address: claimsKeys.thirdParty.ADDRESS,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY_CODE, getCountryCode],
  },
  settlement: [claimsKeys.SETTLEMENT, getYesNoValue],
};
