import { types } from '../../actions/constants';

const initialState = {
  isLoading: false,
};

const loader = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case types.LOADER_START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.LOADER_STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default loader;
