import PropTypes from 'prop-types';
import {
  SvgBrilleDamage,
  SvgKompass,
  SvgGeld1,
  SvgStecker,
  SvgKleidung1,
  SvgMoebel,
  SvgFragezeichen1,
  SvgSchließen,
  SvgTuer,
  SvgFensterGlas,
  SvgLadenMarkise,
  SvgWindrad,
  SvgHaus16,
  SvgSpuelmaschine,
  SvgWand,
  SvgBett,
  SvgWasserhahn,
  SvgBildschirm,
  SvgKleeblatt,
  SvgHaus11,
  SvgSatAntenne1,
  SvgDach,
  SvgCarport,
  SvgKollektoren,
  SvgSonstAussenflaeche,
  SvgTeppich,
  SvgCeranKochfeld,
  SvgBackofenKaminscheibe,
  SvgSpiegel,
  SvgKuechenarbeitsplatte,
  SvgDuschkabine,
  SvgUeberdachung,
  SvgFloor,
  SvgHausExternes,
  SvgJewellery,
  SvgFahrrad1,
  SvgOffice,
  SvgAdvertisement,
  SvgCargo,
  SvgPeople,
} from '../../helpers/IconLoader';

export const icons = {
  'door-or-window-panes': SvgBrilleDamage,
  hobs: SvgBrilleDamage,
  'display-screen': SvgBildschirm,
  different: SvgBrilleDamage,
  compass: SvgKompass,
  money: SvgGeld1,
  plug: SvgStecker,
  clothing: SvgKleidung1,
  furniture: SvgMoebel,
  'question-mark': SvgFragezeichen1,
  'shut-down': SvgSchließen,
  door: SvgTuer,
  window: SvgFensterGlas,
  shop: SvgLadenMarkise,
  windmill: SvgWindrad,
  'house-outside-roof': SvgHaus16,
  'dish-washer': SvgSpuelmaschine,
  wall: SvgWand,
  bed: SvgBett,
  'water-tap': SvgWasserhahn,
  plants: SvgKleeblatt,
  'house-outside': SvgHaus11,
  antenna1: SvgSatAntenne1,
  roof: SvgDach,
  carport: SvgCarport,
  collectors: SvgKollektoren,
  'other-outside-area': SvgSonstAussenflaeche,
  carpet: SvgTeppich,
  hob: SvgCeranKochfeld,
  oven: SvgBackofenKaminscheibe,
  mirror: SvgSpiegel,
  'kitchen-counter': SvgKuechenarbeitsplatte,
  shower: SvgDuschkabine,
  roof1: SvgUeberdachung,
  floor: SvgFloor,
  'external-walls': SvgHausExternes,
  'jewellery-watches': SvgJewellery,
  bicycle: SvgFahrrad1,
  office: SvgOffice,
  advertisement: SvgAdvertisement,
  cargo: SvgCargo,
  people: SvgPeople,
};

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  firstCol: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
  })),
  lastCol: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
  })),
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

const defaults = {
  classes: {},
  firstCol: [],
  lastCol: [],
  localePath: '',
  pathName: '',
  name: '',
  data: [],
  onChange: () => {},
  updateField: () => {},
  getValidationProps: () => {},
};

export default {
  props,
  defaults,
  icons,
};
