const styles = theme => ({
  gridWrapperCenter: {
    justifyContent: 'center',
  },
  gridWrapperLeft: {
    justifyContent: 'left',
  },
  messageBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0px !important',
  },
  infoIcon: {
    paddingRight: 8,
    fill: `${theme.colors.themeDarkerGray} !important`,
  },
  messageBoxContent: {
    width: '100%',
    paddingTop: '5px',
    paddingLeft: '5px',
  },
  mobileAccordion: {
    marginBottom: '40px',
  },
  mobileAccordionTitle: {
    color: theme.colors.themePrimary,
  },
  link: {
    color: theme.colors.themePrimary,
    textDecoration: 'none',
  },
  headingPosition: {
    textAlign: 'center',
  },
  badgeHeadingContainer: {
    margin: '30px 0 30px 0',
  },
  badgeHeadingPosition: {
    textAlign: 'left',
    [`@media (max-width: ${theme.breakpoints.breakpointM})`]: {
      textAlign: 'center',
    },
  },
  wrapper: {
    padding: '20px 8px 0',
  },
  toggleLabel: {
    fontSize: '16px',
    width: '100%',
    height: '100%',
    color: theme.colors.themeDarkestGray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
    padding: '20px 0',
  },
  icon: {
    width: '50px !important',
    height: '50px !important',
    color: theme.colors.expandedIconBlack,
  },
});

export default styles;
