import React from 'react';
import PropTypes from 'prop-types';
import RepairShopCard from '../RepairShopCard';

const RepairShopSelect = ({
  workshopList,
  onWorkshopSelectionChange,
  selectedWorkshopId,
}) => {
  const cards = workshopList.map((workshop) => (
    <RepairShopCard
      key={workshop.repairerid}
      name={workshop.name}
      addressStreet={workshop.address}
      addressCity={`${workshop.postalcode} ${workshop.city}`}
      phone={workshop.phone}
      distance={`${workshop.distance} km`}
      onSelected={() => onWorkshopSelectionChange(workshop.repairerid)}
      selected={selectedWorkshopId === workshop.repairerid}
    />
  ));

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        { cards }
      </div>
    </div>
  );
};

RepairShopSelect.propTypes = {
  workshopList: PropTypes.arrayOf(PropTypes.object),
  onWorkshopSelectionChange: PropTypes.func,
  selectedWorkshopId: PropTypes.string,
};

RepairShopSelect.defaultProps = {
  workshopList: [],
  onWorkshopSelectionChange: () => {},
  selectedWorkshopId: '',
};

export { RepairShopSelect as RepairShopSelectPlain };
export default RepairShopSelect;
