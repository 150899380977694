import {
  NO,
  getEmailValue,
  getPhoneValue,
  getYesNoValue,
  getLastNameValue,
  getFirstNameValue,
  getYesNoValueProxy,
  getOneFromMultiProxy,
  getDateValueProxy,
  arrayIncludesKeyAndValueProxy,
  getCountryCode,
  getLicenseNumber,
  stripSpacesFromIban,
  getSalutationValue,
} from '../claimData.helpers';
import { claimsKeys, getCarMapKey } from '../../../helpers/formData/formData';

export default {
  'vandalism_event-circumstances-and-aftermath_day': {
    date: [claimsKeys.DATE, getDateValueProxy],
  },
  'vandalism_event-circumstances-and-aftermath_location': {
    location: claimsKeys.LOCATION,
    country: [claimsKeys.COUNTRY_CODE, getCountryCode],
  },
  'vandalism_event-circumstances-and-aftermath_car-map': {
    describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
    'front-left': [getCarMapKey('front-left'), arrayIncludesKeyAndValueProxy('carMap', 'front-left')],
    'front-middle': [getCarMapKey('front-middle'), arrayIncludesKeyAndValueProxy('carMap', 'front-middle')],
    'front-right': [getCarMapKey('front-right'), arrayIncludesKeyAndValueProxy('carMap', 'front-right')],
    'middle-left': [getCarMapKey('middle-left'), arrayIncludesKeyAndValueProxy('carMap', 'middle-left')],
    'middle-part': [getCarMapKey('middle-part'), arrayIncludesKeyAndValueProxy('carMap', 'middle-part')],
    'middle-right': [getCarMapKey('middle-right'), arrayIncludesKeyAndValueProxy('carMap', 'middle-right')],
    'back-left': [getCarMapKey('back-left'), arrayIncludesKeyAndValueProxy('carMap', 'back-left')],
    'back-middle': [getCarMapKey('back-middle'), arrayIncludesKeyAndValueProxy('carMap', 'back-middle')],
    'back-right': [getCarMapKey('back-right'), arrayIncludesKeyAndValueProxy('carMap', 'back-right')],
  },
  'vandalism_vehicle-id-ergo_license-number': [claimsKeys.owner.LICENSE_NUMBER, getLicenseNumber],
  'vandalism_contact-data': {
    who: [claimsKeys.customer.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.customer.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.customer.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.customer.PHONE, getPhoneValue],
    email: claimsKeys.customer.EMAIL,
  },
  'vandalism_contact-broker': {
    who: [claimsKeys.owner.SALUTATION, getSalutationValue],
    email: [claimsKeys.owner.EMAIL, getEmailValue],
    firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
    yourReference: claimsKeys.broker.REFERENCE,
  },
  'vandalism_police_is-reported': [claimsKeys.POLICE, getYesNoValue],
  'vandalism_police_report-details': {
    department: claimsKeys.DEPARTMENT,
    caseNumber: claimsKeys.CASE_NUMBER,
  },
  'vandalism_car-repair_private-vehicle': [claimsKeys.PRIVATE_VEHICLE, getYesNoValueProxy(NO)],
  'vandalism_car-repair_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'vandalism_car-repair_repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'vandalism_car-repair_repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'vandalism_car-repair_have-you-invoices': [claimsKeys.INVOICES, getYesNoValue],
  'vandalism_car-repair_repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'vandalism_car-repair_want-to-repair': [
    claimsKeys.DAMAGE_REPAIR,
    getOneFromMultiProxy({
      yes: claimsKeys.REPAIR,
      no: claimsKeys.PAYOUT,
    }),
  ],
  'vandalism_car-repair_not-repaired-beneficiary-iban_payment': [
    claimsKeys.BANK_ACCOUNT,
    getOneFromMultiProxy({
      'my-account': claimsKeys.MY_ACCOUNT,
      'another-account': claimsKeys.ANOTHER_ACCOUNT,
    }),
  ],
  'vandalism_car-repair_not-repaired-beneficiary-iban_name': {
    name: claimsKeys.IBAN_NAME,
    iban: [claimsKeys.IBAN, stripSpacesFromIban],
  },
  'vandalism_car-repair_want-to-recommend-repair-shop': [claimsKeys.RECOMMEND_REPAIR_SHOP, getYesNoValue],
  'vandalism_car-repair_not-repaired-repair-shop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    phoneNumber: [claimsKeys.WORKSHOP_PHONE, getPhoneValue],
    city: claimsKeys.WORKSHOP_CITY,
  },
  'vandalism_car-repair_is-vehicle-in-workshop': [claimsKeys.WORKSHOP, getYesNoValue],
  'vandalism_car-repair_postal-code': claimsKeys.WORKSHOP_POSTAL_CODE,
  'vandalism_car-repair_select-workshop': {
    name: claimsKeys.REPAIR_SHOP_NAME,
    address: claimsKeys.WORKSHOP_ADDRESS,
    phone: claimsKeys.WORKSHOP_PHONE,
    city: claimsKeys.WORKSHOP_CITY,
    postalcode: claimsKeys.WORKSHOP_POSTAL_CODE,
  },
};
