const styles = ({ colors }) => ({
  area: {
    fontSize: '16px',
    fontWeight: 'bold',
    width: '33%',
    height: '33%',
    lineHeight: '24px',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    border: '1px solid #979797',
    backgroundColor: 'transparent',
    color: 'transparent',
    transition: 'all .2s ease-in',
    '&:first-child': {
      borderTopLeftRadius: '4px',
    },
    '&:nth-child(3)': {
      borderTopRightRadius: '4px',
    },
    '&:nth-child(7)': {
      borderBottomLeftRadius: '4px',
    },
    '&:last-child': {
      borderBottomRightRadius: '4px',
    },
    '&:nth-child(5)': {
      justifyContent: 'center',
    },
  },
  areaHovered: {
    '&:hover': {
      backgroundColor: colors.themePrimary,
      color: colors.themeWhite,
    },
  },
  areaSelected: {
    backgroundColor: colors.themePrimary,
    color: colors.themeWhite,
    borderColor: 'transparent',
  },
});

export default styles;
