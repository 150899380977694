import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import injectSheet from 'react-jss/lib/injectSheet';
import MessageBox from '@eg/elements/MessageBox';
import Details from '@eg/elements/Details';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import BlastIcon from '@eg/elements/Icons/BlastIcon';
import Card from '@eg/elements/Card';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ErgoInputText from '../../components/ErgoInputText';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import styles from './styles.jss';
import { HeadingFormPlain } from '../../layout/HeadingForm';
import ErgoNavButton from '../../components/ErgoNavButton';
import { types } from '../../actions/constants';
import { claimNumber as claimNumberValidator } from '../../helpers/validation/claimNumber.validators';
import { required as requiredValidator } from '../../helpers/validation/common.validators';
import claimNumberModel, { getClaimModule, fieldName } from '../../models/panels/claimNumberSelector';

const ClaimNumberSelector = (props, { t }) => {
  const {
    localePath,
    classes,
    isMobile,
    pathName,
    getValidationProps,
  } = props;

  const { claimNumber, claimModule } = useSelector(state => {
    const { formData } = state;
    const { upload } = formData;
    return {
      claimNumber: upload ? upload[fieldName.CLAIM_NUMBER] : '',
      claimModule: upload ? upload[fieldName.CLAIM_MODULE] : '',
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  const setNextModule = (payload) => {
    dispatch({
      type: types.FIELD_UPDATE,
      fieldName: fieldName.CLAIM_MODULE,
      pathName,
      payload,
    });
  };

  const setInputValue = (payload) => {
    dispatch({
      type: types.FIELD_UPDATE,
      fieldName: fieldName.CLAIM_NUMBER,
      pathName,
      payload,
    });
  };

  useEffect(() => {
    if (claimNumber) {
      setInputValue(claimNumber);
    }
  }, []);

  const { onChange } = getValidationProps();

  useEffect(() => {
    if (claimNumber) {
      const nextClaimModule = getClaimModule(claimNumber);
      if (nextClaimModule) {
        onChange(fieldName.CLAIM_MODULE)(nextClaimModule.path);
        setNextModule(nextClaimModule.path);
      }
    }
  }, [claimNumber]);

  const handleDescriptionChange = e => {
    const { value } = e.target;
    setInputValue(value.toUpperCase());
  };

  const validateClaimNumber = claimNumberValidator({
    localePath,
    message: 'validation-rules.invalid-upload-claim-number',
  });
  const validateRequired = requiredValidator({
    localePath,
    message: 'validation-rules.required',
  });
  const error = validateRequired(claimNumber) || validateClaimNumber(claimNumber);

  const commentValueHTML = `
  <div>
    ${t(`${localePath}.commentText`)} 
    <a href=${t(`${localePath}.commentLink`)}
    rel="noopener noreferrer"
    target="_blank"
    class=${classes.link} > 
      ${t(`${localePath}.commentLinkText`)}
    </a>
  </div>`;

  return (
    <ContentWrapper>
      {isMobile ? (
        <Details
          iconLeft={InfoIcon}
          className={classes.mobileAccordion}
          summary={(
            <span className={classes.mobileAccordionTitle}>
              {t(`${localePath}.privacyInfoShowMore`)}
            </span>
          )}
        >
          {t(`${localePath}.legalText`)}
          <a
            href="https://www.ergo.de/de/Service/Datenschutz"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            {t(`${localePath}.legalTextLink`)}
          </a>
          .
        </Details>
      ) : (
        <MessageBox className={classes.messageBox}>
          <InfoIcon width={32} height={32} className={classes.infoIcon} />
          <div className={classes.messageBoxContent}>
            {t(`${localePath}.legalText`)}
            <a
              href="https://www.ergo.de/de/Service/Datenschutz"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              {t(`${localePath}.legalTextLink`)}
            </a>
            .
          </div>
        </MessageBox>
      )}

      <HeadingFormPlain
        value={t(`${localePath}.headingText`)}
        commentValue={commentValueHTML}
        classes={{
          rootPosition: classes.headingPosition,
          commentHeading: classes.commentHeading,
        }}
      />

      <Card className={classes.card}>
        <div className={classes.cardHeader}>
          {t(`${localePath}.cardTitle`)}
        </div>
        <div className={`esc_grid ${classes.cardContent}`}>
          <div className="esc_col esc_col-12 esc_col-l-12">
            <div className="esc_grid__wrapper">
              <div className="esc_col esc_col-12 esc_col-l-12">
                <BlastIcon width="45px" height="45px" className={classes.blastIcon} />
              </div>
            </div>
            <div className="esc_col esc_col-12 esc_col-l-12">
              {t(`${localePath}.cardText`)}
            </div>
            <div className="esc_col esc_col-12 esc_col-l-8">
              <ErgoInputText
                onChange={handleDescriptionChange}
                error={error}
                value={claimNumber}
                description={t(`${localePath}.fields.claimNumber.description`)}
                placeholder={t(`${localePath}.fields.claimNumber.placeholder`)}
              />
            </div>
            <div className="esc_col esc_col-s--offset-1 esc_col-l--offset-9 esc_col-l-1">
              <ErgoNavButton
                disabled={!!error}
                title="button.next"
                size="normal"
                width="fix"
              />
            </div>
          </div>
        </div>
      </Card>
    </ContentWrapper>
  );
};

ClaimNumberSelector.propTypes = claimNumberModel.props;

ClaimNumberSelector.defaultProps = claimNumberModel.defaults;

ClaimNumberSelector.contextTypes = {
  t: PropTypes.func,
};

export { ClaimNumberSelector as ClaimNumberSelectorPlain };
export default compose(
  withSingleFieldValidation,

  injectSheet(styles),
)(ClaimNumberSelector);
