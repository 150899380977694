import {
  getIdentityCommentValue,
  getUploadValues,
  getUploadedFilesProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';

const mappingWhatDocuments = [claimsKeys.TYPE_FILES, getUploadValues];

const mappingFiles = [claimsKeys.FILES, getUploadedFilesProxy];

const mappingDescription = [claimsKeys.upload.IDENTITY, getIdentityCommentValue];

export default {
  'upload-documents-property_what-documents': mappingWhatDocuments,
  'upload-documents-private-liability_what-documents': mappingWhatDocuments,
  'upload-documents-accident_what-documents': mappingWhatDocuments,
  'upload-documents-vehicle_what-documents': mappingWhatDocuments,
  'upload-documents-legal-protection_what-documents': mappingWhatDocuments,
  'upload-documents_files': mappingFiles,
  'upload-documents-property_files': mappingFiles,
  'upload-documents-private-liability_files': mappingFiles,
  'upload-documents-accident_files': mappingFiles,
  'upload-documents-vehicle_files': mappingFiles,
  'upload-documents-legal-protection_files': mappingFiles,
  description: mappingDescription,
};
